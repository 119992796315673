import Error404 from '@virtuslab/nfs-shared/src/components/pages/Error404';
import type { RoutesConfig, WrapRoutes } from '@virtuslab/nfs-shared/src/services/routes';

import appConfig from '../../../config';
import { LoginRoutes, NotFoundRoutes } from '../../../config/paths';

const config: RoutesConfig<WrapRoutes<typeof NotFoundRoutes>> = {
  paths: {
    [NotFoundRoutes.NOT_FOUND]: {
      render: () => <Error404 />,
    },
    [NotFoundRoutes.FALLBACK]: {
      render: () => (
        <Error404 redirect={{
          to: LoginRoutes.LOGIN,
          baseURL: appConfig.baseUrl,
        }}
        />
      ),
    },
  },
};

export default config;
