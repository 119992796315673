import type { FetchResult } from '@apollo/client';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';

import type { NfsErrorFragment as AdminNfsError, UserErrorUnionInterface as AdminUserErrorUnionInterface } from '../schema/admin';
import { isInstanceOf, NfsErrorTypename, UserFacingAuthzErrorTypename } from '../schema/admin';
import type { NfsErrorFragment as UserNfsError, UserErrorUnionInterface as UserUserErrorUnionInterface } from '../schema/user';

import { isEmpty } from './checks';
import { isLiteral, isObjectWithKeys } from './guards';
import type { Nullable } from './object';

type NfsErrorFragment = AdminNfsError | UserNfsError;

export type NonError<ErrorUnionType> = Exclude<
ErrorUnionType, { __typename?: typeof NfsErrorTypename }>;

export type Error<ErrorUnionType> = Extract<
ErrorUnionType, { __typename?: typeof NfsErrorTypename }>;

export const getData = <ErrorUnionType>(
  union?: ErrorUnionType | null,
): NonError<ErrorUnionType> | null => {
  if (isNil(union)) {
    return null;
  }

  if (isInstanceOf(union, NfsErrorTypename)) {
    return null;
  }

  return union as NonError<ErrorUnionType>;
};

export const getError = <ErrorUnionType>(
  union?: ErrorUnionType | null,
): Error<ErrorUnionType> | null => {
  if (isNil(union)) {
    return null;
  }

  if (isInstanceOf(union, NfsErrorTypename)) {
    return union;
  }

  return null;
};

export type ErrorTuple<ErrorUnionType> = [
  data: NonError<ErrorUnionType> | null,
  error: Error<ErrorUnionType> | null,
];

export const splitError = <ErrorUnionType>(
  union?: ErrorUnionType | null,
): ErrorTuple<ErrorUnionType> => [getData(union), getError(union)];

export const throwUnhandled = (
  error?: AdminNfsError | UserNfsError | null,
): void => {
  if (!isNil(error)) {
    throw new Error(error.error.msg);
  }
};

export const isNfsError = (arg: unknown): arg is NfsErrorFragment => isObjectWithKeys(arg, ['__typename', 'error'])
  && isInstanceOf(arg, NfsErrorTypename);

export const getNfsError = (arg: unknown): NfsErrorFragment[] => {
  if (isNil(arg)) {
    return [];
  }

  if (isLiteral(arg)) {
    if (isNfsError(arg)) {
      return [arg];
    }

    return Object.values(arg).flatMap(getNfsError);
  }

  return [];
};

export const isSpecificNfsError = (arg: unknown, typename: Nullable<NfsErrorFragment['error']['__typename']>): boolean => isNfsError(arg) && arg.error.__typename === typename;

export const isUnauthorizedQuery = (arg: unknown): boolean => isSpecificNfsError(arg, UserFacingAuthzErrorTypename);

export const isSuccessfulResponse = (
  fetchResult: FetchResult,
): boolean => isNil(fetchResult.errors) && isEmpty(getNfsError(fetchResult.data));

type ResponseFn<T extends FetchResult> = (fetchResult: T) => unknown;
type ResponseHandler<T extends FetchResult> = (fetchResult: T) => T;

type OnResponseArg<T extends FetchResult> = Readonly<{
  onSuccess?: ResponseFn<T>;
  onError?: ResponseFn<T>;
}>;

export const onResponse = <T extends FetchResult>({
  onSuccess, onError,
}: OnResponseArg<T>): ResponseHandler<T> => (fetchResult) => {
    if (isSuccessfulResponse(fetchResult)) {
      onSuccess?.(fetchResult);
      return fetchResult;
    }

    onError?.(fetchResult);
    return fetchResult;
  };

export const onSuccess = <T extends FetchResult>(
  fn: ResponseFn<T>,
): ResponseHandler<T> => onResponse<T>({ onSuccess: fn, onError: noop });

export const onError = <T extends FetchResult>(
  fn: ResponseFn<T>,
): ResponseHandler<T> => onResponse<T>({ onSuccess: noop, onError: fn });

type AllErrorTypenames = NonNullable<AdminUserErrorUnionInterface['__typename'] | UserUserErrorUnionInterface['__typename']>;

type BaseErrorShape = Readonly<{ __typename?: typeof NfsErrorTypename; error: { __typename?: AllErrorTypenames } }>;

export const ignoreError = <T extends BaseErrorShape>(
  error: Nullable<T>,
  ignored: AllErrorTypenames[],
): Nullable<T> => {
  if (isNil(error)) {
    return null;
  }

  if (ignored.some((errorTypename) => isInstanceOf(error.error, errorTypename))) {
    return null;
  }

  return error;
};
