import first from 'lodash/first';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';

import type { StrictTypedTypePolicies as AdminStrictTypedTypePolicies } from '../schema/admin/clientHelpers';
import type { StrictTypedTypePolicies as UserStrictTypedTypePolicies } from '../schema/user/clientHelpers';
import { isLiteral } from '../services/guards';

export const typesafePolicies = <TypePolicies>() => <CustomTypePolicies extends TypePolicies>(
  arg: CustomTypePolicies,
): CustomTypePolicies => arg;

export const isVariables = <T extends Record<string, unknown>>(
  arg: unknown, key: keyof T,
): arg is T => Boolean(arg)
  && isObject(arg)
  && key in arg;

export const idAtKeyFields = (...keyFieldArgs: unknown[]): ('at' | 'id')[] => {
  // types are wrong, docs show what arguments will be passed here
  // https://www.apollographql.com/docs/react/caching/cache-configuration/#typepolicy-fields
  const object = first(keyFieldArgs);

  if (isLiteral(object)) {
    return 'at' in object && !isNil(object.at) ? ['id', 'at'] : ['id'];
  }

  return ['id'];
};

export const sharedPolicies = typesafePolicies<AdminStrictTypedTypePolicies & UserStrictTypedTypePolicies>()({
  GoogleSpreadsheetUrl: {
    keyFields: ['url'],
  },
  GoogleDocUrl: {
    keyFields: ['url'],
  },
  GoogleFileUrl: {
    keyFields: ['url'],
  },
  DownloadableFileUrl: {
    keyFields: ['url'],
  },
});
