import type { useAuth } from '@virtuslab/react-oauth2';

import { isNotEmptyString } from './checks';

type KeycloakAdapter = ReturnType<typeof useAuth>;

const getToken = async (auth: KeycloakAdapter) => {
  try {
    const token = await auth.getAccessToken();
    return token;
  } catch {
    return null;
  }
};

type AuthHeader = Readonly<{
  Authorization: string;
}>;

export const getAuthHeader = async (auth: KeycloakAdapter): Promise<AuthHeader | null> => {
  const token = await getToken(auth);

  return isNotEmptyString(token) ? { Authorization: `Bearer ${token}` } : null;
};

export default {};
