import type { ReactElement, ReactNode } from 'react';

import styled from 'styled-components/macro';

import { Box } from '@mui/material';
import Divider from '@mui/material/Divider';

import type { NavGroup } from '../../../config/navigation';
import { isNotEmptyString } from '../../../services/checks';

import NavButton from '../../molecules/NavButton';
import NavGroupTitle from '../../molecules/NavGroupTitle';
import NfsLogo from '../../molecules/NfsLogo';

const NavSidebarWrapper = styled.div`
  border-right: ${({ theme }) => theme.scTheme.borders.styles.thin.grey[20]};
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 100%;
  max-height: 100%;
  position: relative;
  width: ${({ theme }) => theme.scTheme.widths.default.navbar};
`;

const NavLinksGroup = styled.div`
  margin-bottom: ${({ theme }) => theme.scTheme.space[600]};
  padding: 0 ${({ theme }) => theme.scTheme.space[300]};

  & > * {
    margin-bottom: ${({ theme }) => theme.scTheme.space[200]};
    margin-top: ${({ theme }) => theme.scTheme.space[200]};
    padding-bottom: calc(${({ theme }) => `${theme.scTheme.space[300]} + ${theme.scTheme.space[100]}`});
    padding-right: 0;
    padding-top: calc(${({ theme }) => `${theme.scTheme.space[300]} + ${theme.scTheme.space[100]}`});
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  & > *:first-child {
    margin-bottom: 0;
    margin-top: 0;
  }
`;

type Props = Readonly<{
  navigation: readonly NavGroup[];
  profileLink: ReactElement;
  children?: ReactNode;
}>;

const NavSidebar = ({ navigation, profileLink, children }: Props): ReactElement => (
  <NavSidebarWrapper>
    <Box overflow="auto">
      <Box ml={600} mt={600} mb={800}>
        <NfsLogo />
      </Box>
      {navigation.map((group) => (
        <NavLinksGroup key={group.id}>
          {isNotEmptyString(group.title) ? (
            <NavGroupTitle title={group.title} key={group.id} />
          ) : null}
          {group.items.map((item) => (
            <NavButton item={item} key={item.id} changeOnActive />
          ))}
        </NavLinksGroup>
      ))}
    </Box>
    <Box mt="auto">
      {children}
      <Divider />
      {profileLink}
    </Box>
  </NavSidebarWrapper>
);
export default NavSidebar;
