import type { ReactElement, ReactNode } from 'react';

import isNil from 'lodash/isNil';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import Box from '@mui/material/Box';
import { X } from 'phosphor-react';

import Columns from '../../atoms/Columns';
import Modal from '../../atoms/Modal';
import ModalBody from '../../atoms/ModalBody';
import ModalOverlay from '../../atoms/ModalOverlay';
import Button from '../../molecules/Button';
import Icon from '../../molecules/Icon';
import IconButton from '../../molecules/IconButton';

const StyledModalBody = styled(ModalBody)`
  width: ${({ theme }) => theme.scTheme.widths.default.confirmationModal};

  &:focus {
    outline: 0;
  }
`;

type Props = Readonly<{
  onConfirm?: () => void;
  onDeny?: () => void;
  header: ReactNode;
  body: ReactNode;
  confirmButton: ReactNode;
  closeButton?: ReactNode;
  closeConfirms?: boolean;
}>;

const ConfirmationModal = ({
  onConfirm, onDeny, header, body, confirmButton, closeConfirms = false, closeButton,
}: Props): ReactElement => {
  const { t } = useTranslation();

  return (
    <Modal
      slots={{
        backdrop: ModalOverlay,
      }}
      data-test-id="confirmation-modal"
      open
      aria-labelledby="modal-title"
    >
      <StyledModalBody>
        <Box display="flex" justifyContent="space-between" id="modal-title">
          {header}
          <Box mt={100}>
            <IconButton onClick={closeConfirms ? onConfirm : onDeny}>
              <Icon size="lg">
                <X />
              </Icon>
            </IconButton>
          </Box>
        </Box>
        <Box pt={500} pb={600}>
          {body}
        </Box>
        <Box width="100%" display="flex" justifyContent="flex-end">
          <Box width="max-content">
            <Columns gap={500}>
              {onDeny || !isNil(closeButton)
                ? closeButton ?? <Button onClick={onDeny} variant="secondary" aria-label={t('Cancel')}>{t('Cancel')}</Button>
                : null}
              {confirmButton}
            </Columns>
          </Box>
        </Box>
      </StyledModalBody>
    </Modal>
  );
};

export default ConfirmationModal;
