import { captureException } from '@sentry/react';
import findLastIndex from 'lodash/findLastIndex';
import take from 'lodash/fp/take';

import type { ReactiveVars } from '../../../..';

// this doesn't remove drawers, just sets their .visible to false
// 1) so that their exit can be animated (at least in some cases)
// 2) so that their data is kept in state -> components that need it can still collect it
export default function createCloseDrawer(drawersVar: ReactiveVars['drawersVar']) {
  return (): void => {
    const drawers = drawersVar();
    const indexOflastVisibleDrawer = findLastIndex(drawers, { visible: true });

    if (indexOflastVisibleDrawer === -1) {
      captureException('Tried to close a drawer while none are visible / no drawer exists');
    } else {
      drawersVar(
        take(indexOflastVisibleDrawer, drawers)
          .concat([{ ...drawers[indexOflastVisibleDrawer], visible: false }])
          .concat(drawers.slice(indexOflastVisibleDrawer + 1)),
      );
    }
  };
}
