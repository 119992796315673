import type { ReactElement } from 'react';

import { Box } from '@mui/material';

import Body from '../Body';

type Props = Readonly<{
  title: string;
}>;

const NavGroupTitle = ({ title }: Props): ReactElement => (
  <Box pl={500}>
    <Body size={300} variant="secondary">
      {title}
    </Body>
  </Box>
);

export default NavGroupTitle;
