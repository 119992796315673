import Inline404 from '@virtuslab/nfs-shared/src/components/molecules/Inline404';
import type { RoutesConfig, WrapRoutes } from '@virtuslab/nfs-shared/src/services/routes';

import { BusinessTravelsRoutes } from '../../../config/paths';

const config: RoutesConfig<WrapRoutes<typeof BusinessTravelsRoutes>> = {
  paths: {
    [BusinessTravelsRoutes.BUSINESS_TRAVELS]: {
      render: Inline404,
    },
  },
};

export default config;
