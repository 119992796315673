import styled, { css } from 'styled-components/macro';

// TODO force children to be only buttons

type Props = Readonly<{
  straightenCorners?: boolean;
}>;

const ButtonGroup = styled.div<Props>`
  && {
    display: flex;
    flex-direction: row;
    position: relative;

    & > * {
      z-index: ${({ theme }) => theme.scTheme.zindex.default};

      &:focus-within {
        z-index: ${({ theme }) => theme.scTheme.zindex.focused};
      }
    }

    ${({ straightenCorners = false }) => (straightenCorners ? css`
      & > *:not(:focus-within) {
        &:first-child {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }

        &:last-child {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }

        &:not(:first-child) {
          margin-left: -${({ theme }) => theme.scTheme.borders.widths.thin};
        }

        /* stylelint-disable selector-not-notation */
        &:not(:first-child):not(:last-child)  {
          border-radius: 0;
        }
      }

    ` : css`
      & > *:first-child {
        border-bottom-left-radius: ${({ theme }) => theme.scTheme.borders.radii.md};
        border-top-left-radius: ${({ theme }) => theme.scTheme.borders.radii.md};
      }

      & > *:last-child {
        border-bottom-right-radius: ${({ theme }) => theme.scTheme.borders.radii.md};
        border-top-right-radius: ${({ theme }) => theme.scTheme.borders.radii.md};
      }

      & > *:not(:first-child) {
        margin-left: -${({ theme }) => theme.scTheme.borders.widths.thin};
      }

      & > * :not(:focus-within) {
        &:not(:first-child) {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }

        &:not(:last-child) {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }
      }
    `)}


  }
`;

export default ButtonGroup;
