import AuthLogin from '@virtuslab/nfs-shared/src/components/pages/AuthLogin';
import type { RoutesConfig, WrapRoutes } from '@virtuslab/nfs-shared/src/services/routes';

import appConfig from '../../../config';
import { LoginRoutes } from '../../../config/paths';

const config: RoutesConfig<WrapRoutes<typeof LoginRoutes>> = {
  paths: {
    [LoginRoutes.LOGIN]: {
      render: () => (
        <AuthLogin
          baseUrl={appConfig.baseUrl}
        />
      ),
    },
  },
};

export default config;
