import type { ReactNode, MouseEventHandler, ReactElement } from 'react';

import isNil from 'lodash/isNil';
import styled, { css } from 'styled-components/macro';

import Box from '@mui/material/Box';
import { Check } from 'phosphor-react';

import palette from '../../../theme/palette';

import DropdownItemContainer from '../../atoms/DropdownItemContainer';
import Hidden from '../../atoms/Hidden';
import ActionText from '../ActionText';
import Body from '../Body';
import Icon from '../Icon';

type WidthProps = Readonly<{
  fixedSize: boolean;
  header: boolean;
}>;

const width = ({ fixedSize, header }: WidthProps) => {
  if (fixedSize && header) {
    return css`
      width: ${({ theme }) => theme.scTheme.widths.max.virtualizedListHeader};
    `;
  }
  if (fixedSize && !header) {
    return css`
      width: ${({ theme }) => theme.scTheme.widths.max.virtualizedListItem};
    `;
  }
  return css`
      width: 100%;
    `;
};

type BoxContainerProps = Readonly<{
  textWrap: boolean;
}> & WidthProps;

const BoxContainer = styled.div<BoxContainerProps>`
  display: ${({ textWrap }) => (textWrap ? 'inline-flex' : 'inline-block')};
  ${width};
  overflow: hidden;
  padding-bottom: ${({ theme }) => theme.scTheme.space[300]};
  padding-right: ${({ theme }) => theme.scTheme.space[500]};
  padding-top: ${({ theme }) => theme.scTheme.space[300]};
  text-overflow: ${({ textWrap }) => (textWrap ? undefined : 'ellipsis')};
  white-space: ${({ textWrap }) => (textWrap ? undefined : 'nowrap')};

  & > * {
    display: inline-flex;
  }
`;

export type Props = Readonly<{
  highlighted?: boolean;
  keyboardHighlight?: boolean;
  selected?: boolean;
  disabled?: boolean;
  showSelection?: boolean;
  children: ReactNode;
  header?: boolean;
  id?: string;
  virtualize: boolean;
  onClick?: MouseEventHandler;
  onMouseMove?: MouseEventHandler;
  textWrap?: boolean;
  renderSelection?: (selected: boolean) => ReactNode;
  renderItem?: (text: ReactNode, selected: boolean) => ReactNode;
}>;

const DropdownItem = ({
  highlighted = false,
  showSelection = false,
  selected = false,
  disabled = false,
  header = false,
  keyboardHighlight = false,
  children,
  id,
  virtualize,
  onClick,
  onMouseMove,
  renderSelection,
  renderItem,
  textWrap = true,
}: Props): ReactElement => (
  <DropdownItemContainer
    id={id}
    onClick={disabled ? undefined : onClick}
    onMouseMove={disabled ? undefined : onMouseMove}
    disabled={disabled}
    highlighted={highlighted}
    header={header}
    keyboardHighlight={keyboardHighlight}
    selected={selected}
  >
    {showSelection ? (
      <>
        {isNil(renderSelection) ? (
          <Box ml={500}>
            <Hidden hiddenVisually visible={selected}>
              <Icon size="lg">
                <Check weight="fill" fill={palette.primary[60]} />
              </Icon>
            </Hidden>
          </Box>
        ) : renderSelection(selected)}
      </>
    ) : null}
    <Box width="100%" ml={showSelection ? 300 : 500}>
      {isNil(renderItem) ? (
        <>
          {selected && showSelection ? (
            <ActionText variant="ghost" disabled={disabled}>
              <BoxContainer
                header={header}
                fixedSize={virtualize}
                textWrap={textWrap}
              >
                {children}
              </BoxContainer>
            </ActionText>

          ) : (
            <Body fitToWidth={!textWrap} size={300} variant={header ? 'secondary' : 'primary'} disabled={disabled}>
              <BoxContainer
                header={header}
                fixedSize={virtualize}
                textWrap={textWrap}
              >
                {children}
              </BoxContainer>
            </Body>
          )}
        </>
      ) : renderItem(children, selected)}

    </Box>
  </DropdownItemContainer>
);

export default DropdownItem;
