import AuthLogout from '@virtuslab/nfs-shared/src/components/pages/AuthLogout';
import type { RoutesConfig, WrapRoutes } from '@virtuslab/nfs-shared/src/services/routes';

import appConfig from '../../../config';
import { LoginRoutes, LogoutRoutes } from '../../../config/paths';

const config: RoutesConfig<WrapRoutes<typeof LogoutRoutes>> = {
  paths: {
    [LogoutRoutes.LOGOUT]: {
      render: () => (
        <AuthLogout
          baseUrl={appConfig.baseUrl}
          loginRoute={LoginRoutes.LOGIN}
        />
      ),
    },
  },
};

export default config;
