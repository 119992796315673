import styled from 'styled-components/macro';

const ModalBody = styled.div`
  background-color: ${({ theme }) => theme.scTheme.palette.inverted[100]};
  border-radius: ${({ theme }) => theme.scTheme.borders.radii.md};
  box-shadow: ${({ theme }) => theme.scTheme.shadows.largeShadow};
  padding: ${({ theme }) => theme.scTheme.space[600]};
  position: relative;
`;

export default ModalBody;
