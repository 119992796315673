import type { ReactElement } from 'react';

import { useAuth } from '@virtuslab/react-oauth2';
import isNil from 'lodash/isNil';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components/macro';

import { Box } from '@mui/material';

import ErrorIllustration from '../../../assets/images/backgrounds/404-illustration.svg';
import { makeLoginRedirectURL } from '../../../services/security';

import Body from '../../molecules/Body';

const ErrorContainer = styled.div`
  display: grid;
  grid-template-areas:
    'empty'
    'error_text'
    'background';
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto 4fr;
  height: 100%;
  justify-items: center;
  width: 100%;
`;

const BackgroundImage = styled.div`
  background-image: url(${ErrorIllustration});
  background-position-x: right;
  background-position-y: top;
  background-repeat: no-repeat;
  background-size: 375%;
  grid-area: background;
  height: 100%;
  margin-top: ${({ theme }) => theme.scTheme.space[800]};
  width: 100%;
`;

type Props = Readonly<{
  redirect?: Readonly<{
    to: string;
    baseURL: string;
  }>;
}>;

const Error404Page = ({ redirect }: Props): ReactElement => {
  const { t } = useTranslation();
  const auth = useAuth();

  const redirectURL = !isNil(redirect)
    ? makeLoginRedirectURL(redirect.to, redirect.baseURL)
    : null;

  if (!auth.isAuthenticated() && !isNil(redirectURL)) {
    return <Navigate to={redirectURL} />;
  }

  return (
    <ErrorContainer>
      <Box />
      <Body size={200} variant="secondary">
        {`${t('This is a 404 page, you can\'t hear anything here')}...`}
      </Body>
      <BackgroundImage />
    </ErrorContainer>
  );
};

export default Error404Page;
