import type { ReactElement, ReactNode } from 'react';
import { useRef, useMemo } from 'react';

import type { NormalizedCacheObject, InMemoryCache, DocumentNode } from '@apollo/client';
import {
  ApolloClient, ApolloLink, ApolloProvider,
} from '@apollo/client';
import type { NetworkError, GraphQLErrors } from '@apollo/client/errors';
import { setContext } from '@apollo/client/link/context';
import type { ErrorLink } from '@apollo/client/link/error';
import { onError } from '@apollo/client/link/error';
import { useAuth } from '@virtuslab/react-oauth2';
import { createUploadLink } from 'apollo-upload-client';
import { GraphQLError } from 'graphql';
import isNil from 'lodash/isNil';
import { v4 } from 'uuid';

import { getAuthHeader } from '../../../services/auth';
import { isEmpty } from '../../../services/checks';
import { isNotNil } from '../../../services/guards';
import useOnRouteChange from '../../../services/hooks/useOnRouteChange';
import type { Nullable } from '../../../services/object';

import { useContentErrorBoundary } from '../ContentErrorBoundary';
import { useFeatureFlagsContext } from '../FeatureFlagsContext';

type ApolloConfig = Readonly<{
  apiUrl: string;
  isDev?: boolean;
}>;

type Props = Readonly<{
  children: ReactNode;
  cache: InMemoryCache;
  config: ApolloConfig;
  typeDefs: DocumentNode;
  onUnauthorized: () => void;
}>;

const formatError = (error: NonNullable<GraphQLErrors[number] | NetworkError>): string => {
  if (error instanceof GraphQLError) {
    return `[GraphQL Error]: Message: ${error.message}.`;
  }

  return `[GraphQL Network Error]: ${error.name}. ${error.message}`;
};

const logError: ErrorLink.ErrorHandler = ({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      const { locations, path } = error;

      // eslint-disable-next-line no-console
      console.error(formatError(error));
      // eslint-disable-next-line no-console
      console.dir({ locations, path });
    });
  }

  if (networkError) {
    // eslint-disable-next-line no-console
    console.error(formatError(networkError));
  }
};

const hasUnauthorizedError = (networkError: Nullable<NetworkError>): boolean => {
  if (isNil(networkError)) {
    return false;
  }

  return [networkError.name, networkError.message].some((text) => /unauthorized/i.test(text));
};

const SetupApollo = ({
  children, cache, config, typeDefs, onUnauthorized,
}: Props): ReactElement => {
  const auth = useAuth();

  const { showSentryDialog } = useContentErrorBoundary();

  const onUnauthorizedRef = useRef(onUnauthorized);

  const { clearCachePeriodically } = useFeatureFlagsContext();

  const client: ApolloClient<NormalizedCacheObject> = useMemo(() => {
    const link = ApolloLink.from([
      onError((response) => {
        logError(response);

        if (hasUnauthorizedError(response.networkError)) {
          onUnauthorizedRef.current();
          return;
        }

        const errorMessage = [
          ...(response.graphQLErrors ?? []).map(formatError),
          isNil(response.networkError) ? null : formatError(response.networkError),
        ].filter(isNotNil).join('\n');

        if (!isEmpty(errorMessage)) {
          showSentryDialog(errorMessage);
        }
      }),
      setContext(async (_, prevContext) => {
        // get the authentication header
        const authHeader = await getAuthHeader(auth);
        const { headers } = prevContext as { headers: Record<string, string> };

        if (isNil(authHeader)) {
          window.location.reload();
        }

        return {
          headers: {
            ...headers,
            ...authHeader,
            'X-Trace-Id': v4(),
          },
        };
      }),
      // TODO add batch query back when possible #187
      createUploadLink({
        uri: `${config.apiUrl}/api/graphql`,
      }) as unknown as ApolloLink,
    ]);

    return new ApolloClient({
      connectToDevTools: config.isDev,
      link,
      cache,
      typeDefs,
    });
  }, [auth, cache, config, typeDefs, showSentryDialog]);

  useOnRouteChange({
    listener: () => {
      if (clearCachePeriodically) {
        client.cache.reset();
      }
    },
  });

  // remember to client.resetStore() on logout.
  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  );
};

export default SetupApollo;
