import type { MutableRefObject } from 'react';
import { useRef } from 'react';

// This is an easy way of getting the best of both worlds when
// it comes to the deps of hooks like `useEffect` or `useMemo`.
// Since whatever here gets wrapped in a ref, by definition, its reference
// won't change across renders - this way, it's safe to put it in deps
// ( this is mainly because in 99% of cases,
// you don't actually care if the function changed or not, just values )
// Additionally, since the function is updated on render, it will always have the newest closure.

// Two caveats
//   - since the whole point of this is to have a stable reference
//        to a function, effects won't run if only the function changes
//   - have to be careful about
//        extracting the value out of the ref https://codesandbox.io/s/upbeat-heisenberg-jnnwx?file=/src/App.js

const useSelfUpdatingRef = <T>(arg: T): MutableRefObject<T> => {
  const memoized = useRef(arg);
  memoized.current = arg;

  return memoized;
};

export default useSelfUpdatingRef;
