import borders from './borders';
import palette from './palette';

const { inner, outer } = borders.widths.focus;

const shadows = {
  focus: {
    primary: {
      full: `0 0 0 ${inner} ${palette.primary[30]},
        0 0 0 ${outer} ${palette.primary[20]}`,
      outer: `0 0 0 calc(${outer} - ${inner}) ${palette.primary[20]}`,
    },
    grey: {
      full: `0 0 0 ${inner} ${palette.greyscale[30]},
        0 0 0 ${outer} ${palette.greyscale[20]}`,
      outer: `0 0 0 calc(${outer} - ${inner}) ${palette.greyscale[20]}`,
    },
    alert: {
      full: `0 0 0 ${inner} ${palette.support.alert[30]},
        0 0 0 ${outer} ${palette.support.alert[20]}`,
      outer: `0 0 0 calc(${outer} - ${inner}) ${palette.support.alert[20]}`,
    },
    warning: {
      full: `0 0 0 ${inner} ${palette.support.warning[30]},
        0 0 0 ${outer} ${palette.support.warning[20]}`,
      outer: `0 0 0 calc(${outer} - ${inner}) ${palette.support.warning[20]}`,
    },
    inverted: {
      full: `0 0 0 ${inner} ${palette.inverted[30]},
        0 0 0 ${outer} ${palette.inverted[30]}`,
      outer: `0 0 0 calc(${outer} - ${inner}) ${palette.inverted[30]}`,
    },
  },
  largeShadow: `0 1px 4px 0px rgba(58,58,58,0.08),
    0 6px 12px 2px rgba(9,26,17,0.12)`,
  smallShadow: '0 0.5px 1px 0.5px rgba(9,26,17,0.12)',
} as const;

export default shadows;
