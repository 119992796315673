import type { FormattingFn } from '.';

type ListFormat = Readonly<{
  type: NonNullable<Intl.ListFormatOptions['type']>;
  style: NonNullable<Intl.ListFormatStyle>;
}>;

const items: FormattingFn<string[], string, ListFormat> = ({ language }) => (
  listItems, format,
) => new Intl.ListFormat(language, { style: format.style, type: format.type }).format(listItems);

type LimitedItemsListFormat = Readonly<{
  limit: number;
}>;

const limitedItems: FormattingFn<string[], string, LimitedItemsListFormat> = ({ language }) => (
  listItems, format,
) => {
  const limitedListItems = listItems.slice(0, format.limit);

  return new Intl.ListFormat(language, { style: 'short', type: 'unit' })
    .format(limitedListItems)
    .concat(listItems.length > format.limit ? ` +${listItems.length - format.limit}` : '');
};

const list = {
  items,
  limitedItems,
} as const;

export type List = typeof list;

export default list;
