import type { ReactElement } from 'react';
import { useMemo } from 'react';

import shouldForwardProp from '@styled-system/should-forward-prop';
import { useTranslation } from 'react-i18next';
import { NavLink, matchPath, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

import { Box } from '@mui/material';

import type { NavItem } from '../../../config/navigation';
import { isEmpty } from '../../../services/checks';

import type { NavButtonBaseProps } from '../../atoms/NavButtonBase';
import NavButtonBase from '../../atoms/NavButtonBase';
import Badge from '../Badge';
import Body from '../Body';
import Icon from '../Icon';

type StyledNavLinkProps = NavButtonBaseProps & Readonly<{
  active: boolean;
}>;

const changeOnActiveStyle = ({ active }: StyledNavLinkProps) => {
  if (active) {
    return css`
      &,
      &:hover {
        background-color: ${({ theme }) => theme.scTheme.palette.primary[60]};
      }
    `;
  }
  return null;
};

const StyledLink = styled(NavButtonBase).withConfig<StyledNavLinkProps>({
  shouldForwardProp,
})<StyledNavLinkProps>`
  ${changeOnActiveStyle};
`;

export type Props = Readonly<{
  item: NavItem;
  changeOnActive?: boolean;
}>;

const NavButton = ({ item, changeOnActive = false }: Props): ReactElement => {
  const { t } = useTranslation();
  const location = useLocation();

  const isActive = useMemo(() => Boolean(matchPath(item.url, location.pathname))
    || (!isEmpty(item.associatedRoutes) && item.associatedRoutes.some((route) => matchPath(route, location.pathname))),
  [location.pathname, item]);

  const applyActiveStyling = isActive && changeOnActive;

  const disabled = item.disabled ?? false;

  const body = (
    <>
      <Body size={300} variant={applyActiveStyling ? 'primary' : 'secondary'} inverted={applyActiveStyling} disabled={disabled}>
        {item.icon ? (
          <Box display="flex" mr={600}>
            <Icon size="lg">{item.icon}</Icon>
          </Box>
        ) : null}
      </Body>
      <Body size={300} variant="primary" inverted={applyActiveStyling} disabled={disabled}>
        {item.title}
      </Body>
    </>
  );

  if (disabled) {
    return (
      <NavButtonBase disabled>
        {body}
        <Box ml={400}>
          <Badge>
            {t('Soon')}
          </Badge>
        </Box>
      </NavButtonBase>
    );
  }

  return (
    <StyledLink
      active={applyActiveStyling}
      as={NavLink}
      to={item.url}
    >
      {body}
    </StyledLink>
  );
};
export default NavButton;
