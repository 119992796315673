import { useMemo } from 'react';

import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import mapValues from 'lodash/mapValues';

import {
  UnauthorizedTypename,
  isInstanceOf,
  type Unauthorized,
} from '../schema/admin';

import { isEmpty } from './checks';
import type { ErrorTuple, NonError } from './errors';
import { isLiteral, isObjectWithKeys, type Literal } from './guards';
import type {
  NonMutable, Nullable, ObjectPaths,
} from './object';

export type Authorized<AuthorizationUnion> = Exclude<
AuthorizationUnion, Unauthorized>;

export type AuthorizedTuple<T> = T extends ErrorTuple<infer U> ? [
  Nullable<ParseAccessRights<U, AccessModes.assumeHandled>>,
  Nullable<T[1]>,
] : never;

export enum AccessModes {
  assumeHandled = 1,
  requireHandling = 2,
}

export type ParseAccessRights<
T, Mode extends AccessModes = AccessModes.requireHandling,
> = Unauthorized extends T
  ? [T] extends [(infer U | Unauthorized)]
    ? U extends { value: infer Val }
      ? Mode extends AccessModes.assumeHandled
        ? Nullable<ParseAccessRights<Val, Mode>>
        : ParseAccessRights<Val, Mode> | Unauthorized
      : U extends { value?: infer Val }
        ? Mode extends AccessModes.assumeHandled
          ? Nullable<ParseAccessRights<Val, Mode>>
          : ParseAccessRights<Val, Mode> | Unauthorized
        : never
    : never
  : T extends Literal
    ? {
      [key in keyof T]: ParseAccessRights<T[key], Mode>
    }
    : T extends NonMutable<(infer U)[]>
      ? NonMutable<ParseAccessRights<U, Mode>[]>
      : T;

export const unauthorized: Unauthorized = { __typename: 'Unauthorized', reason: '' };

export function parseAccessRights<T, U extends AccessModes = AccessModes.requireHandling>(
  arg: T, mode?: U): ParseAccessRights<T, U>;
export function parseAccessRights<T, U extends AccessModes = AccessModes.requireHandling>(
  arg: Nullable<T>, mode?: U): Nullable<ParseAccessRights<T>>;
export function parseAccessRights<T, U extends AccessModes = AccessModes.requireHandling>(
  arg: Nullable<T>, mode?: U,
): Nullable<ParseAccessRights<T>> {
  if (isNil(arg)) {
    return null;
  }

  if (isLiteral(arg)) {
    if (isObjectWithKeys(arg, ['__typename', 'value'])) {
      if (isString(arg.__typename) && arg.__typename.includes('AccessGranted')) {
        return parseAccessRights(arg.value, mode) as Nullable<ParseAccessRights<T>>;
      }
    }

    const mappedObject = mapValues(arg, (value) => {
      if (isNil(value)) {
        return null;
      }

      if (isLiteral(value)) {
        if (isInstanceOf(value, UnauthorizedTypename)) {
          return mode === AccessModes.assumeHandled ? null : unauthorized;
        }

        const objectValue = value as Literal;

        if (isObjectWithKeys(objectValue, ['__typename', 'value'])) {
          if (isString(objectValue.__typename) && objectValue.__typename.includes('AccessGranted')) {
            return parseAccessRights(objectValue.value, mode);
          }
        }

        return parseAccessRights(value, mode);
      }

      if (isArray(value)) {
        return value.map((entry) => parseAccessRights(entry, mode));
      }

      return value;
    });

    return mappedObject as Nullable<ParseAccessRights<T>>;
  }

  if (isArray(arg)) {
    return arg.map((entry) => parseAccessRights(entry, mode)) as Nullable<ParseAccessRights<T>>;
  }

  return arg as Nullable<ParseAccessRights<T>>;
}

export type AuthDataShape<T = unknown> = T | {
  __typename?: typeof UnauthorizedTypename;
};

export type GetAuthDataValue<T, U extends AuthDataShape<T>> = Exclude<U, Unauthorized> extends { value: infer Value }
  ? Value
  : Exclude<T, Unauthorized> extends { value?: infer Value }
    ? Nullable<Value>
    : Exclude<T, Unauthorized>;

export const accessData = <T, U extends AuthDataShape<T>>(arg: Nullable<T>):
Nullable<GetAuthDataValue<T, U>> => {
  if (isNil(arg)) {
    return null;
  }

  if (isObjectWithKeys(arg, ['__typename']) && isInstanceOf(arg, UnauthorizedTypename)) {
    return null;
  }

  if (isObjectWithKeys(arg, ['value'])) {
    return arg.value as Nullable<GetAuthDataValue<T, U>>;
  }

  return arg as Nullable<GetAuthDataValue<T, U>>;
};

export const authorizedTransformation = <T, U>(data: T | Unauthorized, fn: (arg: T) => U): U | Unauthorized => {
  if (isObjectWithKeys(data, ['__typename', 'reason']) && isInstanceOf(data, UnauthorizedTypename)) {
    return data;
  }

  return fn(data as T);
};

export const isAuthorized = <T>(
  data: T | Unauthorized,
): data is T => isObjectWithKeys(data, ['__typename']) && !isInstanceOf(data, UnauthorizedTypename);

export const useAccessRights = <
T,
U = ParseAccessRights<NonError<T>, AccessModes.assumeHandled>,
W = ObjectPaths<U, 10>>(
    errorTuple: ErrorTuple<T>,
  ): [Nullable<U>, ErrorTuple<T>[1], (path: W & string) => boolean] => {
  const [data] = errorTuple;
  const parsedData = useMemo(
    () => parseAccessRights(data, AccessModes.assumeHandled) as Nullable<U>, [data],
  );

  const check = useMemo(() => (path: W & string): boolean => {
    const checkAndRecurse = (pathFragments: string[], currentValue: unknown): boolean => {
      const [currentPathFragment, ...otherPathFragments] = pathFragments;
      if (currentPathFragment === '[]') {
        return isArray(currentValue) ? currentValue
          .every((el) => checkAndRecurse(otherPathFragments, el))
          : false;
      }

      const accessedValue: unknown = get(currentValue, currentPathFragment);

      if (isObjectWithKeys(accessedValue, ['__typename']) && isInstanceOf(accessedValue, UnauthorizedTypename)) {
        return false;
      }

      if (isEmpty(otherPathFragments)) {
        return true;
      }

      return checkAndRecurse(otherPathFragments, accessedValue);
    };

    return checkAndRecurse(path.split('.'), data);
  }, [data]);

  return [parsedData, errorTuple[1], check];
};
