import { Outlet } from 'react-router';

import type { AnyRoutesConfig } from '@virtuslab/nfs-shared/src/services/hooks/useRoutes';
import { emptyRoutesConfig } from '@virtuslab/nfs-shared/src/services/hooks/useRoutes';
import { mergeConfigs } from '@virtuslab/nfs-shared/src/services/routes';
import type { RoutesConfig, WrapRoutes } from '@virtuslab/nfs-shared/src/services/routes';

import SecureRoutesWrapper from '../components/organisms/SecureRoutesWrapper';
import login from '../components/pages/AuthLogin/config';
import logout from '../components/pages/AuthLogout/config';
import benefits from '../components/pages/Benefits/config';
import businessTravels from '../components/pages/BusinessTravels/config';
import dashboard from '../components/pages/Dashboard/config';
import directReporting from '../components/pages/DirectReporting/config';
import error404 from '../components/pages/Error404/config';
import settings from '../components/pages/Settings/config';
import timeReport from '../components/pages/TimeReport/config';

import { BaseRoutes } from './paths';

const defaultSecureConfig: RoutesConfig<WrapRoutes<typeof BaseRoutes>> = {
  paths: {
    [BaseRoutes.BASE]: {
      render: SecureRoutesWrapper,
    },
    [BaseRoutes.CONTRACT_ROUTE]: {
      render: () => <Outlet />,
    },
  },
};

const secureRoutes: AnyRoutesConfig = [
  defaultSecureConfig,
  timeReport,
  businessTravels,
  dashboard,
  benefits,
  settings,
  directReporting,
  error404,
].reduce(mergeConfigs, emptyRoutesConfig);

const insecureRoutes: AnyRoutesConfig = [
  login,
  logout,
].reduce(mergeConfigs, emptyRoutesConfig);

const config = mergeConfigs(insecureRoutes, secureRoutes);

export default config;
