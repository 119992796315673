import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';

import styled from 'styled-components/macro';

import type { ModalProps } from '@mui/material/Modal';
import MuiModal from '@mui/material/Modal';

const BaseModal = styled(MuiModal)`
  &&& {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;

    & > *[aria-hidden='true'] {
      display: none;
    }
  }
`;

const Modal = forwardRef((props: ModalProps, ref: ForwardedRef<HTMLDivElement>) => (
  <BaseModal
    {...props}
    slotProps={{
      ...props.slotProps,
      backdrop: {
        'aria-hidden': false,
        ...props.slotProps?.backdrop,
      },
    }}
    ref={ref}
  />
));

export default Modal;
