import type { ReactElement } from 'react';
import { useEffect, useRef } from 'react';

import { createPortal } from 'react-dom';
import type { ToastContainerProps } from 'react-toastify';
import { ToastContainer as ToastifyToastContainer } from 'react-toastify';
import styled from 'styled-components/macro';
import 'react-toastify/dist/ReactToastify.css';

import ToastCloseButton from '../../molecules/ToastCloseButton';
import ToastIcon from '../../molecules/ToastIcon';

const StyledToastsContainer = styled.div`
  bottom: 0;
  padding: ${({ theme }) => theme.scTheme.space[600]};
  pointer-events: none;
  position: absolute;
  z-index: ${({ theme }) => theme.scTheme.zindex.toasts};

  .Toastify__toast-container {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.scTheme.space[500]};
    max-width: 80%;
    width: max-content;
  }

  .Toastify__toast {
    align-items: flex-start;
    background-color: ${({ theme }) => theme.scTheme.palette.inverted.white};
    border: ${({ theme }) => theme.scTheme.borders.styles.thin.grey[20]};
    border-radius: ${({ theme }) => theme.scTheme.borders.radii.md};
    box-shadow: ${({ theme }) => theme.scTheme.shadows.largeShadow};
    display: flex;
    font-family: inherit;
    margin: 0;
    max-width: 100%;
    min-height: min-content;
    padding: ${({ theme }) => theme.scTheme.space[500]};
    pointer-events: all;
  }

  .Toastify__toast > button {
    margin-left: ${({ theme }) => theme.scTheme.space[500]};
  }

  .Toastify__toast-icon + div {
    width: 100%;
  }

  .Toastify__toast-body {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    margin: 0;
    max-width: 95%;
    padding: 0;
  }
`;

type Props = Omit<ToastContainerProps, 'theme'>;

export const toastContainerId = 'toast-container';

const ToastsContainer = (props: Props): ReactElement => {
  const node = useRef<HTMLDivElement>(document.createElement('div'));

  useEffect(() => {
    node.current.id = toastContainerId;
    document.body.appendChild(node.current);
  }, []);

  return createPortal(
    <StyledToastsContainer>
      <ToastifyToastContainer
        position="bottom-left"
        hideProgressBar
        closeButton={ToastCloseButton}
        icon={ToastIcon}
        {...props}
      />
    </StyledToastsContainer>,
    node.current,
  );
};

export default ToastsContainer;
