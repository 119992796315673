import type { ReactNode, ReactElement } from 'react';
import { useMemo, useContext, createContext } from 'react';

import isNil from 'lodash/isNil';

import type {
  CommonVariables,
  RequiredDocuments,
  RequiredHooks,
} from '../../../config/hooks';

export type ContextValue = Readonly<{
  hooks: RequiredHooks;
  commonVariables: CommonVariables;
  documents: RequiredDocuments;
}>;

const context = createContext<ContextValue | undefined>(undefined);

type Props = Readonly<{
  hooks: RequiredHooks;
  commonVariables?: CommonVariables;
  documents: RequiredDocuments;
  children: ReactNode;
}>;

const HooksProvider = ({
  hooks, commonVariables, children, documents,
}: Props): ReactElement => {
  const contextValue = useMemo<ContextValue>(() => ({
    // casting specific hooks to work as interchangeable hooks
    // allowing the addition of variables from the other hook in their pair.
    hooks: hooks as unknown as ContextValue['hooks'],
    commonVariables: commonVariables ?? {},
    documents,
  }), [hooks, commonVariables, documents]);

  return (
    <context.Provider value={contextValue}>
      {children}
    </context.Provider>
  );
};

export const useHooks = (): ContextValue => {
  const hooks = useContext(context);

  if (isNil(hooks)) {
    throw Error('Used useHooks outside of HooksProvider');
  }

  return hooks;
};

export default HooksProvider;

