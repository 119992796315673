import type {
  StringLocale, MixedLocale, NumberLocale, ArrayLocale,
} from 'yup/lib/locale';
import type { Message, MessageParams } from 'yup/lib/types';

import type { MessageKey, InterpolatedVariables } from './messages';

const messageFn = <MessageArg extends MessageKey>(
  key: MessageArg,
) => <Variables extends InterpolatedVariables<MessageArg>>(
    values: Variables,
  ) => ({
      key,
      values,
    });
type PlainMessage = Readonly<{ key: MessageKey }>;

type MappedLocale<YupLocale> = Readonly<{
  [key in keyof YupLocale]: NonNullable<YupLocale[key]> extends Message<infer U>
    ? PlainMessage | (
      (arg: MessageParams & U) => PlainMessage & { values: Partial<MessageParams & U> }
    ) : never
}>;

interface YupLocale {
  mixed: MappedLocale<MixedLocale>;
  string: MappedLocale<StringLocale>;
  number: MappedLocale<NumberLocale>;
  array: MappedLocale<ArrayLocale>;
}

const yupLocale: YupLocale = {
  mixed: {
    required: { key: 'This field must not be empty' },
    oneOf: { key: 'This field must be selected from provided options' },
    notType: messageFn('This field must be a valid {{type}}'),
  },
  string: {
    email: { key: 'E-mail must be valid' },
    uuid: { key: 'This field must be a valid UUID' },
    lowercase: { key: 'This field must be lowercase' },
    uppercase: { key: 'This field must be uppercase' },
    url: { key: 'This field must be a valid URL' },
    min: messageFn('This field must be at least {{min}} characters long'),
    max: messageFn('This field must be at most {{max}} characters long'),
    length: messageFn('This field must be exactly {{length}} characters long'),
  },
  number: {
    negative: { key: 'This field must be a negative number' },
    positive: { key: 'This field must be a positive number' },
    integer: { key: 'This field must be an integer' },
    lessThan: messageFn('This field must be less than {{less}}'),
    moreThan: messageFn('This field must be more than {{more}}'),
    max: messageFn('This field must be at most {{max}}'),
    min: messageFn('This field must be at least {{min}}'),
  },
  array: {
    min: messageFn('This field must have at least {{min}} values selected'),
  },
};

export default yupLocale;
