import { gql } from '@apollo/client';

// language=GraphQL
export default gql(`
  directive @client on FIELD

  interface Highlight {
    id: ID
  }

  #TODO: #405
  interface AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
  }

  type TimeReportPreferencesDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    requiredContractId: ContractId!
  }

  type InvoicesDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
  }

  extend type Query {
    drawer(id: ID!): AbstractDrawer
    rootDrawer: AbstractDrawer
    topDrawer: AbstractDrawer
  }
`);
