const sizes = {
  xxs: { icon: '1rem', box: '1.2rem' },
  xs: { icon: '1rem', box: '2rem' },
  sm: { icon: '1.2rem', box: '1.6rem' },
  md: { icon: '1.6rem', box: '1.6rem' },
  lg: { icon: '2rem', box: '2rem' },
  xl: { icon: '2rem', box: '4rem' },
};

const icons = {
  sizes,
};

export const defaultIconProps = {
  size: '100%',
  mirrored: false,
  weight: 'regular',
  color: 'currentColor',
} as const;

export type Size = keyof typeof sizes;
export default icons;
