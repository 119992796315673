import isDate from 'lodash/isDate';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import type { StringUnitLength } from 'luxon';
import { DateTime, Info } from 'luxon';

import { diffInDays } from '../date';

import type { FormattingFn } from '.';

export enum DateFormat {
  yearMonth = 'yyyy.LL',
  yearMonthDay = 'yyyy.LL.dd',
  monthShortYear = 'LL/yy',
  monthNameYear = 'LLLL yyyy',
  monthNameDayYear = 'LLLL dd yyyy',
  dayMonth = 'dd.LL',
  dayMonthYear = 'dd.LL.yyyy',
  monthYear = 'LL.yyyy',
  weekdayYearMonthDay = 'ccc, yyyy.LL.dd',
  month = 'LLLL',
}

export enum TimeUnit {
  hour = 'hour',
  month = 'month',
  day = 'day',
  year = 'year',
}

const date: FormattingFn<Date | DateTime | string, string, DateFormat> = (
  { language },
) => (dateToFormat, dateFormat) => {
  if (isString(dateToFormat)) {
    return DateTime.fromISO(dateToFormat).setLocale(language).toFormat(dateFormat);
  }

  if (isDate(dateToFormat)) {
    return DateTime.fromJSDate(dateToFormat).setLocale(language).toFormat(dateFormat);
  }

  return dateToFormat.setLocale(language).toFormat(dateFormat);
};

type RelativeDateArg = Readonly<{
  date: Date | DateTime;
  relativeTo: Date | DateTime;
  limit?: Readonly<{
    days: number;
    dateFormat: DateFormat;
  }>;
}>;

const relativeDate: FormattingFn<RelativeDateArg> = (
  config,
) => (
  arg,
) => {
  const diff = diffInDays(arg.date, arg.relativeTo);

  if (!isNil(arg.limit) && Math.abs(diff) > arg.limit.days) {
    return date(config)(arg.date, arg.limit.dateFormat);
  }

  return new Intl.RelativeTimeFormat(config.language, { numeric: 'auto' }).format(diff, 'day');
};

const hoursFN: FormattingFn<{ date: Date | string; relativeTo?: Date | string }> = (
  config,
) => (
  input,
) => {
  const relativeTo = DateTime.fromJSDate(new Date(input.relativeTo ?? new Date()));
  const baseDate = DateTime.fromJSDate(new Date(input.date));

  const formattedTime = baseDate.setLocale(config.language).toLocaleString(DateTime.TIME_SIMPLE);

  if (relativeTo.hasSame(baseDate, 'day')) {
    return formattedTime;
  }

  return `${date(config)(input.date, DateFormat.yearMonthDay)}, ${formattedTime}`;
};

const minutes: FormattingFn<number, string, StringUnitLength | 'clock'> = (
  { language, t },
) => (
  minutesToFormat, format,
) => {
  const hoursAmount = minutesToFormat < 0
    ? Math.ceil(minutesToFormat / 60) : Math.floor(minutesToFormat / 60);
  const minutesAmount = Math.abs(minutesToFormat % 60);

  if (format === 'clock') {
    return `${hoursAmount < 10 ? `0${hoursAmount}` : hoursAmount} ${t('h')} ${minutesAmount < 10 ? `0${minutesAmount}` : minutesAmount} ${t('m')}`;
  }

  const unparsedHours = new Intl.NumberFormat(language, { style: 'unit', unit: 'hour', unitDisplay: format }).format(hoursAmount);

  const hours = unparsedHours.includes(hoursAmount.toString())
    ? unparsedHours.replace(new RegExp(`${hoursAmount}\\s?`), `${hoursAmount} `)
    : unparsedHours;

  if (minutesToFormat % 60 === 0) {
    return hours;
  }

  const unparsedMinutes = new Intl.NumberFormat(language, { style: 'unit', unit: 'minute', unitDisplay: format }).format(minutesAmount);

  const formattedMinutes = unparsedMinutes.includes(minutesAmount.toString())
    ? unparsedMinutes.replace(new RegExp(`${minutesAmount}\\s?`), `${minutesAmount} `)
    : unparsedMinutes;

  return `${hours} ${formattedMinutes}`;
};

const timeUnit: FormattingFn<number, string, TimeUnit> = (
  { language },
) => (daysToFormat, tUnit) => new Intl.NumberFormat(language, { style: 'unit', unit: tUnit, unitDisplay: 'short' }).format(daysToFormat);

interface DateRange {
  from: Date | DateTime | string;
  to: Date | DateTime | string;
}
const dateRange: FormattingFn<DateRange, string, DateFormat> = (
  config,
) => (rangeToFormat, dateFormat) => {
  const formattedStart = date(config)(rangeToFormat.from, dateFormat);
  const formattedEnd = date(config)(rangeToFormat.to, dateFormat);

  if (formattedStart === formattedEnd) {
    return formattedStart;
  }

  return `${formattedStart} - ${formattedEnd}`;
};

const weekday: FormattingFn<Date | number, string, StringUnitLength> = (
  { language },
) => (day, format) => {
  const weekdayIndex = isDate(day) ? day.getDay() : day;

  const weekdays = Info.weekdays(format, { locale: language });
  return weekdays[(weekdayIndex + 6) % 7];
};

const clock: FormattingFn<number> = () => (time) => {
  const minutesPart = time % 60;
  const hoursPart = Math.floor(time / 60);

  return `${hoursPart < 10 ? `0${hoursPart}` : hoursPart}:${minutesPart < 10 ? `0${minutesPart}` : minutesPart}`;
};

const time = {
  date,
  relativeDate,
  weekday,
  dateRange,
  minutes,
  timeUnit,
  clock,
  hours: hoursFN,
} as const;

export type Time = typeof time;

export default time;
