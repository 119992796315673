import { captureException } from '@sentry/react';
import findIndex from 'lodash/findIndex';
import take from 'lodash/fp/take';
import isNil from 'lodash/isNil';
import last from 'lodash/last';

import type { ReactiveVars } from '../../../..';
import type { Drawer } from '../../../../models/Drawers';

export default function createOpenChildDrawer(drawersVar: ReactiveVars['drawersVar']) {
  return (newDrawer: Drawer): void => {
    const drawers = drawersVar();
    const lastDrawer = last(drawers);

    if (isNil(lastDrawer)) {
      captureException('Tried to open a child drawer without a parent');
      drawersVar([newDrawer]);
    } else if (lastDrawer.visible) {
      drawersVar(drawers.concat([newDrawer]));
    } else {
      const indexOflastVisible = findIndex(drawers, { visible: true });
      const newDrawers = take(indexOflastVisible + 1, drawers);

      if (indexOflastVisible === -1) {
        captureException('Tried to open a child drawer without a visible parent');
        drawersVar([newDrawer]);
      } else {
        drawersVar(newDrawers.concat([newDrawer]));
      }
    }
  };
}
