import has from 'lodash/has';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';
import isPlainObject from 'lodash/isPlainObject';
import type { FieldError } from 'react-hook-form';

import type { DeepObjectPaths, ShallowReplaceNullable } from './object';

export const isNotNil = <T>(arg: T | null | undefined): arg is T => !isNil(arg);

export type Literal = Record<string, unknown>;
export const isLiteral = <T>(
  arg: unknown,
): arg is (T extends Literal ? T : Literal) => isPlainObject(arg);

export const isObjectDeepPath = <T extends Literal>(
  obj: T,
  path: number | string,
): path is DeepObjectPaths<T> => has(obj, path);

export const isError = (arg: unknown): arg is FieldError => isLiteral(arg) && 'ref' in arg && 'message' in arg;

export const isArrayOf = <T>(
  arg: unknown,
  guard: (item: unknown) => item is T,
): arg is T[] => Array.isArray(arg) && arg.every(guard);

export const isObjectOf = <T>(
  arg: unknown,
  guard: (item: unknown) => item is T,
): arg is Record<string, T> => isObject(arg) && Object.values(arg).every(guard);

export const isObjectWithKeys = <T>(
  arg: unknown,
  requiredKeys: (keyof T)[],
): arg is T => isObject(arg) && requiredKeys.every((key) => key in arg);

export const isObjectWithPartialKeys = <T>(
  arg: unknown,
  requiredKeys: (keyof T)[],
): arg is Partial<T> => isObject(arg) && requiredKeys.some((key) => key in arg);

export const isShallowNonNullableObject = <T extends Literal>(
  data: T | undefined,
): data is ShallowReplaceNullable<T> => {
  if (isNil(data)) {
    return false;
  }

  return Object.values(data).every(isNotNil) && Object.keys(data).length !== 0;
};

export const isHTMLElement = (arg: unknown): arg is HTMLElement => arg instanceof HTMLElement;
