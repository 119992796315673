import type { Language } from './changeLanguage';

export const Day = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
} as const;

export const DayToDayName: Record<string, keyof typeof Day> = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};

export type LocaleInfo = Readonly<{
  firstDayOfWeek: number;
  daysFreeOfWork: number[];
  prohibitedWorkingDays: number[];
  language: string;
}>;

const localesInfo: Record<Language, LocaleInfo> = {
  'en-GB': {
    firstDayOfWeek: Day.Monday,
    daysFreeOfWork: [Day.Saturday, Day.Sunday],
    prohibitedWorkingDays: [Day.Sunday],
    language: 'en',
  },
  'pl-PL': {
    firstDayOfWeek: Day.Monday,
    daysFreeOfWork: [Day.Saturday, Day.Sunday],
    prohibitedWorkingDays: [Day.Sunday],
    language: 'pl',
  },
};

export default localesInfo;
