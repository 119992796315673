import flow from 'lodash/fp/flow';
import parseInt from 'lodash/fp/parseInt';
import subtract from 'lodash/fp/subtract';

const toUpperBoundary = flow(parseInt(10), subtract(-0.2));

export const breakpoints = {
  xs: 0,
  sm: '768px',
  md: '992px',
  lg: '1200px',
  xl: '1920px',
} as const;

export const mediaQueries = {
  xs: `only screen and (max-width: ${toUpperBoundary(breakpoints.sm)})`,
  sm: `only screen and (min-width: ${breakpoints.sm}) and (max-width: ${toUpperBoundary(breakpoints.md)})`,
  md: `only screen and (min-width: ${breakpoints.md}) and (max-width: ${toUpperBoundary(breakpoints.lg)})`,
  lg: `only screen and (min-width: ${breakpoints.lg}) and (max-width: ${toUpperBoundary(breakpoints.xl)})`,
  xl: `only screen and (min-width: ${breakpoints.xl})`,
  // xsUp: // always visible,
  smUp: `only screen and (min-width: ${breakpoints.sm})`,
  mdUp: `only screen and (min-width: ${breakpoints.md})`,
  lgUp: `only screen and (min-width: ${breakpoints.lg})`,
  xlUp: `only screen and (min-width: ${breakpoints.xl})`,
  xsDown: `only screen and (max-width: ${toUpperBoundary(breakpoints.sm)})`,
  smDown: `only screen and (maxwidth: ${toUpperBoundary(breakpoints.md)})`,
  mdDown: `only screen and (max-width: ${toUpperBoundary(breakpoints.lg)})`,
  lgDown: `only screen and (max-width: ${toUpperBoundary(breakpoints.xl)})`,
  // xlDown: // always visible,
} as const;

export type Breakpoint = keyof typeof breakpoints;
export type MediaQuery = keyof typeof mediaQueries;
