import { useContext, createContext } from 'react';

import isNil from 'lodash/isNil';
import { useParams } from 'react-router';

import type { Contract } from '../../components/organisms/SecureRoutesWrapper';
import { contractParamName } from '../../config/component';

export type { Contract };

export type ContextValue = Readonly<{
  contracts: readonly Contract[];
}>;

export const context = createContext<ContextValue>({
  contracts: [],
});

type Params = Partial<Record<typeof contractParamName, string>>;

const parseUrlParam = (params: Params) => {
  const param = params[contractParamName];

  const parsedContractIndex = Number.isFinite(Number(param))
    ? Number(param)
    : null;

  return parsedContractIndex;
};

type UseContractReturnValue = Readonly<{
  selectedContract: Contract | null;
  selectedContractIndex: number | null;
  contracts: readonly Contract[];
}>;

const useContract = (): UseContractReturnValue => {
  const { contracts } = useContext(context);

  const params = useParams<Params>();
  const urlContractIndex = parseUrlParam(params);

  const selectedContract = (isNil(urlContractIndex)
    ? null
    : contracts[urlContractIndex]) ?? null;

  return {
    selectedContract,
    selectedContractIndex: urlContractIndex,
    contracts,
  };
};

export default useContract;
