import { useEffect, useRef } from 'react';
import type { ReactElement, ReactNode } from 'react';

import type { ErrorComponentProps } from '@virtuslab/react-oauth2';
import { SecurityProvider as OAuthProvider } from '@virtuslab/react-oauth2';
import isNil from 'lodash/isNil';
import { Navigate } from 'react-router-dom';

import type { Config } from '../../../config';

import Loading from '../../molecules/Loading';

type SendErrorProps = Pick<ErrorComponentProps, 'error'> & Readonly<{
  children: ReactNode;
  onError: (error: NonNullable<ErrorComponentProps['error']>) => void;
}>;

const SendError = ({ error, children, onError }: SendErrorProps) => {
  const onErrorRef = useRef(onError);
  onErrorRef.current = onError;

  useEffect(() => {
    if (!isNil(error)) {
      onErrorRef.current(error);
    }
  }, [error]);

  return (
    <>
      {children}
    </>
  );
};

type SecurityProviderConfig = Pick<Config, 'baseUrl' | 'isLocalDevelopment' | 'keycloak'>;

type Props = Pick<SendErrorProps, 'onError'> & Readonly<{
  children: ReactNode;
  redirectTo: string;
  config: SecurityProviderConfig;
}>;

const SecurityProvider = ({
  children, onError, redirectTo, config,
}: Props): ReactElement => (
  <OAuthProvider
    url={config.keycloak.url}
    realm={config.keycloak.realm}
    clientId={config.keycloak.clientID}
    silentCheckSsoRedirectUri={`${config.baseUrl}/silent-check-sso.html`}
    fallback={<Loading />}
    error={({ error }) => (
      <SendError error={error} onError={onError}>
        {(config.isLocalDevelopment
          ? null
          : <Navigate to={redirectTo} />)}
      </SendError>
    )}
  >
    {children}
  </OAuthProvider>
);

export default SecurityProvider;
