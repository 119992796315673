import type { ReactElement } from 'react';
import { useContext, createContext, Suspense } from 'react';

import useRoutes from '../../../services/hooks/useRoutes';
import type { RoutesConfig, RoutesConfigObjectShape } from '../../../services/routes';

import Loading from '../../molecules/Loading';

type ContextValue = RoutesConfig<RoutesConfigObjectShape>;

const context = createContext<ContextValue>({ paths: {} });

type Props<Routes extends RoutesConfig<RoutesConfigObjectShape>> = Readonly<{
  routes: Routes;
}>;

const RenderRoutes = <Routes extends RoutesConfig<RoutesConfigObjectShape>>({
  routes,
}: Props<Routes>): ReactElement | null => {
  const { renderableRoutes } = useRoutes({ routes });
  return (
    <context.Provider value={routes}>
      <Suspense fallback={<Loading />}>
        {renderableRoutes}
      </Suspense>
    </context.Provider>
  );
};

export const useRoutesConfig = (): ContextValue => useContext(context);

export default RenderRoutes;
