import { lazy } from 'react';

import type { RoutesConfig, WrapRoutes } from '@virtuslab/nfs-shared/src/services/routes';

import { TimeReportRoutes } from '../../../config/paths';

const TimeReportPage = lazy(async () => import('.'));

const config: RoutesConfig<WrapRoutes<typeof TimeReportRoutes>> = {
  paths: {
    [TimeReportRoutes.TIME_REPORT_MONTHLY]: {
      render: TimeReportPage,
    },
    [TimeReportRoutes.TIME_REPORT_MONTHLY_WITH_DAY]: {
      reusePath: TimeReportRoutes.TIME_REPORT_MONTHLY,
      render: TimeReportPage,
    },
    [TimeReportRoutes.TIME_REPORT_MONTHLY_WITH_ENTRY]: {
      reusePath: TimeReportRoutes.TIME_REPORT_MONTHLY,
      render: TimeReportPage,
    },
    [TimeReportRoutes.TIME_REPORT]: {
      reusePath: TimeReportRoutes.TIME_REPORT_MONTHLY,
      render: TimeReportPage,
    },
  },
};

export default config;
