import deburr from 'lodash/deburr';
import isNil from 'lodash/isNil';
import takeWhile from 'lodash/takeWhile';
import { DateTime } from 'luxon';

import type { User } from '../schema/admin';

import { isNotEmptyString } from './checks';

type WithNamePrefixReturnType<
  Prefix extends string | undefined, FieldName extends string,
  > = string extends Prefix
    ? `${FieldName}`
    : undefined extends Prefix
      ? `${FieldName}`
      : `${Prefix}.${FieldName}`;

export const withNamePrefix = <Prefix extends string | undefined, FieldName extends string>(
  prefix: Prefix, name: FieldName,
): WithNamePrefixReturnType<Prefix, FieldName> => (
    isNotEmptyString(prefix)
      ? `${prefix}.${name}` : `${name}`
  ) as WithNamePrefixReturnType<Prefix, FieldName>;

export const stringGroupRepresentation = (str: string): string => {
  if (/^[a-z]/i.test(deburr(str))) {
    return deburr(str[0]).toLocaleUpperCase();
  }

  if (/^\d/.test(str)) {
    return '0-9';
  }

  return '#';
};

export const nonUniqueUsernameFromFirstAndLastName = (user: Pick<User, 'firstName' | 'lastName'>): string => `${user.firstName.trim()[0] || ''}${user.lastName.trim()}`.replace(/\s+/g, '-');
export const usernameFromEmail = (user: Pick<User, 'email'>): string => user.email.split('@')[0];

export const parseURLDateParts = (year: string, month?: string, day?: string): Date | null => {
  const unparsedParts: [string | undefined, string][] = takeWhile([
    [year, 'yyyy'],
    [month, 'M'],
    [day, 'd'],
  ], ([value]) => !isNil(value));

  const parts = unparsedParts.reduce<[string, string][]>((prevParts, currentPart) => {
    const [currentPartValue, currentPartKey] = currentPart;
    if (isNil(currentPartValue)) {
      return prevParts;
    }

    return [...prevParts, [currentPartValue, currentPartKey]];
  }, []);

  const current = DateTime.fromFormat(parts.map(([value]) => value).join('.'), parts.map(([, key]) => key).join('.'));

  if (current.isValid) {
    return current.toJSDate();
  }

  return null;
};

export default {};
