import type { ReactElement } from 'react';

import type { TypeOptions, IconProps } from 'react-toastify';

import {
  CheckCircle, Info, Warning, WarningCircle,
} from 'phosphor-react';

import palette from '../../../theme/palette';

import Icon from '../Icon';

type Props = IconProps;

const variants: Record<TypeOptions, ReactElement> = {
  default: <Info fill={palette.greyscale[60]} weight="fill" />,
  info: <Info fill={palette.support.info[60]} weight="fill" />,
  error: <WarningCircle fill={palette.support.alert[60]} weight="fill" />,
  warning: <Warning fill={palette.support.warning[60]} weight="fill" />,
  success: <CheckCircle fill={palette.support.success[60]} weight="fill" />,
};

const ToastIcon = ({ type }: Props): ReactElement => (
  <Icon size="lg">
    {variants[type]}
  </Icon>
);

export default ToastIcon;
