import type { FeatureFlags } from '@virtuslab/nfs-shared/src/config/featureFlags';

export const featureFlags: FeatureFlags = {
  allowReportingBreak: false,
  allowMaternityLeave: false,
  allowChangingInvoiceStatus: false,
  enforceInvoiceValidation: true,
  ignoreWorkTagLimits: false,
  useACL: true,
  clearCachePeriodically: false,
  ignoreLeaveLimits: false,
};

export default {};
