import get from 'lodash/get';
import isBoolean from 'lodash/isBoolean';
import isDate from 'lodash/isDate';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import mapValues from 'lodash/mapValues';
import * as yup from 'yup';
import type { SchemaObjectDescription } from 'yup/es/schema';
import type { SchemaFieldDescription } from 'yup/lib/schema';

import { isEmpty, isNotEmptyString } from './checks';
import type { FormattingService } from './format';
import { DateFormat } from './format';
import type { Literal } from './guards';
import { isObjectDeepPath } from './guards';
import type {
  DeepObjectPaths, ReplaceObjectValues, FormPrefixes, ObjectShape, GetDiffArgs, Nullable,
} from './object';
import { getDiff, replaceObjectValues } from './object';
import { withNamePrefix } from './string';

export type InteractivityState = boolean | string;

export const interactivityStateToBoolean = (
  state: Nullable<InteractivityState>,
): boolean => {
  if (isBoolean(state)) {
    return state;
  }

  if (isNil(state)) {
    return false;
  }

  return isNotEmptyString(state);
};

export const interactivityStateToReason = (
  state: Nullable<InteractivityState>,
): Nullable<string> => {
  if (isBoolean(state)) {
    return null;
  }

  if (isNil(state)) {
    return null;
  }

  return isNotEmptyString(state) ? state : null;
};

export type SchemaMappingFn = <T extends yup.AnySchema>(field: T, pathSoFar: string) => T;

export const makeRequired = (error?: string) => <T extends yup.AnySchema>(field: T): T => field.required(error) as T;
export const makeOptional = <T extends yup.AnySchema>(field: T): T => field.notRequired() as T;

type ModifySchemaArg<Schema extends yup.AnySchema> = Readonly<{
  schema: Schema;
  paths?: string[];
  mapField: SchemaMappingFn;
  compareFieldPaths?: (field: string, path: string) => boolean;
}>;

export const modifySchema = <Schema extends yup.AnySchema>({
  schema,
  paths,
  mapField,
  compareFieldPaths = (field, path) => field === path,
}: ModifySchemaArg<Schema>): Schema => {
  if (isEmpty(paths) && !isNil(paths)) {
    return schema;
  }

  const mapFields = <T extends yup.AnySchema>(field: T, pathSoFar?: string): T => {
    if (field instanceof yup.ObjectSchema) {
      return yup.object(mapValues(field.fields, (objectField, path) => {
        if (objectField instanceof yup.BaseSchema) {
          return mapFields(objectField, withNamePrefix(pathSoFar, path));
        }
        return objectField as yup.AnySchema;
      })) as unknown as T;
    }

    if (isNil(paths)) {
      return mapField(field, '');
    }

    if (!isEmpty(pathSoFar) && paths.some((path) => compareFieldPaths(pathSoFar, path))) {
      return mapField(field, pathSoFar);
    }

    return field;
  };

  return mapFields(schema);
};

export const toEmptyForm = <Schema extends yup.AnySchema>(
  schema: Schema,
): Partial<yup.InferType<Schema>> => {
  if (schema instanceof yup.ObjectSchema) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return mapValues<yup.InferType<Schema>, any>(schema.fields as unknown, (field?: unknown) => {
      if (isNil(field) || typeof field !== 'object') {
        return null;
      }

      if (field instanceof yup.ObjectSchema) {
        return toEmptyForm(field);
      }

      if (field instanceof yup.StringSchema) {
        return '';
      }

      if (field instanceof yup.ArraySchema) {
        return [];
      }

      if (field instanceof yup.BooleanSchema) {
        return false;
      }

      if (field instanceof yup.NumberSchema) {
        return '';
      }

      return null;
    });
  }

  return {};
};

export const getTypedPrefixes = <Schema extends Literal>(
) => <T extends FormPrefixes<Schema>>(path: T): T => path;

const formatFieldValue = (config: MakeCreateDiffConfig) => (value?: unknown): string => {
  if (isNil(value) || isEmpty(value)) {
    return config.getEmptyText();
  }

  if (isString(value)) {
    return value;
  }

  if (isDate(value)) {
    return config.format.date(value, DateFormat.yearMonthDay);
  }

  if (isNumber(value)) {
    return value.toString();
  }

  if (isBoolean(value)) {
    return config.getBooleanText(value);
  }

  return '';
};

type MakeCreateDiffConfig = Readonly<{
  format: FormattingService;
  getEmptyText: () => string;
  getChangeText: (arg: { field: string; previousValue: string; newValue: string }) => string;
  getBooleanText: (arg: boolean) => string;
}>;

type MakeCreateDiffArg<
InitialDataShape extends Literal,
InputShape extends ObjectShape<InitialDataShape>,
> = GetDiffArgs<InitialDataShape, InputShape> & Readonly<{
  getFieldDisplayName: (
    fieldName: DeepObjectPaths<InitialDataShape> | DeepObjectPaths<InputShape>) => string;
  getCustomValues?: (
    fieldName: DeepObjectPaths<InitialDataShape> | DeepObjectPaths<InputShape>) => ({
    previousValue: unknown;
    currentValue: unknown;
  }) | undefined;
}>;

export const makeCreateFormDiff = (
  config: MakeCreateDiffConfig,
) => <InitialDataShape extends Literal, InputShape extends ObjectShape<InitialDataShape>>({
  omitFields,
  initialData,
  userInput,
  getFieldDisplayName,
  getCustomValues,
}: MakeCreateDiffArg<InitialDataShape, InputShape>,
): ReplaceObjectValues<ObjectShape<InitialDataShape>, unknown, string> => {
  const formatValue = formatFieldValue(config);
  const diff = getDiff<InitialDataShape, InputShape>({ omitFields, initialData, userInput });

  return replaceObjectValues(
    diff,
    isString,
    (_, path) => {
      if (!isObjectDeepPath(initialData, path) && !isObjectDeepPath(userInput, path)) {
        return null;
      }

      const customValues = getCustomValues?.(path);

      const previousValue: unknown = customValues
        ? customValues.previousValue
        : get(initialData, path);
      const currentValue: unknown = customValues
        ? customValues.currentValue
        : get(userInput, path);

      return config.getChangeText({
        field: getFieldDisplayName(path),
        previousValue: formatValue(previousValue),
        newValue: formatValue(currentValue),
      });
    },
  ) as ReplaceObjectValues<InputShape, unknown, string>;
};

export const isSchemaObjectDescription = (
  x: SchemaFieldDescription,
): x is SchemaObjectDescription => 'fields' in x;

export const deepSchemaPaths = <Fields extends Record<string, SchemaFieldDescription>>(
  obj: Fields,
): string[] => Object
    .keys(obj)
    .flatMap((key) => {
      const value = obj[key];
      return isSchemaObjectDescription(value)
        ? deepSchemaPaths(value.fields)
          .map((deepKey) => `${key}.${deepKey}`)
        : [key];
    });

export default {};
