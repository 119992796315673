import styled from 'styled-components/macro';

import background from '../../../assets/images/backgrounds/mountains.svg';

type Props = Readonly<{
  height?: number;
}>;

const Background = styled.div<Props>`
  background-image: url(${background});
  background-position-x: center;
  background-position-y: top;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  height: ${({ height = 47 }) => `${height}%`};
  left: 0;
  position: absolute;
  width: 100%;
  z-index: ${({ theme }) => theme.scTheme.zindex.background};
`;

export default Background;
