import type {
  ReactElement,
  ReactNode,
  KeyboardEvent,
  MouseEventHandler,
  KeyboardEventHandler,
} from 'react';
import {
  useEffect,
  useRef,
  useState,
} from 'react';

import type { UseMultipleSelectionGetDropdownProps, UseSelectGetToggleButtonPropsOptions } from 'downshift';
import { useMultipleSelection, useSelect } from 'downshift';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import identity from 'lodash/identity';
import includes from 'lodash/includes';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';
import last from 'lodash/last';
import noop from 'lodash/noop';
import { InView } from 'react-intersection-observer';
import { Virtuoso } from 'react-virtuoso';
import type { ItemProps as VirtuosoItemProps, ListProps as VirtuosoListProps, VirtuosoHandle } from 'react-virtuoso';
import styled from 'styled-components/macro';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import { isEmpty } from '../../../services/checks';
import type { InteractivityState } from '../../../services/form';
import { interactivityStateToBoolean, interactivityStateToReason } from '../../../services/form';
import { isObjectWithKeys } from '../../../services/guards';
import type { Nullable } from '../../../services/object';

import DropdownContainer from '../../atoms/DropdownContainer';
import Hidden from '../../atoms/Hidden';
import Body from '../Body';
import type { Props as DropdownItemProps } from '../DropdownItem';
import DropdownItem from '../DropdownItem';
import DropdownItemSkeleton from '../DropdownItemSkeleton';
import Tooltip from '../Tooltip';
import type { ListItem } from '../VirtualizedList';
import { ListItemType } from '../VirtualizedList';

// hack: change position of the list from absolute to set dimensions of a container
//  set height to not 0 to display first item
const ScrollerComponent = styled.div`
  overflow-x: visible;
  z-index: ${({ theme }) => theme.scTheme.zindex.floating};

  & > * {
    max-height: ${({ theme }) => theme.scTheme.heights.max.dropdown};
    min-height: .1rem;
    position: relative !important;
  }
`;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ListItemComponent = styled.li<VirtuosoItemProps<any>>``;
const ListComponent = styled.ul<VirtuosoListProps>`
  list-style-type: none;
`;
const VirtuosoListContainer = styled.div`
  &:focus {
    outline: 0;
  }
`;

type DropdownListItemType<ItemValue, AdditionalProps> = ListItem<
Item<ItemValue, AdditionalProps>, { id: string }>;

export const idValuePair = <ItemValue extends unknown>(
  arg: ItemValue,
): Record<'id' | 'value', ItemValue> => ({
    id: arg,
    value: arg,
  });

type ItemProps = Readonly<{
  id?: string;
  onClick?: MouseEventHandler;
  onMouseMove?: MouseEventHandler;
  ref: (node: HTMLElement | null) => void;
}>;

type SelectedItemProps = Readonly<{
  onClick?: MouseEventHandler;
  onKeyDown?: KeyboardEventHandler;
  tabIndex?: number;
  ref: (node: HTMLElement | null) => void;
}>;

type ToggleButtonProps = Readonly<{
  id?: string;
  onClick?: MouseEventHandler;
  onKeyDown?: KeyboardEventHandler;
  ref: (node: HTMLElement | null) => void;
}>;

type LabelProps = Readonly<{
  id?: string;
  htmlFor?: string;
}>;

export const isGroup = <ItemValue extends unknown, AdditionalProps extends unknown>(
  arg?: Group<ItemValue, AdditionalProps> | Item<ItemValue, AdditionalProps>,
): arg is Group<ItemValue, AdditionalProps> => Boolean(isObject(arg) && 'items' in arg);

export const isSubmenuItem = <ItemValue extends unknown, AdditionalProps extends unknown>(
  arg: Item<ItemValue, AdditionalProps>,
): arg is SubmenuItem<ItemValue, AdditionalProps> => 'Submenu' in arg;

const toFlatItemList = <ItemValue extends unknown, AdditionalProps extends unknown>(
  items: Items<ItemValue, AdditionalProps>,
): DropdownListItemType<ItemValue, AdditionalProps>[] => items.flatMap((item) => {
    if (isGroup(item)) {
      const headerAndItems = [
        { id: `${item.id}-header`, data: item.label, type: ListItemType.header } as const,
        ...item.items.map((groupItem) => ({
          id: groupItem.id, data: groupItem, type: ListItemType.item,
        } as const)),
      ];

      return item === last(items)
        ? headerAndItems
        : [...headerAndItems, { id: `${item.id}-divider`, type: ListItemType.divider } as const];
    }

    return { id: item.id, data: item, type: ListItemType.item };
  });

const keyboardActions = [
  useSelect.stateChangeTypes.ToggleButtonKeyDownArrowDown,
  useSelect.stateChangeTypes.ToggleButtonKeyDownArrowUp,
  useSelect.stateChangeTypes.ToggleButtonKeyDownCharacter,
  useSelect.stateChangeTypes.ToggleButtonKeyDownEnd,
  useSelect.stateChangeTypes.ToggleButtonKeyDownEnter,
  useSelect.stateChangeTypes.ToggleButtonKeyDownEscape,
  useSelect.stateChangeTypes.ToggleButtonKeyDownHome,
  useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton,
];

export interface SubmenuRenderProps<ItemValue extends ValueItem, AdditionalProps = undefined> {
  selectedValues: ItemValue extends ValueItem<infer W> ? W[] : never[];
  select?: (value: ItemValue) => void;
  closeMenu: () => void;
  closeSubMenu: () => void;
  additionalProps?: AdditionalProps;
}

export interface BaseItem {
  id: string;
  label: string;
  renderLabel?: (label: string, item: BaseItem) => ReactNode;
  renderLink?: (element: JSX.Element) => ReactNode;
  onClick?: () => void;
}

export type SubmenuItem<ItemValue = unknown, AdditionalProps = undefined> = BaseItem & {
  additionalProps?: AdditionalProps;
  Submenu: (props: SubmenuRenderProps<ValueItem<ItemValue>, AdditionalProps>) => ReactElement;
};

export type ValueItem<ItemValue = unknown> = BaseItem & {
  onSelect?: () => void;
  selectedLabel?: string;
  value: ItemValue;
  disabled?: Nullable<InteractivityState>;
};

export type Item<ItemValue = unknown, AdditionalProps = undefined> =
SubmenuItem<ItemValue, AdditionalProps>
| ValueItem<ItemValue>;

export interface Group<ItemValue = unknown, AdditionalProps = undefined> {
  id: string;
  label?: ReactNode;
  items: Item<ItemValue, AdditionalProps>[];
  renderLink?: (element: JSX.Element, items?: Item<ItemValue, AdditionalProps>[]) => ReactNode;
}

export type Items<ItemValue, AdditionalProps = undefined> = (
  Group<ItemValue, AdditionalProps> | Item<ItemValue, AdditionalProps>
)[];

interface Props<ItemValue, AdditionalProps> {
  items: Items<ItemValue, AdditionalProps>;
  multiselect?: boolean;
  fetchMore?: () => Promise<unknown>;
  fetchMoreLoading?: boolean;
  showSelection?: boolean;
  loading?: boolean;
  value?: ValueItem<ItemValue>[];
  defaultValue?: ValueItem<ItemValue>[];
  virtualize?: boolean;
  disabled?: boolean;
  onSelect?: (item: ValueItem<ItemValue>) => void;
  onRemove?: (item: ValueItem<ItemValue>) => void;
  dropdownWidth?: string;
  itemProps?: Pick<DropdownItemProps, 'renderItem' | 'renderSelection' | 'textWrap'>;
  title?: ReactNode;
  render: (props: {
    isOpen: boolean;
    closeMenu: () => void;
    openMenu: () => void;
    buttonProps: (customProps?: UseMultipleSelectionGetDropdownProps) => ToggleButtonProps;
    labelProps: () => LabelProps;
    selectedItemProps: (
      props: { selectedItem: ValueItem<ItemValue>; index: number }) => SelectedItemProps;
    itemProps: (props: { item: ValueItem<ItemValue>; index: number }) => ItemProps;
    removeItem: (item: ValueItem<ItemValue>) => void;
    dropdown: ReactNode;
  }) => ReactNode;
}

const Dropdown = <ItemValue extends unknown, AdditionalProps = unknown>({
  items,
  render,
  multiselect = false,
  fetchMore,
  fetchMoreLoading = false,
  showSelection = false,
  loading = false,
  virtualize = false,
  disabled = false,
  defaultValue,
  value,
  onSelect,
  onRemove,
  dropdownWidth,
  itemProps,
  title,
}: Props<ItemValue, AdditionalProps>): ReactElement => {
  const selectableItems = items.flatMap(
    (item) => (isGroup(item) ? item.items : item),
  );

  const virtuoso = useRef<VirtuosoHandle>(null);

  const [usingKeyboard, setUsingKeyboard] = useState(false);
  const [openSubmenuId, setOpenSubmenuId] = useState<string | null>(null);

  const {
    getSelectedItemProps,
    getDropdownProps,
    selectedItems,
  } = useMultipleSelection({
    initialSelectedItems: defaultValue,
    selectedItems: value ?? [],
  });

  const {
    isOpen,
    openMenu,
    closeMenu,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    selectedItem: null,
    defaultHighlightedIndex: -1,
    items: selectableItems,
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      const { selectedItem } = changes;

      const shouldKeepOpen = !isNil(selectedItem) && (multiselect
        || isSubmenuItem(selectedItem)) && !disabled;

      switch (type) {
        case useSelect.stateChangeTypes.ToggleButtonKeyDownArrowDown:
          if (state.highlightedIndex === items.length - 1) {
            const newHighlightedItemIndex = items
              .findIndex((item) => !isObjectWithKeys(item, ['disabled']));

            return { ...changes, highlightedIndex: newHighlightedItemIndex };
          }
          return changes;

        case useSelect.stateChangeTypes.ToggleButtonKeyDownArrowUp:
          if (state.highlightedIndex === 0) {
            const newHighlightedItemIndex = items
              .reduceRight((acc, curr, index) => {
                if (!isObjectWithKeys(curr, ['disabled'])) {
                  return index;
                }
                return acc;
              }, -1);
            return { ...changes, highlightedIndex: newHighlightedItemIndex };
          }
          return changes;
        case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
        case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: shouldKeepOpen,
            highlightedIndex: state.highlightedIndex,
          };
        default: {
          return {
            ...changes,
            isOpen: disabled ? false : changes.isOpen,
          };
        }
      }
    },
    onStateChange: ({ type, selectedItem: currentItem, isOpen: menuOpen }) => {
      setUsingKeyboard(includes(keyboardActions, type));

      if (menuOpen === false) {
        setOpenSubmenuId(null);
      }

      switch (type) {
        case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
        case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:

          setOpenSubmenuId(isNil(currentItem) || isSubmenuItem(currentItem)
            ? currentItem?.id ?? null
            : null);

          if (isNil(currentItem) || isSubmenuItem(currentItem)) {
            break;
          } else if (find(selectedItems, { id: currentItem.id })) {
            onRemove?.(currentItem);
          } else {
            currentItem.onSelect?.();
            onSelect?.(currentItem);
          }
          break;
        default:
          break;
      }
    },
  });

  const flatItems = toFlatItemList(items).concat(
    fetchMoreLoading
      ? [{ id: 'fetch-more', type: ListItemType.skeleton }]
      : [],
  );

  useEffect(() => {
    if (usingKeyboard) {
      virtuoso.current?.scrollToIndex({
        index: highlightedIndex,
        align: 'center',
        behavior: 'smooth',
      });
    }
  }, [highlightedIndex, usingKeyboard]);

  const renderItem = (item: DropdownListItemType<ItemValue, AdditionalProps>) => {
    if (item.type === ListItemType.header) {
      const originalItem = items.find((mappedItem) => item.id.includes(mappedItem.id));

      if (!isNil(originalItem) && isGroup(originalItem)) {
        const renderLink = originalItem.renderLink ?? identity;
        const selected = originalItem.items
          .every((option) => !isNil(selectedItems.find((selectedItem) => selectedItem.id === option.id)));

        return (
          <div key={item.id}>
            {!isNil(item.data) && renderLink(
              <DropdownItem
                header
                virtualize={virtualize}
                showSelection={showSelection && selected}
                selected={selected}
              >
                {item.data}
              </DropdownItem>,
              originalItem.items,
            )}
          </div>
        );
      }

      return (
        <div key={item.id}>
          {!isNil(item.data) && (
            <DropdownItem header virtualize={virtualize}>
              {item.data}
            </DropdownItem>
          )}
        </div>
      );
    }

    if (item.type === ListItemType.item) {
      const { id } = item;

      const selected = Boolean(find(selectedItems, { id }));
      const index = findIndex(selectableItems, { id });

      const renderLink = item.data.renderLink ?? identity;
      const itemDisabled = !isSubmenuItem(item.data) ? item.data.disabled : undefined;

      return (
        <Tooltip
          key={id}
          enabled={!isEmpty(interactivityStateToReason(itemDisabled))}
          tooltipProps={{
            placement: 'top',
            title: <Body size={200} inverted>{interactivityStateToReason(itemDisabled)}</Body>,
          }}
        >
          <Box
            {...getItemProps({
              item: item.data,
              index,
              onClick: interactivityStateToBoolean(itemDisabled) ? noop : item.data.onClick,
              disabled: interactivityStateToBoolean(itemDisabled),
            })}
            position="relative"
          >
            {renderLink(
              <DropdownItem
                showSelection={showSelection}
                selected={selected}
                disabled={interactivityStateToBoolean(itemDisabled)}
                keyboardHighlight={usingKeyboard}
                highlighted={index === highlightedIndex}
                virtualize={virtualize}
                {...itemProps}
              >
                {!isNil(item.data.renderLabel)
                  ? item.data.renderLabel(item.data.label, item.data)
                  : item.data.label}
              </DropdownItem>,
            )}
            {isSubmenuItem(item.data) && openSubmenuId === item.id ? (
              <Box onClick={(e) => e.stopPropagation()}>
                <item.data.Submenu
                  selectedValues={selectedItems.map((selectedItem) => selectedItem.value)}
                  additionalProps={item.data.additionalProps}
                  select={onSelect ?? noop}
                  closeSubMenu={() => setOpenSubmenuId(null)}
                  closeMenu={closeMenu}
                />
              </Box>
            ) : null}
          </Box>
        </Tooltip>

      );
    }

    if (item.type === ListItemType.divider) {
      return (
        <Box my={300} key={item.id}>
          <Divider />
        </Box>
      );
    }

    return (
      <Box my={300} key={item.id}>
        <InView onChange={(visible) => {
          if (fetchMore !== undefined && visible) {
            void fetchMore();
          }
        }}
        >
          <DropdownItemSkeleton multiselect={multiselect} />
          <DropdownItemSkeleton multiselect={multiselect} />
          <DropdownItemSkeleton multiselect={multiselect} />
        </InView>
      </Box>
    );
  };

  return (
    <Box position="relative">
      {render({
        isOpen,
        closeMenu,
        openMenu,
        labelProps: getLabelProps,
        itemProps: getItemProps,
        removeItem: onRemove ?? noop,
        selectedItemProps: getSelectedItemProps,
        buttonProps: (customProps) => getToggleButtonProps(getDropdownProps({
          ...customProps,
          preventKeyAction: isOpen,
          disabled,
          onKeyDown: (e: KeyboardEvent) => {
            if (e.code !== 'Space' && e.code !== 'Enter') {
              return;
            }

            if (isOpen) {
              closeMenu();
            } else {
              openMenu();
            }
          },
        }) as UseSelectGetToggleButtonPropsOptions) as ToggleButtonProps,
        dropdown: (
          <Hidden visible={isOpen}>
            <DropdownContainer virtualize={virtualize} dropdownWidth={dropdownWidth}>
              {title}
              <VirtuosoListContainer {...getMenuProps()}>
                <Hidden visible={!loading}>
                  {virtualize ? (
                    <Virtuoso
                      ref={virtuoso}
                      style={{ listStyleType: 'none' }}
                      components={{
                        Scroller: ScrollerComponent,
                        Item: ListItemComponent,
                        List: ListComponent,
                      }}
                      data={loading ? [] : flatItems}
                      itemContent={(_, item: typeof flatItems[number]) => renderItem(item)}
                    />
                  ) : (
                    <Box py={300} component={ListComponent}>
                      {flatItems.map(renderItem)}
                    </Box>
                  )}
                </Hidden>
                {loading && (
                  <>
                    <DropdownItemSkeleton multiselect={multiselect} />
                    <DropdownItemSkeleton multiselect={multiselect} />
                    <DropdownItemSkeleton multiselect={multiselect} />
                  </>
                )}
              </VirtuosoListContainer>
            </DropdownContainer>
          </Hidden>
        ),
      })}
    </Box>
  );
};

export default Dropdown;
