import { lazy } from 'react';

import type { RoutesConfig, WrapRoutes } from '@virtuslab/nfs-shared/src/services/routes';

import { DirectReportingRoutes } from '../../../config/paths';

const DirectReportingPage = lazy(async () => import('.'));

const config: RoutesConfig<WrapRoutes<typeof DirectReportingRoutes>> = {
  paths: {
    [DirectReportingRoutes.DIRECT_REPORTING]: {
      render: DirectReportingPage,
    },
  },
};

export default config;
