import type { ReactElement } from 'react';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

type Props = Readonly<{
  multiselect: boolean;
}>;
const DropdownItemSkeleton = ({ multiselect }: Props): ReactElement => (
  <Box ml={multiselect ? 700 : 500} py={300} alignItems="center" display="flex" position="relative">
    {multiselect ? (<Box width="10%" />) : null}
    <Box width="100%">
      <Skeleton variant="text" height={14} width="80%" />
    </Box>
  </Box>
);

export default DropdownItemSkeleton;
