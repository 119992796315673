import {
  SquaresFour, Alarm, Suitcase, Gift,
  TreeStructure,
} from 'phosphor-react';

import type { SelectedContract } from '@virtuslab/nfs-shared/src/components/organisms/SelectedContractProvider';
import type { NavGroup, NavItem } from '@virtuslab/nfs-shared/src/config/navigation';
import { B2BContractType } from '@virtuslab/nfs-shared/src/schema/admin';
import type { Nullable } from '@virtuslab/nfs-shared/src/services/object';
import { generatePath } from '@virtuslab/nfs-shared/src/services/routes';

import type { TranslatingFn } from '../services/i18n';

import type { contractParamName } from './component';
import {
  BenefitsRoutes,
  BusinessTravelsRoutes,
  DashboardRoutes,
  DirectReportingRoutes,
  LogoutRoutes,
  SettingsRoutes,
  TimeReportRoutes,
} from './paths';

type SharedParams = Record<typeof contractParamName, string>;

const navigation = (
  t: TranslatingFn,
  sharedParams: SharedParams,
  selectedContractType: Nullable<SelectedContract['type']>,
): NavGroup[] => [
  {
    id: 'user',
    items: [
      {
        id: 'dashboard',
        title: t('Dashboard'),
        icon: <SquaresFour />,
        url: generatePath(DashboardRoutes.DASHBOARD, sharedParams),
        disabled: false,
      },
    ],
  },
  {
    id: 'employee',
    title: 'Employee',
    items: [
      {
        id: 'timeReport',
        title: t('Time report'),
        icon: <Alarm />,
        url: generatePath(TimeReportRoutes.TIME_REPORT, sharedParams),
        associatedRoutes: Object.values(TimeReportRoutes),
        canBeAccessed: true,
      },
      {
        id: 'benefits',
        title: selectedContractType === B2BContractType.B2B ? t('Luminis products') : t('Benefits'),
        icon: <Gift />,
        url: generatePath(BenefitsRoutes.BENEFITS, sharedParams),
        canBeAccessed: true,
      },
      {
        id: 'businessTravels',
        title: t('Business travels'),
        icon: <Suitcase />,
        url: generatePath(BusinessTravelsRoutes.BUSINESS_TRAVELS, sharedParams),
        disabled: true,
        canBeAccessed: false,
      },
      {
        id: 'directReporting',
        title: t('Direct reporting'),
        icon: <TreeStructure />,
        url: generatePath(DirectReportingRoutes.DIRECT_REPORTING, sharedParams),
        canBeAccessed: true,
      },
    ],
  },
];

export const profileNavigation = (t: TranslatingFn, sharedParams: SharedParams): NavItem[] => [
  {
    id: 'settings',
    title: t('Account settings'),
    url: generatePath(SettingsRoutes.SETTINGS, sharedParams),
    disabled: true,
  },
  {
    id: 'logout',
    title: t('Logout'),
    url: LogoutRoutes.LOGOUT,
  },
];

export default navigation;
