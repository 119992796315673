import type { RoutesToRoutesParams } from '@virtuslab/nfs-shared/src/services/routes';
import { separateOptionalPaths } from '@virtuslab/nfs-shared/src/services/routes';

import { contractParamName } from './component';

export const BaseRoutes = separateOptionalPaths({
  BASE: '/',
  // if modifying this, also check if contract-switching-links are generated properly (UserProfileLink/index.tsx)
  CONTRACT_ROUTE: `/c/:${contractParamName}` as const,
} as const);
export type BaseRoutesProps = RoutesToRoutesParams<typeof BaseRoutes>;

export const LoginRoutes = separateOptionalPaths({
  LOGIN: '/auth/login',
} as const);
export type LoginRoutesProps = RoutesToRoutesParams<typeof LoginRoutes>;

export const LogoutRoutes = separateOptionalPaths({
  LOGOUT: '/auth/logout',
} as const);
export type LogoutRoutesProps = RoutesToRoutesParams<typeof LogoutRoutes>;

export const BusinessTravelsRoutes = separateOptionalPaths({
  BUSINESS_TRAVELS: `${BaseRoutes.CONTRACT_ROUTE}/business-travels`,
} as const);
export type BusinessTravelsRoutesProps = RoutesToRoutesParams<typeof BusinessTravelsRoutes>;

export const DashboardRoutes = separateOptionalPaths({
  DASHBOARD: `${BaseRoutes.CONTRACT_ROUTE}/dashboard`,
} as const);
export type DashboardRoutesProps = RoutesToRoutesParams<typeof DashboardRoutes>;

export const ErrorsRoutes = separateOptionalPaths({
  NOT_FOUND: '/404',
} as const);
export type ErrorsRoutesProps = RoutesToRoutesParams<typeof ErrorsRoutes>;

export const BenefitsRoutes = separateOptionalPaths({
  BENEFITS: `${BaseRoutes.CONTRACT_ROUTE}/benefits`,
} as const);
export type BenefitsRoutesProps = RoutesToRoutesParams<typeof BenefitsRoutes>;

export const SettingsRoutes = separateOptionalPaths({
  SETTINGS: `${BaseRoutes.CONTRACT_ROUTE}/settings`,
} as const);
export type SettingsRoutesProps = RoutesToRoutesParams<typeof SettingsRoutes>;

export const DirectReportingRoutes = separateOptionalPaths({
  DIRECT_REPORTING: `${BaseRoutes.CONTRACT_ROUTE}/direct-reporting`,
} as const);
export type DirectReportingRoutesProps = RoutesToRoutesParams<typeof DirectReportingRoutes>;

export const TimeReportRoutes = separateOptionalPaths({
  TIME_REPORT: `${BaseRoutes.CONTRACT_ROUTE}/time-report`,
  TIME_REPORT_MONTHLY: `${BaseRoutes.CONTRACT_ROUTE}/time-report/monthly/:year/:month`,
  TIME_REPORT_MONTHLY_WITH_DAY: `${BaseRoutes.CONTRACT_ROUTE}/time-report/monthly/:year/:month/:day`,
  TIME_REPORT_MONTHLY_WITH_ENTRY: `${BaseRoutes.CONTRACT_ROUTE}/time-report/monthly/:year/:month/:day/:entryId`,
} as const);
export type TimeReportRoutesProps = RoutesToRoutesParams<typeof TimeReportRoutes>;

export const NotFoundRoutes = separateOptionalPaths({
  NOT_FOUND: '/404',
  FALLBACK: '*',
});
export type NotFoundRoutesProps = RoutesToRoutesParams<typeof NotFoundRoutes>;
