import type { ReactiveVar } from '@apollo/client';

import type { DrawerQueryVariables } from '@virtuslab/nfs-shared/src/schema/user';
import type { StrictTypedTypePolicies } from '@virtuslab/nfs-shared/src/schema/user/clientHelpers';
import {
  typesafePolicies, isVariables, sharedPolicies, idAtKeyFields,
} from '@virtuslab/nfs-shared/src/state';

import type { FlatDrawers, Drawer } from './models/Drawers';
import * as queries from './operations/queries';
import typeDefs from './schema';

export type ReactiveVars = Readonly<{
  drawersVar: ReactiveVar<FlatDrawers>;
}>;
export const typePolicies = (
  { drawersVar }: ReactiveVars,
): StrictTypedTypePolicies => typesafePolicies<StrictTypedTypePolicies>()({
  ...sharedPolicies,
  Mutation: {
    keyFields: [],
  },
  Query: {
    keyFields: [],
    fields: {
      drawer: {
        read(_, { variables }): Drawer | null {
          const drawers = drawersVar();

          if (isVariables<DrawerQueryVariables>(variables, 'id')) {
            return queries.drawer(drawers, variables);
          }

          return null;
        },
      },
      rootDrawer: {
        read: () => queries.rootDrawer(drawersVar()),
      },
      topDrawer: {
        read: () => queries.topDrawer(drawersVar()),
      },
    },
  },
  Contract: {
    keyFields: idAtKeyFields,
  },
  TimeReport: {
    keyFields: false,
    merge: true,
  },
  EmploymentTimeReportStats: {
    keyFields: false,
    merge: true,
  },
  B2BTimeReportStats: {
    keyFields: false,
    merge: true,
  },
  B2BContractSalaryDetails: {
    keyFields: ['contractId', 'yearMonth'],
    merge: true,
  },
  EmploymentContractSalaryDetails: {
    keyFields: ['contractId', 'yearMonth'],
    merge: true,
  },
  TimeBasedOptClearableOnCallRate: {
    keyFields: false,
    merge: true,
  },
  TimeBasedOptClearableNetGross: {
    keyFields: false,
    merge: true,
  },
  TimeBasedRate: {
    keyFields: false,
    merge: true,
  },
});

export { typeDefs };
