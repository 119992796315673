import type {
  ComponentType, ComponentProps, ReactNode, FunctionComponent, ForwardedRef,
} from 'react';
import { memo } from 'react';

import { Virtuoso } from 'react-virtuoso';
import type { ListRange, Components, VirtuosoHandle } from 'react-virtuoso';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

export enum ListItemType {
  empty = 'empty',
  item = 'item',
  divider = 'divider',
  header = 'header',
  skeleton = 'skeleton',
}

export type ListItem<ItemValue, ExtraFields = Record<string, unknown>> =
  ExtraFields & { type: ListItemType.divider; data?: never }
  | ExtraFields & { type: ListItemType.empty; data?: never }
  | ExtraFields & { type: ListItemType.header; data: ReactNode }
  | ExtraFields & { type: ListItemType.item; data: ItemValue }
  | ExtraFields & { type: ListItemType.skeleton; data?: never };

export type ListItemProps<ItemValue = unknown, AdditionalProps = unknown> = Readonly<{
  item: ItemValue;
  index: number;
  additionalProps: AdditionalProps;
}>;

type ListSkeletonProps<AdditionalSkeletonProps = unknown> = Readonly<{
  additionalProps?: AdditionalSkeletonProps;
  itemIndex?: number;
}>;

export interface Props<ItemValue = unknown, AdditionalProps = unknown, AdditionalSkeletonProps = unknown> {
  items: ListItem<ItemValue>[];
  Item: FunctionComponent<ListItemProps<ItemValue, AdditionalProps>>;
  Skeleton: FunctionComponent<ListSkeletonProps<AdditionalSkeletonProps>>;
  GroupHeader: FunctionComponent<{ item: ReactNode }>;
  onRangeChange?: (range: ListRange, total: number) => void;
  components?: Components<ListItem<ItemValue>>;
  virtuosoRef?: ForwardedRef<VirtuosoHandle>;
  additionalItemProps: AdditionalProps;
  additionalSkeletonProps?: AdditionalSkeletonProps;
}

const typedMemo: <T extends ComponentType<Props>>(
  c: T,
  areEqual?: (
    prev: ComponentProps<T>,
    next: ComponentProps<T>
  ) => boolean
) => T = memo;

const VirtualizedList = typedMemo(<
  ItemValue extends unknown, AdditionalProps extends unknown, AdditionalSkeletonProps extends unknown,
  >({
    items,
    onRangeChange,
    Skeleton,
    Item,
    GroupHeader,
    components,
    virtuosoRef,
    additionalItemProps,
    additionalSkeletonProps,
  }: Props<ItemValue, AdditionalProps, AdditionalSkeletonProps>) => (
    <Virtuoso<ListItem<ItemValue>>
      tabIndex={-1}
      itemProp={items.length.toString()}
      ref={virtuosoRef}
      style={{ overflowX: 'hidden', listStyleType: 'none' }}
      components={components}
      rangeChanged={(range) => { onRangeChange?.(range, items.length); }}
      data={items}
      itemContent={(index, data) => {
        if (data.type === ListItemType.empty) {
          return <Box width="100%" py={700} />;
        }

        if (data.type === ListItemType.divider) {
          return (
            <Box width="100%" px={500} py={500}>
              <Divider />
            </Box>
          );
        }

        if (data.type === ListItemType.header) {
          return <GroupHeader item={data.data} />;
        }

        if (data.type === ListItemType.item) {
          return (
            <Item additionalProps={additionalItemProps} item={data.data} index={index} />
          );
        }

        return <Skeleton additionalProps={additionalSkeletonProps} />;
      }}
    />
  ));

export default VirtualizedList;
