import type { ForwardedRef, ReactElement } from 'react';
import { forwardRef } from 'react';

import shouldForwardProp from '@styled-system/should-forward-prop';
import isNil from 'lodash/isNil';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

import { Box } from '@mui/material';
import type { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import MuiButton from '@mui/material/Button';

import { isEmpty } from '../../../services/checks';
import type { InteractivityState } from '../../../services/form';
import { interactivityStateToReason, interactivityStateToBoolean } from '../../../services/form';

import ActionText from '../ActionText';
import type { variants, Props as ActionTextProps } from '../ActionText';
import Body from '../Body';
import Tooltip from '../Tooltip';

type ButtonProps = Readonly<{
  textVariant: Props['variant'];
  inverted: boolean;
  disabled: boolean;
}>;

const buttonCursor = ({ disabled }: ButtonProps) => css`
  cursor: ${disabled ? 'not-allowed' : null};
`;

const hoverStyle = ({ disabled, textVariant }: ButtonProps) => {
  if (disabled) {
    return null;
  }

  if (textVariant === 'ghost') {
    return css`
      &:hover {
        text-decoration: underline;
      }
    `;
  }

  return null;
};

const buttonShadow = (props: ButtonProps) => {
  if (props.inverted) {
    return css`
      box-shadow: ${({ theme }) => theme.scTheme.shadows.focus.inverted.full};
    `;
  }

  if (props.textVariant === 'destructive') {
    return css`
      box-shadow: ${({ theme }) => theme.scTheme.shadows.focus.alert.full};
    `;
  }

  return css`
    box-shadow: ${({ theme }) => theme.scTheme.shadows.focus.primary.full};
  `;
};

const Button = styled(MuiButton).withConfig({
  shouldForwardProp: (prop) => shouldForwardProp(prop),
})<ButtonProps>`
  && {
    min-width: unset;
    padding: ${({ theme }) => theme.scTheme.space[0]};

    &:hover,
    &:active {
      background-color: unset;
    }

    ${hoverStyle}
    ${buttonCursor}
    &:focus-visible {
      ${buttonShadow}
    }

    &.MuiButtonBase-root.Mui-disabled {
      pointer-events: all;
    }
  }
`;

export type Props = Omit<MuiButtonProps, 'disabled' | 'size' | 'variant'> & Readonly<{
  variant?: keyof typeof variants;
  inverted?: boolean;
  disabled?: InteractivityState;
  size?: ActionTextProps['size'];
  link?: Readonly<{
    to: string;
  }>;
}>;

const TextButton = forwardRef(({
  children,
  variant = 'primary',
  disabled,
  inverted = false,
  size,
  link,
  ...buttonProps
}: Props, ref: ForwardedRef<HTMLButtonElement>): ReactElement => (
  <Tooltip
    enabled={!isEmpty(interactivityStateToReason(disabled))}
    tooltipProps={{
      placement: 'top',
      title: <Body size={200} inverted>{interactivityStateToReason(disabled)}</Body>,
    }}
  >
    <Box>
      <Button
        {...buttonProps}
        ref={ref}
        color="primary"
        size="small"
        inverted={inverted}
        textVariant={variant}
        variant="text"
        disabled={interactivityStateToBoolean(disabled)}
      >
        <ActionText
          as={isNil(link) ? undefined : Link}
          to={link?.to ?? ''}
          size={size}
          tabIndex={-1}
          disabled={interactivityStateToBoolean(disabled)}
          variant={variant}
          inverted={inverted}
        >
          {children}
        </ActionText>
      </Button>
    </Box>
  </Tooltip>

));

export default TextButton;
