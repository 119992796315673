import type { ReactNode, ReactElement } from 'react';
import {
  useMemo,
  createContext, useEffect, useState, useContext,
} from 'react';

import isNil from 'lodash/isNil';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { AdapterLuxon as DateAdapter } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import type { FormattingService } from '../../../services/format';
import type { LocaleInfo } from '../../../services/i18n';
import { localesInfo } from '../../../services/i18n';
import type { Language } from '../../../services/i18n/changeLanguage';
import type { Nullable } from '../../../services/object';

import { useFormatting } from '../FormattingProvider';

const toJSDayIndex = (date: DateTime) => date.weekday % 7;

const isLocaleLanguage = (
  locale: Nullable<string>,
): locale is Language => !isNil(locale) && Object.keys(localesInfo).includes(locale);

type ConstructorArg = ConstructorParameters<typeof DateAdapter>[0];

const makeCustomDateAdapter = (format: FormattingService) => {
  class CustomDateAdapter extends DateAdapter {
    private readonly localeInfo: LocaleInfo;

    public constructor(arg: ConstructorArg) {
      super(arg);

      const { locale } = arg ?? {};
      this.localeInfo = isLocaleLanguage(locale) ? localesInfo[locale] : localesInfo['en-GB'];
    }

    public getWeekArray = (date: DateTime) => {
      const monthStart = date.startOf('month');
      const padding = (7 + toJSDayIndex(monthStart) - this.localeInfo.firstDayOfWeek) % 7;

      const weeks: DateTime[][] = Array.from({ length: 6 },
        (_, weekIndex) => Array.from({ length: 7 },
          (__, dayInWeekIndex) => monthStart.plus({ days: dayInWeekIndex + (weekIndex * 7) - padding })));

      return weeks;
    };

    public getWeekdays = () => {
      const weekStart = DateTime.local().set({ weekday: this.localeInfo.firstDayOfWeek });
      const weekdays = Array.from({ length: 7 }, (_, i) => format.weekday(weekStart.plus({ day: i }).toJSDate(), 'narrow'));

      return weekdays;
    };
  }

  return CustomDateAdapter;
};

type Props = Readonly<{
  children: ReactNode;
}>;

export const context = createContext<LocaleInfo>(localesInfo['en-GB']);

const LocaleInfoProvider = ({ children }: Props): ReactElement => {
  const { i18n } = useTranslation();
  const format = useFormatting();

  const [localeInfo, updateLocaleInfo] = useState(localesInfo[i18n.language]);

  const CustomDateAdapter = useMemo(() => makeCustomDateAdapter(format), [format]);

  useEffect(() => {
    const onLanguageChanged = (language: Language) => updateLocaleInfo(localesInfo[language]);

    i18n.on('languageChanged', onLanguageChanged);
    return () => i18n.off('languageChanged', onLanguageChanged);
  }, [i18n]);

  return (
    <context.Provider value={localeInfo}>
      <LocalizationProvider adapterLocale={i18n.language} dateAdapter={CustomDateAdapter}>
        {children}
      </LocalizationProvider>
    </context.Provider>
  );
};

export const useLocaleInfo = (): LocaleInfo => useContext(context);
export default LocaleInfoProvider;
