import styled, { css } from 'styled-components/macro';

import type { Size } from '../../../theme/icons';

type Props = Readonly<{
  size: Size;
}>;

const boxSize = (props: Props) => css`
  height: ${({ theme }) => theme.scTheme.icons.sizes[props.size].box};
  width: ${({ theme }) => theme.scTheme.icons.sizes[props.size].box};
`;

const IconBox = styled.div<Props>`
  align-items: center;
  display: inline-flex;
  justify-content: center;
  ${boxSize};
`;

export default IconBox;
