import type { ReactElement, ReactNode } from 'react';
import { createContext, useContext, useMemo } from 'react';

import type { FeatureFlags } from '../../../config/featureFlags';

type ContextValue = FeatureFlags;

const context = createContext<ContextValue>({
  allowReportingBreak: false,
  allowMaternityLeave: false,
  ignoreWorkTagLimits: false,
  allowChangingInvoiceStatus: false,
  enforceInvoiceValidation: true,
  useACL: false,
  clearCachePeriodically: false,
  ignoreLeaveLimits: false,
});

type Props = Readonly<{
  featureFlags: FeatureFlags;
  children: ReactNode;
}> ;

const FeatureFlagsProvider = ({
  featureFlags, children,
}: Props): ReactElement => {
  const contextValue = useMemo<ContextValue>(() => ({
    ...featureFlags,
  }), [featureFlags]);

  return (
    <context.Provider value={contextValue}>
      {children}
    </context.Provider>
  );
};

export const useFeatureFlagsContext = (): ContextValue => useContext(context);

export default FeatureFlagsProvider;
