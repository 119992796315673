import type { ConditionalPick } from 'type-fest';

import type { AbsenceTypeInput, B2BContractType } from '@virtuslab/nfs-shared/src/schema/admin';
import type {
  CalendarDayType,
  ContractStatus,
  EmploymentContractType,
  Settlement,
  AbsenceType,
  OnCallMultiplier,
} from '@virtuslab/nfs-shared/src/schema/user';
import type { InterpolatedVariables, PluralBuildingBlocksToMessages, SharedEnumMessages } from '@virtuslab/nfs-shared/src/services/i18n/messages';
import {
  sharedPluralBuildingBlocks,
  makePluralMessages, sharedPluralMessages,
  sharedMessages,
} from '@virtuslab/nfs-shared/src/services/i18n/messages';

export interface EnumMessages extends SharedEnumMessages {
  contractStatus: ContractStatus;
  employmentContractType: EmploymentContractType;
  settlement: Settlement;
  b2bContractType: B2BContractType;
  calendarDayType: CalendarDayType;
  absenceType: AbsenceType;
  absenceTypeInput: AbsenceTypeInput;
  onCallMultiplier: OnCallMultiplier;
}

export const enumMessage = <T extends keyof EnumMessages, U extends EnumMessages[T]>(
  enumName: T,
  value: U,
): `${T}-${U}` => `${enumName}-${value}` as const;

export type Messages = {
  [key in keyof EnumMessages as `${key}-${EnumMessages[key]}`]: undefined
} & {
  [key in PluralBuildingBlocksToMessages<typeof pluralBuildingBlocks>]: InterpolatedVariables<key>
} & {
  [key in PluralMessages]: InterpolatedVariables<key>
} & {
  [key in typeof messages[number]]: InterpolatedVariables<key>
};

export type MessageKey = keyof Messages;
export type PlainMessageKey = keyof ConditionalPick<Messages, undefined>;
export type PluralMessages = keyof (typeof sharedPluralMessages);

export const pluralMessages = {
  ...sharedPluralMessages,
  ...makePluralMessages({}),
};

export const pluralBuildingBlocks = {
  ...sharedPluralBuildingBlocks,
  ...makePluralMessages({}),
};

const messages = [
  ...sharedMessages,
  'Dashboard',
  'Time report',
  'Finances',
  'Business travels',
  'Account settings',
  'Logout',
  'New entry',
  'Month',
  'Year',
  'Close',
  'Work',
  'Leave',
  'On-call',
  'Activity',
  'Select project',
  'Add the same entry for the next days',
  'Break',
  'Total',
  'Time',
  '{{thing}} (optional)',
  'Additional options',
  'Add',
  'Comment',
  'Enter comment',
  'Mark as overtime',
  'Report overtime only with the consent of your manager',
  'Go to today',
  'Today',
  'No time set',
  'Overtime',
  'From',
  'To',
  'Show / hide additional options',
  'Edit entry - {{entryType}}',
  'Save',
  'Entry modal',
  'Leave type',
  'Start date',
  'End date',
  'Absence type',
  'days',
  'Repeat until',
  'Omit weekends and public holidays',
  'Enter number',
  'No access to {{thing}}',
  'You have no signed contract in the system. Contact your Space Owner',
  'Switch contracts',
  'from {{date}}',
  'No contract',
  'Time report preferences',
  'Drawer with a form for editing time report preferences',
  'Default values in a new entry',
  'Selected values will be prefilled each time you add a new entry',
  'If a project is not available on the selected date, you will have to pick it manually',
  'Invoice number',
  'The page contains outdated data',
  'Reload',
  '{{entityName}} already exists',
  '{{entityName}} doesn\'t exists',
  'Some fields are read-only',
  'Invalid currency',
  'You do not have sufficient permissions',
  'Some fields are invalid ({{fields}})',
  'At least one field must be filled',
  'You have no benefits to show',
  'No Luminis products to show',
  'Partially refunded',
  'Anything unclear about the Time Report or do you have any questions?',
  'Go to Time Report Guide',
  'No operational lead assigned. Please contact your Space Owner.',
] as const;

export default messages;
