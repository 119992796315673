import type { ReactElement } from 'react';
import { useMemo } from 'react';

import isNil from 'lodash/isNil';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { Box } from '@mui/material';
import { CaretRight } from 'phosphor-react';

import FloatingContainer from '@virtuslab/nfs-shared/src/components/atoms/FloatingContainer';
import NavButtonBase from '@virtuslab/nfs-shared/src/components/atoms/NavButtonBase';
import Body from '@virtuslab/nfs-shared/src/components/molecules/Body';
import type { ValueItem } from '@virtuslab/nfs-shared/src/components/molecules/Dropdown';
import Dropdown, { idValuePair } from '@virtuslab/nfs-shared/src/components/molecules/Dropdown';
import Icon from '@virtuslab/nfs-shared/src/components/molecules/Icon';
import NavButton from '@virtuslab/nfs-shared/src/components/molecules/NavButton';
import ProfileLink from '@virtuslab/nfs-shared/src/components/molecules/ProfileLink';
import TextGroup from '@virtuslab/nfs-shared/src/components/molecules/TextGroup';
import { useFormatting } from '@virtuslab/nfs-shared/src/components/organisms/FormattingProvider';
import type { NavItem } from '@virtuslab/nfs-shared/src/config/navigation';
import { B2BContractType } from '@virtuslab/nfs-shared/src/schema/admin';
import { B2BContractTypename, EmploymentContractTypename, isInstanceOf } from '@virtuslab/nfs-shared/src/schema/user';
import { insertAtIndex } from '@virtuslab/nfs-shared/src/services/array';
import { isEmpty } from '@virtuslab/nfs-shared/src/services/checks';
import type { FormattingService } from '@virtuslab/nfs-shared/src/services/format';
import { DateFormat } from '@virtuslab/nfs-shared/src/services/format';
import { generatePath } from '@virtuslab/nfs-shared/src/services/routes';

import { BaseRoutes } from '../../../config/paths';
import type { Contract } from '../../../services/hooks/useContract';
import useContract from '../../../services/hooks/useContract';
import type { TranslatingFn } from '../../../services/i18n';
import { enumMessage } from '../../../services/i18n';

const formatContractDuration = (t: TranslatingFn, format: FormattingService, contract: Contract) => {
  const { startDate, expectedEndDate, terminationDate } = contract;

  const rangeEnd = terminationDate ?? expectedEndDate;

  if (isNil(rangeEnd)) {
    return `${t('from {{date}}', { date: format.date(startDate, DateFormat.yearMonthDay) })}`;
  }

  return `${format.dateRange({ from: startDate, to: rangeEnd }, DateFormat.yearMonthDay)}`;
};

const changeContractLink = (currentPathname: string, currentContractIndex: number | null, newContractIndex: number) => {
  if (isNil(currentContractIndex)) {
    return generatePath(BaseRoutes.CONTRACT_ROUTE, {
      contractIndex: newContractIndex,
    });
  }

  return currentPathname.replace(generatePath(BaseRoutes.CONTRACT_ROUTE, {
    contractIndex: currentContractIndex,
  }), generatePath(BaseRoutes.CONTRACT_ROUTE, {
    contractIndex: newContractIndex,
  }));
};

type Props = Readonly<{
  navigation: NavItem[];
}>;

const UserProfileLink = ({ navigation }: Props): ReactElement => {
  const { t } = useTranslation();
  const format = useFormatting();

  const { contracts, selectedContract, selectedContractIndex } = useContract();
  const { pathname } = useLocation();

  const items = useMemo<ValueItem<Contract['id']>[]>(() => contracts.map((contract, index) => {
    const translatedContractType = isInstanceOf(contract, EmploymentContractTypename)
      ? t(enumMessage('employmentContractType', contract.type))
      : t(enumMessage('b2bContractType', isInstanceOf(contract, B2BContractTypename) ? B2BContractType.B2B : B2BContractType.SUBCONTRACT));

    return {
      ...idValuePair(contract.id),
      label: `${translatedContractType} (${formatContractDuration(t, format, contract)})`,
      renderLink: (label) => (
        <Link to={changeContractLink(pathname, selectedContractIndex, index)}>
          {label}
        </Link>
      ),
      renderLabel: (label) => (
        <TextGroup gap={200}>
          {label}
          <Body variant="secondary" size={200}>
            {contract.promisor.name}
          </Body>
        </TextGroup>
      ),
    };
  }), [contracts, format, t, pathname, selectedContractIndex]);

  const links = navigation.map((item) => (
    <NavButton item={item} key={item.id} />
  ));

  const selectedOption = items.find((item) => item.id === selectedContract?.id);

  const withContractSelection = insertAtIndex(
    (
      <Box key="contract-selection" position="relative" width="100%">
        <Dropdown
          showSelection
          value={selectedOption ? [selectedOption] : []}
          items={items}
          render={({ buttonProps, dropdown }) => (
            <>
              <NavButtonBase {...buttonProps()}>
                <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
                  <Body size={300}>
                    {t('Switch contracts')}
                  </Body>
                  <Icon size="xs">
                    <CaretRight />
                  </Icon>
                </Box>
              </NavButtonBase>
              <FloatingContainer x="right" y="bottom" xAlignment="outside" yAlignment="inside">
                {dropdown}
              </FloatingContainer>
            </>
          )}
        />
      </Box>
    ), 1,
    links,
  );

  return (
    <ProfileLink
      caption={selectedOption?.label ?? t('No contract')}
      navigation={isEmpty(contracts) || contracts.length === 1
        ? links : withContractSelection}
    />
  );
};

export default UserProfileLink;
