import first from 'lodash/first';
import tail from 'lodash/tail';

import type { FlatDrawers, Drawer } from '../../../models/Drawers';

export const toTree = (nodes: FlatDrawers): Drawer | null => {
  const currentNode = first(nodes) ?? null;

  if (currentNode === null) {
    return null;
  }

  return {
    ...currentNode,
    child: toTree(tail(nodes)),
  };
};

export default {};
