const checkbox = {
  stroke: '3rem',
} as const;

const list = {
  dot: '.4rem',
} as const;

const images = {
  mountains: {
    sm: '4.8rem',
    md: '16.8rem',
    lg: '26.4rem',
  },
} as const;

const size = {
  checkbox,
  list,
  images,
} as const;

export type Size = typeof size;

export default size;
