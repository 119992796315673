import animations from './animations';
import borders from './borders';
import heights from './heights';
import icons from './icons';
import palette from './palette';
import { breakpoints, mediaQueries } from './responsivness';
import shadows from './shadows';
import sizes from './sizes';
import space from './space';
import {
  typefaces, sizes as fontSizes, weights, lineHeights,
} from './typography';
import widths from './widths';
import zindex from './zIndex';

const theme = {
  palette,
  fontFamilies: typefaces,
  fontSizes,
  fontWeights: weights,
  fontLineHeights: lineHeights,
  heights,
  space,
  icons,
  borders,
  shadows,
  animations,
  breakpoints,
  mediaQueries,
  widths,
  sizes,
  zindex,
} as const;

export type Theme = typeof theme;

export default theme;
