import isNil from 'lodash/isNil';
import styled, { css } from 'styled-components/macro';

type WidthProps = Readonly<{ virtualize: boolean; dropdownWidth?: string }>;

const width = ({ virtualize, dropdownWidth }: WidthProps) => {
  if (virtualize) {
    return css`
      overflow: hidden;
      width: ${({ theme }) => theme.scTheme.widths.max.virtualizedList};
    `;
  }

  if (!isNil(dropdownWidth)) {
    return css`
      max-width: ${dropdownWidth};
    `;
  }

  return css`
      overflow: visible;
      width: 100%;
    `;
};

const DropdownContainer = styled.div`
  background-color: ${({ theme }) => theme.scTheme.palette.inverted.white};
  border-radius: ${({ theme }) => theme.scTheme.borders.radii.md};
  box-shadow: ${({ theme }) => theme.scTheme.shadows.largeShadow};
  margin-top: ${({ theme }) => theme.scTheme.space[300]};
  max-height: ${({ theme }) => theme.scTheme.heights.max.dropdown};
  min-width: ${({ theme }) => theme.scTheme.widths.min.dropdown};
  ${width};
  z-index: ${({ theme }) => theme.scTheme.zindex.floating};

  & li[data-index='0'] {
    padding-top: ${({ theme }) => theme.scTheme.space[300]};
  }

  & li:last-of-type {
    padding-bottom: ${({ theme }) => theme.scTheme.space[300]};
  }
`;

export default DropdownContainer;
