import { useEffect } from 'react';

import type { BrowserOptions } from '@sentry/browser';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';

import type { Config } from './config';

enum EventCategories {
  console = 'console',
}

const ignoredBreadcrumbs = [
  { category: EventCategories.console, pattern: /in strict ?mode/gi },
  { category: EventCategories.console, pattern: /GraphQL Network Error.*undefined.*validation.*error/i },
  { category: EventCategories.console, pattern: /UnhandledRejection.*Non-Error.*undefined/i },
];

export const appConfigToSentryConfig = (config: Config): BrowserOptions => ({
  enabled: !config.isLocalDevelopment && config.sentry.enabled,
  dsn: config.sentry.url,
  environment: config.env,
  release: config.release,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Sentry.Replay({
      maskAllText: true,
      blockAllMedia: true,
    })],
  tracesSampleRate: 1.0,
  attachStacktrace: true,
  beforeBreadcrumb: (breadcrumb) => {
    const shouldIgnore = ignoredBreadcrumbs
      .some(({ category, pattern }) => category === breadcrumb.category
          && breadcrumb.message !== undefined
          && pattern.test(breadcrumb.message));

    if (shouldIgnore) {
      return null;
    }

    return breadcrumb;
  },
});

export default {};
