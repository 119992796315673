import type { ReactElement, ReactNode } from 'react';
import {
  useRef, useEffect, useState, createContext,
} from 'react';

import * as Sentry from '@sentry/react';
import noop from 'lodash/noop';

import type { Literal } from '../../../services/guards';

type UsersnapInitParams = Partial<Readonly<{
  nativeScreenshot: Readonly<{
    target: string;
  }> | boolean;
  user: Partial<Readonly<{
    userId: string;
    email: string;
  }>>;
  custom: Literal;
  collectGeoLocation: 'all' | 'none' | null;
  enableScreenshot: boolean;
  useLocalStorage: boolean;
  useSystemFonts: boolean;
  locale: string;
}>>;

type UsersnapEvent = 'open';

type UsersnapApi = Readonly<{
  init: (arg: UsersnapInitParams) => void;
  destroy: () => void;
  setValue: <T extends keyof UsersnapInitParams>(field: T, value: UsersnapInitParams[T]) => unknown;
  on: (event: UsersnapEvent, callback: (event: Record<'api', UsersnapApi>) => void) => void;
}>;

type ContextValue = UsersnapApi | null;
export const context = createContext<ContextValue>(null);

const onLoadCallbackName = 'onUsersnapCXLoad';

type Props = Readonly<{
  initParams?: UsersnapInitParams;
  children: ReactNode;
}>;

const UsersnapProvider = ({ initParams = {}, children }: Props): ReactElement => {
  const [usersnapApi, setUsersnapApi] = useState<ContextValue>(null);
  const paramsRef = useRef(initParams);

  useEffect(() => () => {
    if (usersnapApi) {
      usersnapApi.destroy();
    }
  }, [usersnapApi]);

  useEffect(() => {
    if (onLoadCallbackName in window) {
      return noop;
    }

    Object.defineProperty(window, onLoadCallbackName, {
      value: (api: UsersnapApi) => {
        api.init(paramsRef.current);
        setUsersnapApi(api);

        api.on('open', (event) => {
          event.api.setValue('custom', {
            ...paramsRef.current.custom,
            lastSentryTransaction: Sentry.lastEventId(),
          });
        });
      },
    });

    const script = document.createElement('script');
    script.defer = true;
    script.src = `https://widget.usersnap.com/global/load/1ab33c71-0489-45fc-8fe2-1fea4db82581?onload=${onLoadCallbackName}`;
    document.head.appendChild(script);

    return () => script.remove();
  }, []);

  return (
    <context.Provider value={usersnapApi}>
      {children}
    </context.Provider>
  );
};

export default UsersnapProvider;
