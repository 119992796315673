import type { ReactElement } from 'react';

import { useAuth } from '@virtuslab/react-oauth2';
import qs from 'qs';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';

import { Box } from '@mui/material';
import { GoogleLogo } from 'phosphor-react';

import Background from '../../atoms/Background';
import CTAButton from '../../molecules/CTAButton';
import Header from '../../molecules/Header';
import Icon from '../../molecules/Icon';
import NfsLogo from '../../molecules/NfsLogo';

const LoginContainer = styled.div`
  display: grid;
  grid-template-areas:
    'top_empty'
    'login_text'
    'bottom_empty';
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto 1.5fr;
  height: 100%;
  justify-items: center;
  width: 100%;
`;

type Props = Readonly<{
  baseUrl: string;
}>;

function AuthLogin({
  baseUrl,
}: Props): ReactElement {
  const auth = useAuth();
  const { t } = useTranslation();

  const { search } = useLocation();
  const { redirectUri = '/' } = qs.parse(search, { ignoreQueryPrefix: true });
  const redirectAfterLogin = `${baseUrl}${String(redirectUri)}`;

  if (auth.isAuthenticated()) {
    return (<Navigate to={redirectAfterLogin} />);
  }

  return (
    <LoginContainer>
      <Box position="absolute" top={0} left={0} p={600}>
        <NfsLogo />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        width="max-content"
        gridArea="login_text"
      >
        <Box p={600}>
          <Header as="h1">
            {t('Log in')}
          </Header>
        </Box>
        <Box py={600}>
          <CTAButton fullWidth onClick={async () => auth.login(redirectAfterLogin, 'google', 'login')}>
            <Box px={300} display="inline-flex">
              <Box px={700} display="inline-flex" alignItems="center">
                <Box display="inline-flex" mr={300}>
                  <Icon size="lg">
                    <GoogleLogo weight="bold" />
                  </Icon>
                </Box>
                {t('Continue with Google')}
              </Box>
            </Box>
          </CTAButton>
        </Box>
      </Box>
      <Background />
    </LoginContainer>
  );
}

export default AuthLogin;
