import type {
  DocumentNode,
  LazyQueryHookOptions,
  QueryHookOptions,
  QueryResult,
  QueryTuple,
  MutationTuple,
  MutationHookOptions,
} from '@apollo/client';
import isNil from 'lodash/isNil';
import type { UnionToIntersection } from 'type-fest';

import type {
  useAdminProjectsColorsProviderAllProjectsQuery,
  useAdminTimeReportBlockedMessageSpaceOwnerQuery,
  useAdminTimeReportOnCallDetailsModalQuery,
  useAdminBenefitSectionsAssignedBenefitVariantsQuery,
  useAdminTimeReportEntryModalLimitsQuery,
  useAdminInvoicesDrawerCreateInvoiceMutation,
  useAdminInvoicesDrawerRemoveInvoiceMutation,
  useAdminInvoicesDrawerUpdateInvoiceMutation,
  useInvoicesDrawerInvoicesLazyQuery as useAdminInvoicesDrawerInvoicesLazyQuery,
  useAdminTimeReportFinancesLazyQuery,
  useAdminTimeReportLeaveAndTrainingQuery,
  useAdminDirectReportingStructureQuery,
  useAdminTimeReportStatsLazyQuery,
  useTimeReportWorkTimeInsightsLazyQuery as useAdminTimeReportWorkTimeInsightsLazyQuery,
  useAdminPreviousMonthPaymentStatusQuery,
} from '../schema/admin';
import type {
  Exact,
  useUserProjectsColorsProviderAllProjectsQuery,
  useUserTimeReportBlockedMessageSpaceOwnerQuery,
  useUserTimeReportOnCallDetailsModalQuery,
  useUserBenefitSectionsAssignedBenefitVariantsQuery,
  useUserTimeReportEntryModalLimitsQuery,
  useUserInvoicesDrawerCreateInvoiceMutation,
  useUserInvoicesDrawerRemoveInvoiceMutation,
  useUserInvoicesDrawerUpdateInvoiceMutation,
  useInvoicesDrawerInvoicesLazyQuery as useUserInvoicesDrawerInvoicesLazyQuery,
  useUserTimeReportFinancesLazyQuery,
  useUserTimeReportLeaveAndTrainingQuery,
  useUserDirectReportingStructureQuery,
  useUserTimeReportStatsLazyQuery,
  useTimeReportWorkTimeInsightsLazyQuery as useUserTimeReportWorkTimeInsightsLazyQuery,
  useUserPreviousMonthPaymentStatusQuery,
} from '../schema/user';
import { AccessModes, parseAccessRights, type ParseAccessRights } from '../services/authorization';
import type { ErrorTuple } from '../services/errors';
import { splitError } from '../services/errors';
import type { Literal } from '../services/guards';
import type { ExtractErrorTuple } from '../services/hooks/useToasts';
import type {
  GetPathValue, MergeDeepObject, ObjectValues, ToPathObject,
} from '../services/object';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ApolloOptsType = any;
// eslint-disable-next-line @typescript-eslint/ban-types
type EmptyVariablesType = Readonly<{}>;

type AnyHookShape = (baseOptions: ApolloOptsType) => unknown;

type ReplaceEmptyVariables<Variables> = string extends keyof Variables ? EmptyVariablesType : Variables;

type InferVariables<Hook extends AnyHookShape> =
   Parameters<Hook>[0] extends QueryHookOptions<ApolloOptsType, infer Variables>
     ? ReplaceEmptyVariables<Variables>
     : NonNullable<Parameters<Hook>[0]> extends LazyQueryHookOptions<ApolloOptsType, infer Variables>
       ? ReplaceEmptyVariables<Variables>
       : EmptyVariablesType;

export type InterchangeableHooks<HookA extends AnyHookShape, HookB extends AnyHookShape> = Readonly<{
  hookA: HookA; hookB: HookB;
}>;

type QueryCreatorConfig = Readonly<{
  withParsedAccessRights: boolean;
  withErrorSplit: boolean;
}>;

type InterchangeableQueriesFromQueryTypes<
HookA extends AnyHookShape,
QueryA,
HookB extends AnyHookShape,
QueryB,
Config extends QueryCreatorConfig,
> = Readonly<{
  hook: (...args: ApolloOptsType[]) => ReturnType<HookA> | ReturnType<HookB>;
  splitError: false extends Config['withErrorSplit'] ? null
    : false extends Config['withParsedAccessRights']
      ? (response?: QueryA | QueryB | null) => ErrorTuple<ExtractErrorTuple<QueryA>>
      | ErrorTuple<ExtractErrorTuple<QueryB>>
      : (response?: QueryA | QueryB | null) => ErrorTuple<
      ParseAccessRights<ExtractErrorTuple<QueryA>, AccessModes.assumeHandled>>
      | ErrorTuple<ParseAccessRights<ExtractErrorTuple<QueryB>, AccessModes.assumeHandled>>;
}>;

export type InterchangeableQueries<HookA extends AnyHookShape, HookB extends AnyHookShape,
Config extends QueryCreatorConfig> =
ReturnType<HookA> extends QueryResult<infer QueryA, ApolloOptsType>
  ? ReturnType<HookB> extends QueryResult<infer QueryB, ApolloOptsType>
    ? InterchangeableQueriesFromQueryTypes<HookA, QueryA, HookB, QueryB, Config>
    : never
  : ReturnType<HookA> extends QueryTuple<infer QueryA, ApolloOptsType>
    ? ReturnType<HookB> extends QueryTuple<infer QueryB, ApolloOptsType>
      ? InterchangeableQueriesFromQueryTypes<HookA, QueryA, HookB, QueryB, Config>
      : never
    : ReturnType<HookA> extends MutationTuple<infer MutationA, ApolloOptsType>
      ? ReturnType<HookB> extends MutationTuple<infer MutationB, ApolloOptsType>
        ? InterchangeableQueriesFromQueryTypes<HookA, MutationA, HookB, MutationB, Config>
        : never
      : never;

type MergeVariables<VariablesA, VariablesB> = VariablesA extends Exact<infer VarA>
  ? VariablesB extends Exact<infer VarB>
    ? Partial<MergeDeepObject<VarA, VarB>>
    : Partial<VarA>
  : VariablesB extends Exact<infer VarB>
    ? Partial<VarB>
    : never;

export type MergeQueries<HookA extends AnyHookShape, HookB extends AnyHookShape> =
ReturnType<HookA> extends QueryResult<infer QueryA, ApolloOptsType>
  ? ReturnType<HookB> extends QueryResult<infer QueryB, ApolloOptsType>
    ? (
      baseOptions: QueryHookOptions<QueryA, MergeVariables<InferVariables<HookA>, InferVariables<HookB>>>
      | QueryHookOptions<QueryB, MergeVariables<InferVariables<HookA>, InferVariables<HookB>>>
    ) => ReturnType<HookA> | ReturnType<HookB>
    : never
  : ReturnType<HookA> extends QueryTuple<infer QueryA, ApolloOptsType>
    ? ReturnType<HookB> extends QueryTuple<infer QueryB, ApolloOptsType>
      ? (
        baseOptions: LazyQueryHookOptions<QueryA, MergeVariables<InferVariables<HookA>, InferVariables<HookB>>>
        | LazyQueryHookOptions<QueryB, MergeVariables<InferVariables<HookA>, InferVariables<HookB>>>
      ) => ReturnType<HookA> | ReturnType<HookB>
      : never
    : ReturnType<HookA> extends MutationTuple<infer MutationA, ApolloOptsType>
      ? ReturnType<HookB> extends MutationTuple<infer MutationB, ApolloOptsType>
        ? (
          baseOptions?: MutationHookOptions<MutationA, MergeVariables<InferVariables<HookA>, InferVariables<HookB>>>
          | MutationHookOptions<MutationB, MergeVariables<InferVariables<HookA>, InferVariables<HookB>>>
        ) => ReturnType<HookA> | ReturnType<HookB>
        : never
      : never;

type Hooks = keyof RequiredHooksConfig['gql']['mutations'] | keyof RequiredHooksConfig['gql']['queries'];
const emptyConfig: QueryCreatorConfig = { withErrorSplit: false, withParsedAccessRights: false };

type ConfigsShape = Readonly<{
  [key in Hooks]: QueryCreatorConfig
}>;

type DeriveRequiredHooks<T, Configs extends ConfigsShape, Config extends QueryCreatorConfig> =
  T extends InterchangeableHooks<infer HookA, infer HookB>
    ? InterchangeableQueries<HookA, HookB, Config>
    : T extends Literal ? {
      [key in keyof T]: key extends keyof ConfigsShape
        ? DeriveRequiredHooks<T[key], Configs, Configs[key]>
        : DeriveRequiredHooks<T[key], Configs, typeof emptyConfig>
    } : T;

type DeriveAllPossibleVariables<T extends Literal> = UnionToIntersection<ObjectValues<{
  [key in keyof ToPathObject<T, 3>]: GetPathValue<T, key> extends InterchangeableHooks<infer HookA, infer HookB>
    ? MergeVariables<InferVariables<HookA>, InferVariables<HookB>> : never;
}>>;

// type NonSplittableErrors<Config, HooksToOmit> = Config extends InterchangeableQueries<infer HookA, infer HookB>
//   ? InterchangeableQueries<HookA, HookB>
//   : Config extends Literal ? {
//     [key in keyof Config]: key extends HooksToOmit
//       ? Omit<NonSplittableErrors<Config[key], HooksToOmit>, 'splitError'>
//       : NonSplittableErrors<Config[key], HooksToOmit>;
//   } : Config;

const makeQueryConfig = <T extends ConfigsShape>(arg: T) => arg;

const queryConfig = makeQueryConfig({
  useBenefitSectionsAssignedBenefitVariantsQuery: { withErrorSplit: false, withParsedAccessRights: false },
  useInvoicesDrawerInvoicesLazyQuery: { withErrorSplit: false, withParsedAccessRights: false },
  useTimeReportEntryModalLimitsQuery: { withErrorSplit: false, withParsedAccessRights: false },
  useTimeReportFinances: { withErrorSplit: false, withParsedAccessRights: false },
  useTimeReportLeaveAndTrainingQuery: { withErrorSplit: false, withParsedAccessRights: false },
  useDirectReportingStructureQuery: { withErrorSplit: true, withParsedAccessRights: true },
  useInvoicesDrawerCreateInvoiceMutation: { withErrorSplit: true, withParsedAccessRights: false },
  useInvoicesDrawerRemoveInvoiceMutation: { withErrorSplit: true, withParsedAccessRights: false },
  useInvoicesDrawerUpdateInvoiceMutation: { withErrorSplit: true, withParsedAccessRights: false },
  useOnCallMultipliers: { withErrorSplit: true, withParsedAccessRights: false },
  useProjectColors: { withErrorSplit: true, withParsedAccessRights: false },
  useUserSpaceOwner: { withErrorSplit: true, withParsedAccessRights: false },
  useTimeReportStats: { withErrorSplit: false, withParsedAccessRights: true },
  useTimeReportWorkTimeInsightsLazyQuery: { withErrorSplit: true, withParsedAccessRights: true },
  usePreviousMonthPaymentStatusQuery: { withErrorSplit: false, withParsedAccessRights: true },
});

export type RequiredHooksConfig = Readonly<{
  gql: {
    queries: {
      useProjectColors: InterchangeableHooks<
        typeof useAdminProjectsColorsProviderAllProjectsQuery,
        typeof useUserProjectsColorsProviderAllProjectsQuery
      >;
      useUserSpaceOwner: InterchangeableHooks<
        typeof useAdminTimeReportBlockedMessageSpaceOwnerQuery,
        typeof useUserTimeReportBlockedMessageSpaceOwnerQuery
      >;
      useOnCallMultipliers: InterchangeableHooks<
        typeof useAdminTimeReportOnCallDetailsModalQuery,
        typeof useUserTimeReportOnCallDetailsModalQuery
      >;
      useTimeReportFinances: InterchangeableHooks<
        typeof useAdminTimeReportFinancesLazyQuery,
        typeof useUserTimeReportFinancesLazyQuery
      >;
      useBenefitSectionsAssignedBenefitVariantsQuery: InterchangeableHooks<
        typeof useAdminBenefitSectionsAssignedBenefitVariantsQuery,
        typeof useUserBenefitSectionsAssignedBenefitVariantsQuery
      >;
      useTimeReportEntryModalLimitsQuery: InterchangeableHooks<
        typeof useAdminTimeReportEntryModalLimitsQuery,
        typeof useUserTimeReportEntryModalLimitsQuery
      >;
      useInvoicesDrawerInvoicesLazyQuery: InterchangeableHooks<
        typeof useAdminInvoicesDrawerInvoicesLazyQuery,
        typeof useUserInvoicesDrawerInvoicesLazyQuery
      >;
      useTimeReportLeaveAndTrainingQuery: InterchangeableHooks<
        typeof useAdminTimeReportLeaveAndTrainingQuery,
        typeof useUserTimeReportLeaveAndTrainingQuery
      >;
      useDirectReportingStructureQuery: InterchangeableHooks<
        typeof useAdminDirectReportingStructureQuery,
        typeof useUserDirectReportingStructureQuery
      >;
      useTimeReportStats: InterchangeableHooks<
        typeof useAdminTimeReportStatsLazyQuery,
        typeof useUserTimeReportStatsLazyQuery
      >;
      useTimeReportWorkTimeInsightsLazyQuery: InterchangeableHooks<
        typeof useAdminTimeReportWorkTimeInsightsLazyQuery,
        typeof useUserTimeReportWorkTimeInsightsLazyQuery
      >;
      usePreviousMonthPaymentStatusQuery: InterchangeableHooks<
        typeof useAdminPreviousMonthPaymentStatusQuery,
        typeof useUserPreviousMonthPaymentStatusQuery
      >;
    };
    mutations: {
      useInvoicesDrawerCreateInvoiceMutation: InterchangeableHooks<
        typeof useAdminInvoicesDrawerCreateInvoiceMutation,
        typeof useUserInvoicesDrawerCreateInvoiceMutation
      >;
      useInvoicesDrawerRemoveInvoiceMutation: InterchangeableHooks<
        typeof useAdminInvoicesDrawerRemoveInvoiceMutation,
        typeof useUserInvoicesDrawerRemoveInvoiceMutation
      >;
      useInvoicesDrawerUpdateInvoiceMutation: InterchangeableHooks<
        typeof useAdminInvoicesDrawerUpdateInvoiceMutation,
        typeof useUserInvoicesDrawerUpdateInvoiceMutation
      >;
    };
  };
}>;

export type RequiredDocuments = Readonly<{
  userProjectsIds: DocumentNode;
  timeReportEntryModalLimits: DocumentNode;
}>;

export type RequiredHooks = DeriveRequiredHooks<RequiredHooksConfig, typeof queryConfig, typeof emptyConfig>;
export type CommonVariables = DeriveAllPossibleVariables<RequiredHooksConfig['gql']>;

export type MakeHooksConfigArg = Readonly<{
  useUserSpaceOwner: RequiredHooks['gql']['queries']['useUserSpaceOwner']['hook'];
  useProjectColors: RequiredHooks['gql']['queries']['useProjectColors']['hook'];
  useOnCallMultipliers: RequiredHooks['gql']['queries']['useOnCallMultipliers']['hook'];
  useTimeReportFinances: RequiredHooks['gql']['queries']['useTimeReportFinances']['hook'];
  useDirectReportingStructureQuery: RequiredHooks['gql']['queries']['useDirectReportingStructureQuery']['hook'];
  useBenefitSectionsAssignedBenefitVariantsQuery: RequiredHooks['gql']['queries']['useBenefitSectionsAssignedBenefitVariantsQuery']['hook'];
  useTimeReportEntryModalLimitsQuery: RequiredHooks['gql']['queries']['useTimeReportEntryModalLimitsQuery']['hook'];
  useInvoicesDrawerInvoicesLazyQuery: RequiredHooks['gql']['queries']['useInvoicesDrawerInvoicesLazyQuery']['hook'];
  useInvoicesDrawerCreateInvoiceMutation: RequiredHooks['gql']['mutations']['useInvoicesDrawerCreateInvoiceMutation']['hook'];
  useTimeReportLeaveAndTrainingQuery: RequiredHooks['gql']['queries']['useTimeReportLeaveAndTrainingQuery']['hook'];
  useInvoicesDrawerRemoveInvoiceMutation: RequiredHooks['gql']['mutations']['useInvoicesDrawerRemoveInvoiceMutation']['hook'];
  useInvoicesDrawerUpdateInvoiceMutation: RequiredHooks['gql']['mutations']['useInvoicesDrawerUpdateInvoiceMutation']['hook'];
  useTimeReportStats: RequiredHooks['gql']['queries']['useTimeReportStats']['hook'];
  useTimeReportWorkTimeInsightsLazyQuery: RequiredHooks['gql']['queries']['useTimeReportWorkTimeInsightsLazyQuery']['hook'];
  usePreviousMonthPaymentStatusQuery: RequiredHooks['gql']['queries']['usePreviousMonthPaymentStatusQuery']['hook'];
}>;

export const makeHooksConfig = ({
  useProjectColors,
  useUserSpaceOwner,
  useOnCallMultipliers,
  useTimeReportFinances,
  useBenefitSectionsAssignedBenefitVariantsQuery,
  useTimeReportEntryModalLimitsQuery,
  useInvoicesDrawerCreateInvoiceMutation,
  useInvoicesDrawerRemoveInvoiceMutation,
  useInvoicesDrawerUpdateInvoiceMutation,
  useInvoicesDrawerInvoicesLazyQuery,
  useTimeReportLeaveAndTrainingQuery,
  useDirectReportingStructureQuery,
  useTimeReportStats,
  useTimeReportWorkTimeInsightsLazyQuery,
  usePreviousMonthPaymentStatusQuery,
}: MakeHooksConfigArg): RequiredHooks => ({
  gql: {
    mutations: {
      useInvoicesDrawerCreateInvoiceMutation: {
        hook: useInvoicesDrawerCreateInvoiceMutation,
        splitError: (response) => {
          if (isNil(response)) {
            return [null, null];
          }

          if ('contractSalaryDetails' in response) {
            return splitError(response.contractSalaryDetails.createInvoiceDetails);
          }

          return splitError(response.reporting.invoiceDetailsApi.createInvoiceDetails);
        },
      },
      useInvoicesDrawerRemoveInvoiceMutation: {
        hook: useInvoicesDrawerRemoveInvoiceMutation,
        splitError: (response) => {
          if (isNil(response)) {
            return [null, null];
          }

          if ('contractSalaryDetails' in response) {
            return splitError(response.contractSalaryDetails.removeInvoiceDetails);
          }

          return splitError(response.reporting.invoiceDetailsApi.removeInvoiceDetails);
        },
      },
      useInvoicesDrawerUpdateInvoiceMutation: {
        hook: useInvoicesDrawerUpdateInvoiceMutation,
        splitError: (response) => {
          if (isNil(response)) {
            return [null, null];
          }

          if ('contractSalaryDetails' in response) {
            return splitError(response.contractSalaryDetails.updateInvoiceDetails);
          }

          return splitError(response.reporting.invoiceDetailsApi.updateInvoiceDetails);
        },
      },
    },
    queries: {
      useTimeReportWorkTimeInsightsLazyQuery: {
        hook: useTimeReportWorkTimeInsightsLazyQuery,
        splitError: (response) => splitError(parseAccessRights(response?.timeReport, AccessModes.assumeHandled)),
      },
      useDirectReportingStructureQuery: {
        hook: useDirectReportingStructureQuery,
        splitError: (response) => {
          if (isNil(response)) {
            return [null, null];
          }

          if ('profile' in response) {
            return splitError(response.profile);
          }

          return splitError(parseAccessRights(response.user, AccessModes.assumeHandled));
        },
      },
      usePreviousMonthPaymentStatusQuery: {
        hook: usePreviousMonthPaymentStatusQuery,
        splitError: null,
      },
      useTimeReportStats: {
        hook: useTimeReportStats,
        splitError: null,
      },
      useTimeReportLeaveAndTrainingQuery: {
        hook: useTimeReportLeaveAndTrainingQuery,
        splitError: null,
      },
      useInvoicesDrawerInvoicesLazyQuery: {
        hook: useInvoicesDrawerInvoicesLazyQuery,
        splitError: null,
      },
      useTimeReportEntryModalLimitsQuery: {
        hook: useTimeReportEntryModalLimitsQuery,
        splitError: null,
      },
      useUserSpaceOwner: {
        hook: useUserSpaceOwner,
        splitError: (response) => {
          if (isNil(response)) {
            return [null, null];
          }

          if ('profile' in response) {
            return splitError(response.profile);
          }

          return splitError(response.user);
        },
      },
      useProjectColors: {
        hook: useProjectColors,
        splitError: (response) => {
          if (isNil(response)) {
            return [null, null];
          }

          if ('projects' in response) {
            return splitError(response.projects);
          }

          return splitError(response.getUserProjects);
        },
      },
      useOnCallMultipliers: {
        hook: useOnCallMultipliers,
        splitError: (response) => splitError(response?.onCallMultipliers),
      },
      useTimeReportFinances: {
        hook: useTimeReportFinances,
        splitError: null,
      },
      useBenefitSectionsAssignedBenefitVariantsQuery: {
        hook: useBenefitSectionsAssignedBenefitVariantsQuery,
        splitError: null,
      },
    },
  },
});
