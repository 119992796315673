import mapValues from 'lodash/mapValues';
import merge from 'lodash/merge';

import type { Language, TranslatingFn } from '../i18n';

import type { Finances } from './finances';
import finances, { TimeUnit } from './finances';
import type { List } from './list';
import list from './list';
import type { PersonalInfo } from './personalInfo';
import personalInfo from './personalInfo';
import type { Time } from './time';
import time, { DateFormat } from './time';

type Formatters = Finances & List & PersonalInfo & Time;
const formatters: Formatters = merge(finances, time, personalInfo, list);

type FormattinFnConfig = Readonly<{
  t: TranslatingFn;
  language: Language;
}>;

export type FormattingFn<T, ReturnValue = string, AvailableFormats = undefined> = (
  (config: FormattinFnConfig) => (
    (data: T, ...format: AvailableFormats extends undefined
      ? []
      : [AvailableFormats]
    ) => ReturnValue
  )
);

export type FormattingService = Readonly<{
  [formatter in keyof Formatters]: ReturnType<Formatters[formatter]>
}>;

export {
  DateFormat,
  TimeUnit,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (language: Language, t: TranslatingFn): FormattingService => mapValues<Formatters, any>(
  formatters, <T extends keyof Formatters>(fn: Formatters[T]) => fn({ language, t }),
);
