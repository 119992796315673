import type { FieldPolicy } from '@apollo/client';
import isArray from 'lodash/isArray';
import isBoolean from 'lodash/isBoolean';
import isDate from 'lodash/isDate';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import { DateTime } from 'luxon';

import { getTimeZoneAwareDate, toISOMonth } from '../services/date';

import type { ScalarParsers as AdminScalarParsers } from './admin/scalarPaths';
import type { Maybe } from './user';
import type { ScalarParsers as UserScalarParsers } from './user/scalarPaths';

type ScalarFieldPolicy<IncomingType, ReturnType> = FieldPolicy<
Maybe<ReturnType>,
Maybe<IncomingType>
>;

export const monthDayPolicy: ScalarFieldPolicy<Date | string, Date> = {
  merge: (_, incoming) => {
    if (isNil(incoming) || isDate(incoming)) {
      return incoming;
    }

    // MonthDay in format --MM-DD
    const monthDayRegex = /^--[0-9]{2}-[0-9]{2}$/;

    if (isString(incoming) && monthDayRegex.exec(incoming)) {
      return new Date(`1970-${incoming.slice(2)}`);
    }

    return null;
  },
};

export const arrayTypePolicy = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ofType: ScalarFieldPolicy<any, any>,
): ScalarFieldPolicy<unknown[], unknown[]> => ({
  merge: (_, incoming, opts) => {
    const fn = ofType.merge;

    if (isNil(fn) || isBoolean(fn)) {
      return incoming;
    }

    if (isNil(incoming)) {
      return incoming;
    }

    if (isArray(incoming)) {
      const array: unknown[] = isNil(ofType.merge) || isBoolean(ofType.merge)
        ? incoming : incoming.map((data): unknown => fn(_, data, opts));
      return array;
    }

    return incoming;
  },
});

export const dateTypePolicy: ScalarFieldPolicy<Date | string, Date> = {
  merge: (_, incoming) => {
    if (isNil(incoming)) {
      return incoming;
    }

    if (isString(incoming)) {
      return DateTime.fromISO(incoming).toJSDate();
    }

    return incoming;
  },
};

export const localDateTypePolicy: ScalarFieldPolicy<Date | string, Date> = {
  merge: (_, incoming) => {
    if (isNil(incoming)) {
      return incoming;
    }

    if (isString(incoming)) {
      return getTimeZoneAwareDate(incoming);
    }

    return incoming;
  },
};

export const timeTypePolicy: ScalarFieldPolicy<Date | string, Date> = {
  merge: (_, incoming) => {
    if (isNil(incoming)) {
      return incoming;
    }

    if (isString(incoming)) {
      return DateTime.fromISO(incoming).toJSDate();
    }

    return incoming;
  },
};

export const basisPointTypePolicy: ScalarFieldPolicy<number, number> = {
  merge: (_, incoming) => {
    if (isNil(incoming)) {
      return incoming;
    }

    return incoming / 100;
  },
};

export const centsTypePolicy: ScalarFieldPolicy<number, number> = {
  merge: (_, incoming) => {
    if (isNil(incoming)) {
      return incoming;
    }

    return incoming / 100;
  },
};

type ScalarVatStringValues = 'ExemptFromVAT' | 'VatNotApplicable';
export const vatTypeInputPolicy: ScalarFieldPolicy<ScalarVatStringValues | number, ScalarVatStringValues | number> = {
  merge: (_, incoming) => {
    if (isNil(incoming) || isString(incoming)) {
      return incoming;
    }

    return incoming / 100;
  },
};

export const scalarParsers: AdminScalarParsers & UserScalarParsers = {
  Cents: (number) => {
    if (isNil(number)) {
      return null;
    }

    return Math.round(number * 100);
  },
  DateTime: (date) => {
    if (isNil(date)) {
      return null;
    }

    return DateTime.fromJSDate(date).toISO();
  },
  LocalDate: (date) => {
    if (isNil(date)) {
      return null;
    }

    return DateTime.fromJSDate(date).toISODate();
  },
  LocalTime: (date) => {
    if (isNil(date)) {
      return null;
    }

    return DateTime.fromJSDate(date).toLocaleString(DateTime.TIME_24_SIMPLE);
  },
  BasisPoint: (basisPoint) => {
    if (isNil(basisPoint)) {
      return null;
    }

    return Math.round(basisPoint * 100);
  },
  YearMonth: (date) => {
    if (isNil(date)) {
      return null;
    }

    return toISOMonth(date);
  },
  Date: (date) => {
    if (isNil(date)) {
      return null;
    }

    return DateTime.fromJSDate(date).toISODate();
  },
  OffsetDateTime: (date) => {
    if (isNil(date)) {
      return null;
    }

    return DateTime.fromJSDate(date).toISO({ includeOffset: true });
  },
  LocalDateTime: (date) => {
    if (isNil(date)) {
      return null;
    }

    return DateTime.fromJSDate(date).toISO();
  },
  VatTypeInput: (vatValue) => {
    if (isNil(vatValue) || isString(vatValue)) {
      return vatValue;
    }

    return vatValue * 100;
  },
};
