import type { ReactElement, ReactNode } from 'react';

import shouldForwardProp from '@styled-system/should-forward-prop';
import styled, { css } from 'styled-components/macro';

import palette from '../../../theme/palette';
import type { Size } from '../../../theme/typography';
import { lineHeights, weights } from '../../../theme/typography';

const variants = {
  primary: {
    normal: { default: palette.greyscale[90], disabled: palette.greyscale[40] },
    inverted: { default: palette.inverted[100], disabled: palette.greyscale[40] },
  },
  secondary: {
    normal: { default: palette.greyscale[60], disabled: palette.greyscale[40] },
    inverted: { default: palette.inverted[70], disabled: palette.greyscale[40] },
  },
  ghost: {
    normal: { default: palette.greyscale[30], disabled: palette.greyscale[30] },
    inverted: { default: palette.inverted[30], disabled: palette.greyscale[30] },
  },
  active: {
    normal: { default: palette.support.success[60], disabled: palette.greyscale[40] },
    // same as normal
    inverted: { default: palette.support.success[60], disabled: palette.greyscale[40] },
  },
  alert: {
    normal: { default: palette.support.alert[60], disabled: palette.greyscale[40] },
    // same as normal
    inverted: { default: palette.support.alert[60], disabled: palette.greyscale[40] },
  },
  info: {
    normal: { default: palette.support.info[60], disabled: palette.greyscale[40] },
    // same as normal
    inverted: { default: palette.support.info[60], disabled: palette.greyscale[40] },
  },
  success: {
    normal: { default: palette.support.success[60], disabled: palette.greyscale[40] },
    // same as normal
    inverted: { default: palette.support.success[60], disabled: palette.greyscale[40] },
  },
  warning: {
    normal: { default: palette.support.warning[60], disabled: palette.greyscale[40] },
    inverted: { default: palette.support.warning[60], disabled: palette.greyscale[40] },
  },
} as const;

export type Variant = keyof typeof variants;

const styleVariants = {
  700: { weight: weights.bold, lineHeight: lineHeights.xs },
  600: { weight: weights.bold, lineHeight: lineHeights.sm },
  500: { weight: weights.semiBold, lineHeight: lineHeights.sm },
  400: { weight: weights.medium, lineHeight: lineHeights.lg },
  300: { weight: weights.medium, lineHeight: lineHeights.md },
  200: { weight: weights.medium, lineHeight: lineHeights.sm },
  100: { weight: weights.bold, lineHeight: lineHeights.xxs },
} as const;

const headerToSize = {
  h1: 700,
  h2: 600,
  h3: 500,
  h4: 400,
  h5: 300,
  h6: 200,
  h7: 100,
} as const;

export type TextProps = Readonly<{
  variant?: Variant;
  inline?: boolean;
  size: Size;
  disabled?: boolean;
  inverted?: boolean;
  color?: string;
  textAlign?: 'center';
  textTransform?: 'capitalize' | 'uppercase';
  break?: 'break-all';
}>;

const headerCursor = ({ disabled = false }: TextProps) => css`
  cursor: ${disabled ? 'not-allowed' : null};
`;

const headerColors = ({
  variant = 'primary', color: colorOverwrite, disabled = false, inverted = false,
}: TextProps) => {
  const colorVariants = variants[variant];

  const colorVariant = inverted
    ? colorVariants.inverted
    : colorVariants.normal;

  const color = colorOverwrite ?? (disabled
    ? colorVariant.disabled
    : colorVariant.default);

  return css`
    color: ${color};
  `;
};

const StyledHeader = styled.span.withConfig({
  shouldForwardProp: (prop) => shouldForwardProp(prop),
})<TextProps>`
  display: ${({ inline = false }) => (inline ? 'inline' : 'inline-flex')};
  font-size: ${({ theme, size }) => theme.scTheme.fontSizes[size]};
  text-align: ${(props) => props.textAlign};
  text-transform: ${(props) => props.textTransform};
  word-break: ${(props) => props.break};
  ${headerColors}
  ${headerCursor}
  ${({ size }) => css`
    font-weight: ${styleVariants[size].weight};
    line-height: ${styleVariants[size].lineHeight};
  `}
`;

export type Props = Omit<TextProps, 'size'> & Readonly<{
  as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'h7';
  children?: ReactNode;
}>;

const Header = ({ as, ...props }: Props): ReactElement => (
  <StyledHeader as={as === 'h7' ? 'h6' : as} {...props} size={headerToSize[as]} />
);

export default Header;
