import type { ReactNode, ReactElement } from 'react';
import {
  createContext, useEffect, useState, useContext,
} from 'react';

import isNil from 'lodash/isNil';
import { useTranslation } from 'react-i18next';

import type { FormattingService } from '../../../services/format';
import createService from '../../../services/format';
import type { Language } from '../../../services/i18n';
import type { Nullable } from '../../../services/object';

type Props = Readonly<{
  children: ReactNode;
}>;

const context = createContext<Nullable<FormattingService>>(null);

const FormattingProvider = ({ children }: Props): ReactElement => {
  const { i18n, t } = useTranslation();
  const [service, updateService] = useState(createService(i18n.language, t));

  useEffect(() => {
    const onLanguageChanged = (language: Language) => updateService(createService(language, t));

    i18n.on('languageChanged', onLanguageChanged);
    return () => i18n.off('languageChanged', onLanguageChanged);
  }, [i18n, t]);

  return (
    <context.Provider value={service}>
      {children}
    </context.Provider>
  );
};

export const useFormatting = (): FormattingService => {
  const value = useContext(context);

  if (isNil(value)) {
    throw Error('Using useFormatting outside of provider');
  }

  return value;
};
export default FormattingProvider;
