import type { ReactNode, ReactElement } from 'react';

import { useAuth } from '@virtuslab/react-oauth2';

import HooksProvider from '@virtuslab/nfs-shared/src/components/organisms/HooksProvider';
import SharedServicesProvider from '@virtuslab/nfs-shared/src/components/organisms/ServicesProvider';
import UsersnapProvider from '@virtuslab/nfs-shared/src/components/organisms/UsersnapProvider';

import config from '../../../config';
import { hooks, documents } from '../../../config/hooks';

import TranslationsProvider from '../TranslationsProvider';

type Props = Readonly<{
  children: ReactNode;
}>;

const ServicesProvider = ({ children }: Props): ReactElement => {
  const auth = useAuth();
  const authenticated = auth.isAuthenticated();
  const user = authenticated ? auth.getUserProfile() : null;

  return (
    <TranslationsProvider>
      <UsersnapProvider
        initParams={{
          custom: {
            version: config.release,
          },
          user: {
            email: user?.email,
          },
        }}
      >
        <HooksProvider hooks={hooks} documents={documents}>
          <SharedServicesProvider>
            {children}
          </SharedServicesProvider>
        </HooksProvider>
      </UsersnapProvider>
    </TranslationsProvider>
  );
};

export default ServicesProvider;
