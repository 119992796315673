import styled from 'styled-components/macro';

const ModalOverlay = styled.div`
  &&&& {
    background-color: ${({ theme }) => theme.scTheme.palette.inverted[100]};
    height: 100%;
    position: absolute;
    width: 100%;
  }
`;

export default ModalOverlay;
