import type { ReactElement, ReactNode } from 'react';

import { I18nextProvider } from 'react-i18next';

import { i18n } from '../../../services/i18n';

type Props = Readonly<{
  children: ReactNode;
}>;

const TranslationsProvider = ({ children }: Props): ReactElement => (
  <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
);

export default TranslationsProvider;
