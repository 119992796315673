import type { ReactElement, ReactNode } from 'react';
import { useEffect, useRef, useState } from 'react';

import isNil from 'lodash/isNil';
import { useTranslation } from 'react-i18next';

import type { Config } from '../../../config';
import { checkVersionIntervalMS } from '../../../config/component';
import { isObjectWithKeys } from '../../../services/guards';
import useSelfUpdatingRef from '../../../services/hooks/useSelfUpdatingRef';

import Body from '../../molecules/Body';
import CTAButton from '../../molecules/CTAButton';
import Header from '../../molecules/Header';
import Loading from '../../molecules/Loading';
import ConfirmationModal from '../ConfirmationModal';

type VersionEndpointJSONShape = Readonly<{
  service: string;
  git: Readonly<{
    commit: string;
  }>;
}>;

type VersionState = Readonly<{
  version: string;
  confirmed: boolean;
  shouldRefreshImmediately: boolean;
}>;

type Props = Readonly<{
  children: ReactNode;
  config: Config;
}>;

const AppVersionGuard = ({ children, config }: Props): ReactElement => {
  const { t } = useTranslation();

  const intervalDelayMS = useRef(checkVersionIntervalMS.focused);
  const [initialCheck, setInitialCheck] = useState(false);

  const [versionState, setVersionState] = useState<VersionState>({
    version: config.version,
    confirmed: true,
    shouldRefreshImmediately: false,
  });

  const onReload = () => {
    window.location.reload();
  };

  const onClose = () => {
    setVersionState((prev) => ({ ...prev, confirmed: true }));
  };

  const checkIfUpToDateRef = useSelfUpdatingRef(async (shouldRefreshImmediately = false) => {
    const appVersionInfo = await fetch(config.appVersionStatusUrl, { cache: 'no-cache' })
      .then(async (response) => response.json())
      .then((data) => {
        if (!isObjectWithKeys<VersionEndpointJSONShape>(data, ['git', 'service'])) {
          return null;
        }

        if (isObjectWithKeys<VersionEndpointJSONShape['git']>(data.git, ['commit'])) {
          return data;
        }

        return null;
      })
      .catch(() => null);
    setInitialCheck(true);

    if (isNil(appVersionInfo)) {
      return;
    }

    setVersionState((prev) => (appVersionInfo.git.commit === prev.version ? prev : {
      version: appVersionInfo.git.commit,
      confirmed: false,
      shouldRefreshImmediately,
    }));
  });

  const checkVersionPeriodicallyRef = useSelfUpdatingRef(async () => {
    // eslint-disable-next-line no-promise-executor-return
    await new Promise((resolve) => setTimeout(resolve, intervalDelayMS.current));
    await checkIfUpToDateRef.current();

    void checkVersionPeriodicallyRef.current();
  });

  useEffect(() => {
    void checkIfUpToDateRef.current(true);

    const onFocus = () => {
      void checkIfUpToDateRef.current();
      intervalDelayMS.current = checkVersionIntervalMS.focused;
    };

    const onBlur = () => {
      intervalDelayMS.current = checkVersionIntervalMS.blurred;
    };

    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);

    void checkVersionPeriodicallyRef.current();

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, [checkIfUpToDateRef, checkVersionPeriodicallyRef]);

  if (!initialCheck) {
    return <Loading />;
  }

  if (versionState.shouldRefreshImmediately) {
    onReload();
    return <Loading />;
  }

  return (
    <>
      {!versionState.confirmed && (
        <ConfirmationModal
          header={<Header as="h4">{t('We have just updated the app')}</Header>}
          body={<Body size={300}>{t('Reload the page to apply these updates')}</Body>}
          onConfirm={onReload}
          onDeny={onClose}
          confirmButton={(
            <CTAButton
              onClick={onReload}
              ref={(node) => node?.focus()}
            >
              {t('Reload')}
            </CTAButton>
          )}
        />
      )}
      {children}
    </>
  );
};

export default AppVersionGuard;
