import * as Sentry from '@sentry/react';
import isString from 'lodash/isString';

import { isLiteral } from './guards';

export function isEmpty<T>(arg: T[]): boolean;
export function isEmpty(arg: string | null | undefined): arg is '' | null | undefined;
export function isEmpty<T>(arg: T | null | undefined): arg is null | undefined;
export function isEmpty<T>(arg: T): boolean {
  if (arg === null || arg === undefined) {
    return true;
  }

  if (isString(arg) && arg.trim() === '') {
    return true;
  }

  if (isLiteral(arg) && Object.keys(arg).length === 0) {
    return true;
  }

  if (Array.isArray(arg) && arg.length === 0) {
    return true;
  }

  return false;
}

export const isNotEmptyString = <T extends string>(str: T | null | undefined): str is Exclude<T, ''> => {
  if (str === null || str === undefined) {
    return false;
  }

  if (str.trim() === '') {
    Sentry.captureMessage('Asserted string was empty');
    return false;
  }

  return true;
};
