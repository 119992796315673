import type { ReactNode, ReactElement } from 'react';

import FormattingProvider from '../FormattingProvider';
import LocaleInfoProvider from '../LocaleInfoProvider';

export type Props = Readonly<{
  children: ReactNode;
}>;

const ServicesProvider = ({ children }: Props): ReactElement => (
  <FormattingProvider>
    <LocaleInfoProvider>
      {children}
    </LocaleInfoProvider>
  </FormattingProvider>
);

export default ServicesProvider;
