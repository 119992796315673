import type { ReactElement } from 'react';

import type { TooltipProps } from '@mui/material/Tooltip';
import MuiTooltip from '@mui/material/Tooltip';

type Props = Readonly<{
  children: ReactElement;
  tooltipProps: Omit<TooltipProps, 'children'>;
  enabled?: boolean;
}>;

const Tooltip = ({ children, enabled = false, tooltipProps }: Props): ReactElement => {
  if (!enabled) {
    return children;
  }

  return (
    <MuiTooltip {...tooltipProps}>
      {children}
    </MuiTooltip>
  );
};

export default Tooltip;
