import type { ReactElement } from 'react';

import shouldForwardProp from '@styled-system/should-forward-prop';
import styled, { css } from 'styled-components/macro';

import type { IconButtonProps } from '@mui/material/IconButton';

import borders from '../../../theme/borders';
import palette from '../../../theme/palette';

const variants = {
  primary: {
    bare: {
      normal: { icon: palette.greyscale[60] },
      hover: { icon: palette.greyscale[70] },
      active: { icon: palette.greyscale[80] },
      focus: { icon: palette.greyscale[60] },
      selected: { icon: palette.primary[60] },
      disabled: { icon: palette.greyscale[40] },
    },
    ghost: {
      normal: { icon: palette.greyscale[90], background: palette.transparent },
      hover: { icon: palette.greyscale[90], background: palette.greyscale[10] },
      active: { icon: palette.greyscale[100], background: palette.greyscale[10] },
      focus: { icon: palette.greyscale[90], background: palette.transparent },
      selected: { icon: palette.primary[60], background: palette.primary[10] },
      disabled: { icon: palette.greyscale[40], background: palette.greyscale[10] },
    },
    outlined: {
      normal: { icon: palette.greyscale[90], background: palette.transparent, border: borders.styles.thin.grey[20] },
      hover: { icon: palette.greyscale[90], background: palette.greyscale[10], border: borders.styles.thin.grey[20] },
      active: { icon: palette.greyscale[100], background: palette.greyscale[10], border: borders.styles.thin.grey[20] },
      focus: { icon: palette.greyscale[90], background: palette.transparent, border: borders.styles.thin.grey[20] },
      selected: { icon: palette.primary[60], background: palette.primary[10], border: borders.styles.thin.grey[20] },
      disabled: {
        icon: palette.greyscale[40], background: palette.greyscale[10], border: borders.styles.thin.grey[20],
      },
    },
  },
  destructive: {
    bare: {
      normal: { icon: palette.support.alert[60] },
      hover: { icon: palette.support.alert[70] },
      active: { icon: palette.support.alert[80] },
      focus: { icon: palette.support.alert[60] },
      disabled: { icon: palette.greyscale[40] },
    },
    ghost: {
      normal: { icon: palette.support.alert[60], background: palette.transparent },
      hover: { icon: palette.support.alert[70], background: palette.support.alert[10] },
      active: { icon: palette.support.alert[80], background: palette.support.alert[10] },
      focus: { icon: palette.support.alert[60], background: palette.transparent },
      disabled: { icon: palette.greyscale[40], background: palette.greyscale[10] },
    },
    outlined: {
      normal: { icon: palette.support.alert[60], border: borders.styles.thin.alert[20] },
      hover: { icon: palette.support.alert[70], border: borders.styles.thin.alert[20] },
      active: { icon: palette.support.alert[80], border: borders.styles.thin.alert[20] },
      focus: { icon: palette.support.alert[60], border: borders.styles.thin.alert[20] },
      disabled: { icon: palette.greyscale[40], border: borders.styles.thin.alert[20] },
    },
  },
} as const;

type Variant = keyof typeof variants;
type Type = keyof typeof variants[Variant];

export type Props = IconButtonProps & Readonly<{
  children: ReactElement;
  variant?: Variant;
  selected?: boolean;
  displayType?: Type;
  disabled?: boolean;
}>;

const buttonCursor = ({ disabled = false }: Props) => css`
  cursor: ${disabled ? 'not-allowed' : null};
`;

const buttonBackgrounds = ({
  variant = 'primary', disabled = false, selected = false, displayType = 'bare',
}: Props) => {
  if (displayType !== 'ghost') {
    return null;
  }

  const colors = variants[variant][displayType];

  if (disabled) {
    return css`
      background-color: ${colors.disabled.background};
    `;
  }

  if (selected && variant === 'primary') {
    return css`
      background-color: ${variants.primary.ghost.selected.background};
    `;
  }

  return css`
    background-color: ${colors.normal.background};

    &:hover {
      background-color: ${colors.hover.background};
    }

    &:active {
      background-color: ${colors.active.background};
    }

    &:focus-visible {
      background-color: ${colors.focus.background};
    }
  `;
};

const buttonBorders = ({
  variant = 'primary', disabled = false, selected = false, displayType = 'bare',
}: Props) => {
  if (displayType !== 'outlined') {
    return null;
  }

  const colors = variants[variant][displayType];
  const sharedBorderStyles = css`
    border-radius: ${({ theme }) => theme.scTheme.borders.radii.sm};
  `;

  if (disabled) {
    return css`
      ${sharedBorderStyles}
      border: ${colors.disabled.border};
    `;
  }

  if (selected && variant === 'primary') {
    return css`
      border: ${colors.active.border};
    `;
  }

  return css`
    border: ${colors.normal.border};

    &:hover {
      border: ${colors.hover.border};
    }

    &:active {
      border: ${colors.active.border};
    }

    &:focus-visible {
      border: ${colors.focus.border};
    }
`;
};

const buttonColors = ({
  variant = 'primary', disabled = false, selected = false, displayType = 'bare',
}: Props) => {
  const colors = variants[variant][displayType];

  if (disabled) {
    return css`
      color: ${colors.disabled.icon};
    `;
  }

  if (selected && variant === 'primary') {
    return css`
      color: ${variants.primary[displayType].selected.icon};

      &:focus-visible {
        box-shadow: ${({ theme }) => theme.scTheme.shadows.focus.primary.full};
        outline: 0;
      }
    `;
  }

  return css`
    color: ${colors.normal.icon};

    &:hover {
      color: ${colors.hover.icon};
    }

    &:focus-visible {
      box-shadow: ${({ theme }) => theme.scTheme.shadows.focus.primary.full};
      color: ${colors.focus.icon};
      outline: 0;
    }

    &:active {
      color: ${colors.active.icon};
    }
  `;
};

const buttonPaddings = ({ displayType = 'bare' }: Props) => {
  if (displayType !== 'bare') {
    return css`
      padding: ${({ theme }) => theme.scTheme.space[300]};
    `;
  }

  return css`
    padding: unset;
  `;
};

const IconButton = styled.button.attrs<Props>(({ type }) => ({
  type: type ?? 'button',
})).withConfig<Props>({ shouldForwardProp })`
  &&& {
    background-color: unset;
    border: unset;
    border-radius: ${({ theme }) => theme.scTheme.borders.radii.md};
    cursor: pointer;
    display: inline-flex;
    flex: 0 0 auto;
    height: max-content;
    min-width: unset;
    overflow: visible;
    position: relative;
    transition: box-shadow ${({ theme }) => theme.scTheme.animations.durations.fast};

    ${buttonColors}
    ${buttonBackgrounds}
    ${buttonPaddings}
    ${buttonCursor}
    ${buttonBorders}
  }
`;

export default IconButton;
