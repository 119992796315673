import findLastIndex from 'lodash/findLastIndex';

import { toTree } from '..';
import type { Drawer, FlatDrawers } from '../../../../models/Drawers';

const queryResolver = (
  drawers: FlatDrawers,
): Drawer | null => {
  const drawerIndex = findLastIndex(drawers, { visible: true });

  if (drawerIndex === -1) {
    return null;
  }

  return toTree(drawers.slice(drawerIndex));
};

export default queryResolver;
