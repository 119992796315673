import type { ReactElement } from 'react';
import {
  useEffect,
  useMemo,
  Suspense,
} from 'react';

import { Secure } from '@virtuslab/react-oauth2';
import isNil from 'lodash/isNil';
import partition from 'lodash/partition';
import qs from 'qs';
import { useTranslation } from 'react-i18next';
import {
  Navigate,
  Outlet,
  useNavigate,
  useParams,
  matchPath,
  useLocation,
} from 'react-router-dom';

import Box from '@mui/material/Box';
import { Question } from 'phosphor-react';

import Body from '@virtuslab/nfs-shared/src/components/molecules/Body';
import Icon from '@virtuslab/nfs-shared/src/components/molecules/Icon';
import Loading from '@virtuslab/nfs-shared/src/components/molecules/Loading';
import LoadingErrorHandler from '@virtuslab/nfs-shared/src/components/molecules/LoadingErrorHandler';
import TextButton from '@virtuslab/nfs-shared/src/components/molecules/TextButton';
import ContentErrorBoundary from '@virtuslab/nfs-shared/src/components/organisms/ContentErrorBoundary';
import NavSidebar from '@virtuslab/nfs-shared/src/components/organisms/NavSidebar';
import ToastsContainer from '@virtuslab/nfs-shared/src/components/organisms/ToastsContainer';
import { B2BContractType } from '@virtuslab/nfs-shared/src/schema/admin';
import type { SecureRoutesWrapperContractsQuery } from '@virtuslab/nfs-shared/src/schema/user';
import {
  EmploymentContractTypename,
  B2BContractTypename, isInstanceOf, useSecureRoutesWrapperContractsQuery, ContractStatus,
} from '@virtuslab/nfs-shared/src/schema/user';
import type { NonError } from '@virtuslab/nfs-shared/src/services/errors';
import { splitError } from '@virtuslab/nfs-shared/src/services/errors';
import type { NonMutable } from '@virtuslab/nfs-shared/src/services/object';
import { generatePath } from '@virtuslab/nfs-shared/src/services/routes';
import { removeInacessibleNavigationOptions } from '@virtuslab/nfs-shared/src/services/security';
import palette from '@virtuslab/nfs-shared/src/theme/palette';

import config from '../../../config';
import { trGuideURL } from '../../../config/component';
import navigation, { profileNavigation } from '../../../config/navigation';
import type { BaseRoutesProps } from '../../../config/paths';
import { BaseRoutes, LoginRoutes, TimeReportRoutes } from '../../../config/paths';
import type { ContextValue } from '../../../services/hooks/useContract';
import { context } from '../../../services/hooks/useContract';

import GraphqlProvider from '../GraphqlProvider';
import UserProfileLink from '../UserProfileLink';

export type Contract = NonError<SecureRoutesWrapperContractsQuery['profile']>['contracts'][number];

const getContractType = (contracts: NonMutable<Contract[]>, urlContractIndex: string) => {
  const index = Number(urlContractIndex);

  if (!Number.isFinite(index)) {
    return null;
  }

  const selectedContract = contracts[index];

  if (isNil(selectedContract)) {
    return null;
  }

  if (isInstanceOf(selectedContract, B2BContractTypename)) {
    return B2BContractType.B2B;
  }

  if (isInstanceOf(selectedContract, EmploymentContractTypename)) {
    return selectedContract.type;
  }

  return B2BContractType.SUBCONTRACT;
};

const sortContracts = (
  contractA: Contract, contractB: Contract,
) => contractA.createdAt.getTime() - contractB.createdAt.getTime();

const { search } = window.location;
const { redirectUri = undefined } = qs.parse(search, { ignoreQueryPrefix: true });
const relativeLocation = redirectUri ?? window.location.href.replace(`${config.baseUrl}`, '');
const redirectUrl = `${LoginRoutes.LOGIN}?redirectUri=${String(relativeLocation)}`;

const withProviders = (Component: () => ReactElement) => () => {
  const navigate = useNavigate();

  return (
    <Secure otherwise={<Navigate to={redirectUrl} />}>
      <ContentErrorBoundary>
        <GraphqlProvider onUnauthorized={() => navigate(redirectUrl)}>
          <Component />
        </GraphqlProvider>
      </ContentErrorBoundary>
    </Secure>
  );
};

const SecureRoutesWrapper = (): ReactElement => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const isInRoot = !isNil(matchPath(BaseRoutes.BASE, location.pathname))
    || !isNil(matchPath(BaseRoutes.CONTRACT_ROUTE, location.pathname));

  const isOnTR = !isNil(matchPath(TimeReportRoutes.TIME_REPORT, location.pathname));

  const params = useParams<BaseRoutesProps['CONTRACT_ROUTE']>();
  const { data, loading, refetch } = useSecureRoutesWrapperContractsQuery();

  const [contractsResponse, error] = splitError(data?.profile);
  const contracts = contractsResponse?.contracts;

  const contractIndex = params.contractIndex ?? '0';

  const contextValue = useMemo<ContextValue>(() => {
    const [activeContracts, inactiveContracts] = partition(
      contracts ?? [],
      (contract) => contract.status === ContractStatus.CONCLUDED || contract.status === ContractStatus.ACTIVE,
    );

    return {
      contracts: [
        ...activeContracts.sort(sortContracts),
        ...inactiveContracts.sort(sortContracts),
      ],
    };
  }, [contracts]);

  useEffect(() => {
    if (isInRoot) {
      navigate(generatePath(TimeReportRoutes.TIME_REPORT, {
        contractIndex,
      }));
    }
  }, [isInRoot, navigate, contractIndex]);

  if (loading) {
    return <Loading />;
  }

  return (
    <context.Provider value={contextValue}>
      <ToastsContainer />
      <NavSidebar
        navigation={
          removeInacessibleNavigationOptions(
            [],
            navigation(t, { contractIndex }, getContractType(contextValue.contracts, contractIndex)),
          )
        }
        profileLink={<UserProfileLink navigation={profileNavigation(t, { contractIndex })} />}
      >
        {isOnTR && (
          <Box p={600} display="flex" flexDirection="column" gap={300}>
            <Icon size="md">
              <Question weight="fill" color={palette.support.info[60]} />
            </Icon>
            <Body size={200}>
              {t('Anything unclear about the Time Report or do you have any questions?')}
            </Body>
            <TextButton size={200} onClick={() => window.open(trGuideURL)} variant="primary">
              {t('Go to Time Report Guide')}
            </TextButton>
          </Box>
        )}
      </NavSidebar>
      <Suspense fallback={<Loading />}>
        <LoadingErrorHandler
          error={error}
          reloadData={async () => refetch()}
          size="lg"
          forceDisplayErrorMessage={isNil(data)}
        >
          <Outlet />
        </LoadingErrorHandler>
      </Suspense>
    </context.Provider>
  );
};

export default withProviders(SecureRoutesWrapper);
