const zIndex = {
  background: -10,
  default: 0,
  selected: 10,
  hover: 20,
  focused: 30,
  floating: 40,
  overlay: 100,
  drawers: 200,
  modals: 300,
  toasts: 1000,
};

export default zIndex;
