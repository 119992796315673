import shouldForwardProp from '@styled-system/should-forward-prop';
import styled, { css } from 'styled-components/macro';

type DataPropVariant = 'focus-within' | 'hover';
type DataProp = `data-hidden-${DataPropVariant}`;

const dataPropHoverKey: DataProp = 'data-hidden-hover';
const dataPropFocusKey: DataProp = 'data-hidden-focus-within';

const dataPropTrueValue = 'true';
const dataPropFalseValue = 'false';

type GetParentPropsArgs = Readonly<{
  focus?: boolean;
  hover?: boolean;
}>;
type GetParentPropsReturnValue = Record<DataProp, string>;

export const getParentProps = (arg: GetParentPropsArgs): GetParentPropsReturnValue => ({
  'data-hidden-focus-within': arg.focus === true ? dataPropTrueValue : dataPropFalseValue,
  'data-hidden-hover': arg.hover === true ? dataPropTrueValue : dataPropFalseValue,
});

type Props = Readonly<{
  visible?: boolean;
  hiddenVisually?: boolean;
  focusable?: boolean;
}>;

export const show = css`
  display: contents;
  opacity: 1;
  pointer-events: all;
`;

const hideVisually = css`
  height: max-content;
  opacity: 0;
  pointer-events: none;
  width: max-content;
`;

const showOnFocus = css`
  &:focus-within {
    ${show}
  }
`;

const Hidden = styled.div.withConfig({
  shouldForwardProp: (prop) => shouldForwardProp(prop),
})<Props>`
  [${dataPropHoverKey}="${dataPropTrueValue}"]:hover & {
    ${show}
  }

  [${dataPropFocusKey}="${dataPropTrueValue}"]:focus-within & {
    ${show}
  }

  ${({ visible = false, hiddenVisually = false, focusable = false }) => {
    if (visible) {
      return show;
    }

    if (hiddenVisually || focusable) {
      return css`
        ${hideVisually}
        ${focusable && showOnFocus}
      `;
    }

    return css`
      display: none;
    `;
  }}
`;

export default Hidden;
