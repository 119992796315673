const space = {
  0: '0rem',
  100: '0.2rem',
  200: '0.4rem',
  250: '0.6rem',
  300: '0.8rem',
  400: '1.2rem',
  500: '1.6rem',
  600: '2.4rem',
  700: '3.2rem',
  800: '4.8rem',
  900: '6.4rem',
  1000: '9.4rem',
} as const;

export const spaceFactor = 4;

export type Space = keyof typeof space;

export default space;
