import type { MouseEventHandler, ReactElement, ReactNode } from 'react';

import identity from 'lodash/identity';
import isNil from 'lodash/isNil';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';

import errorImg from '../../../assets/images/backgrounds/mountains_error.svg';
import type { NfsErrorFragment as AdminNfsErrorFragment } from '../../../schema/admin';
import type { NfsErrorFragment as UserNfsErrorFragment } from '../../../schema/user';
import type { Nullable } from '../../../services/object';
import space from '../../../theme/space';

import ImgMountains from '../../atoms/ImgMountains';
import Body from '../Body';
import CTAButton from '../CTAButton';
import Header from '../Header';
import TextButton from '../TextButton';

type NfsErrorFragment = AdminNfsErrorFragment | UserNfsErrorFragment;

const variants = {
  sm: {
    buttonMargin: space[300],
    textMargin: space[500],
    containerPadding: space[500],
    headerMargin: undefined,
    shouldShowHeader: false,
    buttonComponent: TextButton,
    bodyVariant: 'secondary',
    greyscale: true,
  },
  md: {
    buttonMargin: space[700],
    textMargin: space[500],
    containerPadding: undefined,
    headerMargin: space[700],
    shouldShowHeader: true,
    buttonComponent: CTAButton,
    bodyVariant: 'primary',
    greyscale: false,
  },
  lg: {
    buttonMargin: space[700],
    textMargin: space[700],
    containerPadding: undefined,
    headerMargin: space[700],
    shouldShowHeader: true,
    buttonComponent: CTAButton,
    bodyVariant: 'primary',
    greyscale: false,
  },
} as const;

type Variant = keyof typeof variants;

export type LoadingErrorType = Readonly<{
  error: Nullable<NfsErrorFragment>;
  reloadData: MouseEventHandler<HTMLButtonElement>;
}>;

export type LoadingErrorProps = Omit<LoadingErrorType, 'error'> & Readonly<{
  size: Variant;
}>;

export const LoadingError = ({ size, reloadData }: LoadingErrorProps): ReactElement => {
  const { t } = useTranslation();
  const variant = variants[size];

  return (
    <Box
      height="100%"
      flex="1"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      py={variant.containerPadding}
    >
      <ImgMountains src={errorImg} greyscale={variant.greyscale} size={size} />
      {variant.shouldShowHeader && (
        <Box mt={variant.headerMargin}>
          <Header as="h4">
            {t('Mountains looks ominously')}
          </Header>
        </Box>
      )}
      <Box mt={variant.textMargin} display="flex" flexDirection="column" alignItems="center">
        <Body size={300} variant={variant.bodyVariant}>
          {t('There was a problem loading this data.')}
        </Body>
        <Body size={300} variant={variant.bodyVariant}>
          {t('Please try again later.')}
        </Body>
      </Box>
      <Box mt={variant.buttonMargin}>
        <variant.buttonComponent onClick={reloadData}>
          {t('Try again')}
        </variant.buttonComponent>
      </Box>
    </Box>
  );
};

export type LoadingErrorHandlerProps = LoadingErrorProps & LoadingErrorType & Readonly<{
  children: ReactNode;
  forceDisplayErrorMessage?: boolean;
  renderError?: (errorNode: ReactNode) => ReactNode;
}>;

const LoadingErrorHandler = (props: LoadingErrorHandlerProps): ReactElement => {
  const {
    error, children, forceDisplayErrorMessage = false, renderError = identity,
  } = props;

  if (isNil(error) && !forceDisplayErrorMessage) {
    return <>{children}</>;
  }

  return <>{renderError(<LoadingError {...props} />)}</>;
};

export default LoadingErrorHandler;
