import type { ForwardedRef, ReactElement, ReactNode } from 'react';
import { forwardRef } from 'react';

import { Box } from '@mui/material';

import type { Variant } from '../../atoms/BadgeBase';
import BadgeBase from '../../atoms/BadgeBase';
import Header from '../Header';

const variants = {
  subtle: {
    grey: {
      normal: { text: 'secondary' },
      inverted: { text: 'secondary' },
    },
    green: {
      normal: { text: 'success' },
      inverted: { text: 'success' },
    },
    red: {
      normal: { text: 'alert' },
      inverted: { text: 'alert' },
    },
    blue: {
      normal: { text: 'info' },
      inverted: { text: 'info' },
    },
    orange: {
      normal: { text: 'warning' },
      inverted: { text: 'warning' },
    },
  },
  strong: {
    grey: {
      normal: { text: 'primary' },
      inverted: { text: 'secondary' },
    },
    green: {
      normal: { text: 'primary' },
      inverted: { text: 'success' },
    },
    red: {
      normal: { text: 'primary' },
      inverted: { text: 'alert' },
    },
    blue: {
      normal: { text: 'primary' },
      inverted: { text: 'info' },
    },
    orange: {
      normal: { text: 'primary' },
      inverted: { text: 'warning' },
    },
  },
} as const;

export type Props = Readonly<{
  children: ReactNode;
  icon?: ReactElement;
  color?: keyof (typeof variants[keyof (typeof variants)]);
  fullWidth?: boolean;
  inverted?: boolean;
  variant?: Variant;
}>;

const Badge = forwardRef(({
  children, fullWidth, inverted = false, variant = 'subtle', color = 'grey', icon, ...other
}: Props, ref: ForwardedRef<HTMLDivElement>): ReactElement => {
  const textVariant = variants[variant][color][inverted ? 'inverted' : 'normal'].text;
  const shouldInvertTextColor = variant === 'strong' && !inverted;
  const hasIcon = Boolean(icon);

  return (
    <BadgeBase
      {...other}
      ref={ref}
      fullWidth={fullWidth}
      inverted={inverted}
      variant={variant}
      color={color}
      hasIcon={hasIcon}
    >
      <Header as="h7" variant={textVariant} inverted={shouldInvertTextColor}>
        {children}
        {hasIcon && (
          <Box ml={200} display="inline-flex" alignItems="center">
            {icon}
          </Box>
        )}
      </Header>
    </BadgeBase>
  );
});

export default Badge;
