import isNil from 'lodash/isNil';

import type { AbstractDrawerUnionInterface } from '@virtuslab/nfs-shared/src/schema/user';
import { isInstanceOf } from '@virtuslab/nfs-shared/src/schema/user';
import { isObjectWithKeys } from '@virtuslab/nfs-shared/src/services/guards';

import type { ReactiveVars } from '../../../..';

export default function createCloseAllDrawers(drawersVar: ReactiveVars['drawersVar']) {
  return (types?: NonNullable<AbstractDrawerUnionInterface['__typename']>[]): void => {
    const drawers = drawersVar();

    const shouldBeVisible = (drawer: typeof drawers[number]) => {
      if (!isObjectWithKeys(drawer, ['__typename'])) {
        return false;
      }

      if (isNil(types)) {
        return false;
      }

      return types.every((type) => !isInstanceOf(drawer, type));
    };

    const closeAllAfter = drawers.findIndex((drawer) => !shouldBeVisible(drawer));

    drawersVar(drawers.map((drawer, index) => ({
      ...drawer,
      visible: closeAllAfter === -1 || index < closeAllAfter,
    })));
  };
}
