const weights = {
  bold: 700,
  semiBold: 600,
  medium: 500,
  regular: 400,
} as const;

export type Weight = keyof typeof weights;

export default weights;
