import styled from 'styled-components/macro';

import { ReactComponent as Logo } from '../../../assets/images/logos/logo_nfs.svg';

import { variants as avatarVariants } from '../Avatar';

const sizes = {
  default: '3.6rem',
  avatar: avatarVariants.sm.size,
};

type Size = keyof typeof sizes;

type Props = Readonly<{
  size?: Size;
}>;

const NfsLogo = styled(Logo)<Props>`
  height: ${({ size = 'default' }) => sizes[size]};
  width: ${({ size = 'default' }) => sizes[size]};
`;

export default NfsLogo;
