import type { ReactElement, ReactNode } from 'react';
import {
  useState, createContext, useMemo, useContext,
} from 'react';

import type { ReportDialogOptions } from '@sentry/react';
import { captureMessage, ErrorBoundary as SentryErrorBoundary, showReportDialog } from '@sentry/react';
import { SecurityContext } from '@virtuslab/react-oauth2';
import noop from 'lodash/noop';

import { isNotNil } from '../../../services/guards';
import useSelfUpdatingRef from '../../../services/hooks/useSelfUpdatingRef';

type ContextValue = Readonly<{
  showSentryDialog: (errorMessage: string, opts?: Partial<ReportDialogOptions>) => void;
}>;

const context = createContext<ContextValue>({
  showSentryDialog: noop,
});

type Props = Readonly<{
  children: ReactNode;
}>;

const ContentErrorBoundary = ({ children }: Props): ReactElement => {
  const { auth } = useContext(SecurityContext) ?? {};

  const [hasError, setHasError] = useState(false);

  const getDialogOptions = (): ReportDialogOptions => {
    const { firstName, lastName, username } = auth?.getUserProfile() ?? {};
    return {
      user: {
        name: [firstName, lastName].filter(isNotNil).join(' '),
        email: username,
      },
    };
  };

  const getDialogOptionsRef = useSelfUpdatingRef(getDialogOptions);
  const contextValue = useMemo<ContextValue>(() => ({
    showSentryDialog: (error, opts) => {
      setHasError(true);
      const eventId = captureMessage(error);

      showReportDialog({
        ...getDialogOptionsRef.current(),
        ...opts,
        eventId,
      });
    },
  }), [getDialogOptionsRef]);

  return (
    <context.Provider value={contextValue}>
      <SentryErrorBoundary
        showDialog
        dialogOptions={getDialogOptions()}
      >
        {hasError ? null : children}
      </SentryErrorBoundary>
    </context.Provider>
  );
};

export const useContentErrorBoundary = (): ContextValue => useContext(context);

export default ContentErrorBoundary;
