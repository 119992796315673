import Inline404 from '@virtuslab/nfs-shared/src/components/molecules/Inline404';
import type { RoutesConfig, WrapRoutes } from '@virtuslab/nfs-shared/src/services/routes';

import { SettingsRoutes } from '../../../config/paths';

const config: RoutesConfig<WrapRoutes<typeof SettingsRoutes>> = {
  paths: {
    [SettingsRoutes.SETTINGS]: {
      render: Inline404,
    },
  },
};

export default config;
