import merge from 'lodash/merge';
import trimEnd from 'lodash/trimEnd';

import { isNotEmptyString } from '../services/checks';

export interface ReadOnlyConfig {
  name: string;
  titleName: string;
  service: string;
  env: string;
  isLocalDevelopment: boolean;
  isBrowser: boolean;
}

export interface KeycloakConfig {
  url: string;
  realm: string;
  clientID: string;
}

export interface SentryConfig {
  url: string;
  enabled: boolean;
}

export interface RuntimeConfig {
  baseUrl: string;
  basePath: string;
  apiUrl: string;
  domain: string;
  emailDomain: string;
  version: string;
  release: string;
  locale?: string;
  country: string;
  keycloak: KeycloakConfig;
  sentry: SentryConfig;
  appVersionStatusUrl: string;
  git: Readonly<{
    branch: string;
    commit: string;
  }>;
}

export interface Config extends ReadOnlyConfig, RuntimeConfig {}

export const extractEnv = (version: string, nodeEnv: string): string => {
  const parts = version.split('@');

  if (parts.length !== 2) {
    if (nodeEnv === 'test') {
      return 'test';
    }

    if (nodeEnv !== 'production') {
      return 'dev';
    }

    return 'prod';
  }

  return parts[0];
};

export const removeEnvFromVersion = (version: string, env: string): string => {
  if (version.includes('@')) {
    const [, parsedVersion] = version.split('@');
    return parsedVersion;
  }

  return version.replace(env, '');
};

type ProcessEnvReturnType = Readonly<{
  runtimeConfig: RuntimeConfig;
  readOnlyConfig: ReadOnlyConfig;
}>;

export const processEnv = (env: NodeJS.ProcessEnv, defaultTitle: string): ProcessEnvReturnType => {
  const {
    REACT_APP_BASEPATH,
    REACT_APP_API_URL,
    REACT_APP_LOCALE,
    REACT_APP_COUNTRY,
    REACT_APP_SERVICE = 'frontend',
    REACT_APP_VERSION = 'unknown',
    REACT_APP_NAME = 'NFS',
    REACT_APP_TITLE = defaultTitle,
    REACT_APP_DOMAIN,
    NODE_ENV,
    REACT_APP_KEYCLOAK_URL,
    REACT_APP_KEYCLOAK_REALM,
    REACT_APP_KEYCLOAK_CLIENTID,
    REACT_APP_SENTRY_URL,
    REACT_APP_VERSION_STATUS_URL,
    REACT_APP_SENTRY_ENABLED,
  } = env;

  const locale = REACT_APP_LOCALE ?? 'en_gb';
  const country = REACT_APP_COUNTRY ?? 'uk';

  const parsedEnv = extractEnv(REACT_APP_VERSION, NODE_ENV);

  const readOnlyConfig: ReadOnlyConfig = {
    name: REACT_APP_NAME,
    titleName: REACT_APP_TITLE,
    service: REACT_APP_SERVICE,
    env: parsedEnv,
    // different from env - one of the stages of production can be "dev",
    // but that doesn't make it the same as local development
    isLocalDevelopment: NODE_ENV !== 'production',
    isBrowser: typeof window !== 'undefined',
  };

  if (!isNotEmptyString(REACT_APP_KEYCLOAK_URL)) {
    throw new Error('Keycloak URL has to be configured. Make sure you set up REACT_APP_KEYCLOAK_URL correctly.');
  }

  if (!isNotEmptyString(REACT_APP_KEYCLOAK_REALM)) {
    throw new Error('Keycloak\'s realm has to be configured. Make sure you set up REACT_APP_KEYCLOAK_REALM correctly.');
  }

  if (!isNotEmptyString(REACT_APP_KEYCLOAK_CLIENTID)) {
    throw new Error('Keycloak\'s client ID has to be configured. Make sure you set up REACT_APP_KEYCLOAK_CLIENTID correctly.');
  }

  if (!isNotEmptyString(REACT_APP_SENTRY_URL)) {
    throw new Error('Sentry DSN URL has to be configured. Make sure you set up REACT_APP_SENTRY_URL correctly.');
  }

  if (!isNotEmptyString(REACT_APP_DOMAIN)) {
    throw new Error('App\'s domain has to be configured. Make sure you set up REACT_APP_DOMAIN correctly.');
  }

  const defaultKeycloakConfig: KeycloakConfig = {
    url: REACT_APP_KEYCLOAK_URL,
    realm: REACT_APP_KEYCLOAK_REALM,
    clientID: REACT_APP_KEYCLOAK_CLIENTID,
  };

  const defaultSentryConfig: SentryConfig = {
    url: REACT_APP_SENTRY_URL,
    enabled: REACT_APP_SENTRY_ENABLED !== 'false',
  };

  const runtimeConfig: RuntimeConfig = {
    locale,
    country,
    basePath: trimEnd(REACT_APP_BASEPATH ?? '', '/'),
    apiUrl: REACT_APP_API_URL ?? '/api',
    domain: REACT_APP_DOMAIN,
    emailDomain: REACT_APP_DOMAIN ? `@${REACT_APP_DOMAIN}` : '@example.com',
    keycloak: defaultKeycloakConfig,
    sentry: defaultSentryConfig,
    appVersionStatusUrl: REACT_APP_VERSION_STATUS_URL ?? '/status/version',
    baseUrl: '', // will be updated later, see below
    release: '',
    version: '',
    git: {
      branch: '',
      commit: '',
    },
  };

  return { readOnlyConfig, runtimeConfig };
};

export const mergeWindowConfig = (windowConfig: Partial<RuntimeConfig>, envConfigs: ProcessEnvReturnType): Config => {
  const config: Config = merge(envConfigs.runtimeConfig, windowConfig, envConfigs.readOnlyConfig);

  // update fields
  config.baseUrl = `${trimEnd(window.location.origin, '/')}${config.basePath}`;
  config.version = config.git.commit;
  config.release = `${config.service}@${config.version}`;

  return config;
};
