import styled from 'styled-components/macro';

import mountains from '../../../assets/images/backgrounds/mountains_padded.svg';
import sizes from '../../../theme/sizes';

type Size = keyof typeof sizes['images']['mountains'];

type Props = Readonly<{
  greyscale?: boolean;
  size?: Size;
  src?: string;
}>;

const ImgMountains = styled.img.attrs<Props>(({ src }) => ({
  src: src ?? mountains,
}))<Props>`
  background-color: ${({ theme }) => theme.scTheme.palette.primary[10]};
  border-radius: 50%;
  filter: ${({ greyscale = false }) => (greyscale ? 'grayscale(100%)' : null)};
  height: ${({ size = 'lg' }) => sizes.images.mountains[size]};
  width: ${({ size = 'lg' }) => sizes.images.mountains[size]};
`;

export default ImgMountains;
