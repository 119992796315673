
import { useEffect, useRef } from 'react';

import first from 'lodash/first';
import isNil from 'lodash/isNil';
import not from 'lodash/negate';
import { useLocation, matchPath } from 'react-router';

import { useRoutesConfig } from '../../components/organisms/RenderRoutes';

import { isEmpty } from '../checks';

import useSelfUpdatingRef from './useSelfUpdatingRef';

type UseOnRouteChangeArg = Readonly<{
  listener: () => void;
}>;

const useOnRouteChange = ({ listener }: UseOnRouteChangeArg): void => {
  const { paths } = useRoutesConfig();
  const location = useLocation();

  const prevMatchedRoute = useRef<string>();
  const listenerRef = useSelfUpdatingRef(listener);

  const matchedRoute = Object.keys(paths).find((path) => matchPath(path, location.pathname));

  useEffect(() => {
    if (isNil(prevMatchedRoute.current) || isNil(matchedRoute)) {
      return;
    }

    const prevMatchedRouteParts = prevMatchedRoute.current.split('/').filter(not(isEmpty));
    const currentMatchedRouteParts = matchedRoute.split('/').filter(not(isEmpty));

    const sameRoot = first(prevMatchedRouteParts) === first(currentMatchedRouteParts);

    if (!sameRoot) {
      listenerRef.current();
    }
  }, [listenerRef, matchedRoute]);

  useEffect(() => {
    prevMatchedRoute.current = matchedRoute;
  }, [matchedRoute, prevMatchedRoute]);
};

export default useOnRouteChange;
