import type { i18n } from 'i18next';
import isNil from 'lodash/isNil';
import { Settings } from 'luxon';

import type { Translations } from './translations';

export type Language = 'en-GB' | 'pl-PL';

const makeChangeLanguage = (
  i18nInstance: i18n,
  defaultNS: string,
  getBundle: (language: Language) => Promise<unknown>,
) => async (
  language: Language,
): Promise<void> => {
  if (!i18nInstance.hasResourceBundle(language, defaultNS)) {
    const bundle = await getBundle(language) as { default: Translations } | undefined;

    if (!isNil(bundle)) {
      i18nInstance.addResourceBundle(language, defaultNS, bundle.default);
    }
  }

  Settings.defaultLocale = language;
  void i18nInstance.changeLanguage(language);
};
export default makeChangeLanguage;
