import type { ReactiveVars } from '../..';

import createCloseDrawer from './drawers/close';
import createCloseAllDrawers from './drawers/closeAll';
import createOpenDrawer from './drawers/open';
import createOpenChildDrawer from './drawers/openChild';
import createUpdateDrawer from './drawers/update';

export type DrawerMutations = Readonly<{
  open: ReturnType<typeof createOpenDrawer>;
  openChild: ReturnType<typeof createOpenChildDrawer>;
  close: ReturnType<typeof createCloseDrawer>;
  closeAll: ReturnType<typeof createCloseAllDrawers>;
  update: ReturnType<typeof createUpdateDrawer>;
}>;

export const drawerMutations = (drawersVar: ReactiveVars['drawersVar']): DrawerMutations => ({
  open: createOpenDrawer(drawersVar),
  openChild: createOpenChildDrawer(drawersVar),
  close: createCloseDrawer(drawersVar),
  closeAll: createCloseAllDrawers(drawersVar),
  update: createUpdateDrawer(drawersVar),
});

export default {};
