import type { ReactElement } from 'react';
import { useEffect, useContext } from 'react';

import { SecurityContext } from '@virtuslab/react-oauth2';

type Props = Readonly<{
  baseUrl: string;
  loginRoute: string;
}>;

function AuthLogoutPage({ baseUrl, loginRoute }: Props): ReactElement {
  const context = useContext(SecurityContext);

  if (!context) {
    throw new Error('No Security Context available');
  }
  const { auth, fallback } = context;

  useEffect(() => {
    void auth.logout(`${baseUrl}${loginRoute}`);
  }, [auth, baseUrl, loginRoute]);

  return fallback;
}

export default AuthLogoutPage;
