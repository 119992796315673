import type { ForwardedRef, ReactElement } from 'react';
import { forwardRef } from 'react';

import styled, { css } from 'styled-components/macro';

import Box from '@mui/material/Box';
import type { ButtonProps } from '@mui/material/Button';
import MuiButton from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { isEmpty } from '../../../services/checks';
import type { InteractivityState } from '../../../services/form';
import { interactivityStateToBoolean, interactivityStateToReason } from '../../../services/form';
import palette from '../../../theme/palette';
import shadows from '../../../theme/shadows';

import ActionText, { variants as textVariants } from '../ActionText';
import Body from '../Body';
import Tooltip from '../Tooltip';

const variants = {
  primary: {
    default: palette.primary[60],
    hover: palette.primary[70],
    active: palette.primary[80],
    shadow: shadows.focus.primary.full,
    disabled: palette.greyscale[20],
  },
  alert: {
    default: palette.support.alert[60],
    hover: palette.support.alert[70],
    active: palette.support.alert[80],
    shadow: shadows.focus.alert.full,
    disabled: palette.support.alert[30],
  },
};

type Variant = keyof typeof variants;

type StyledButtonProps = Readonly<{
  disabled: boolean;
  buttonVariant: Variant;
}>;

const buttonColors = ({ disabled, buttonVariant }: StyledButtonProps) => {
  if (disabled) {
    return css`
      &,
      &:hover {
        background-color: ${variants[buttonVariant].disabled};
      }
    `;
  }

  return css`
    background-color: ${variants[buttonVariant].default};

    &:hover {
      background-color: ${variants[buttonVariant].hover};
    }

    &:active {
      background-color: ${variants[buttonVariant].active};
    }

    &:focus-visible {
      box-shadow: ${variants[buttonVariant].shadow};
    }

    ${ActionText} {
      &:hover,
      &:active {
        color: ${textVariants.primary.inverted.default};
      }
    }
  `;
};

const Button = styled(MuiButton).withConfig<StyledButtonProps>({
  shouldForwardProp: (prop) => prop !== 'buttonVariant',
})`
  &&& {
    ${({ disabled = false }) => disabled && css`
      cursor: not-allowed;
    `}

    ${buttonColors}

    &.MuiButtonBase-root.Mui-disabled {
      color: unset;
      pointer-events: all;
    }
  }
`;

export type Props = Pick<ButtonProps, 'children' | 'endIcon' | 'fullWidth' | 'onClick' | 'ref' | 'startIcon' | 'type'> & Readonly<{
  variant?: Variant;
  loading?: boolean;
  disabled?: InteractivityState;
}>;

const CTAButton = forwardRef(({
  children, disabled = false, type, fullWidth, onClick, startIcon, endIcon, variant = 'primary', loading = false,
}: Props, ref: ForwardedRef<HTMLButtonElement>): ReactElement => {
  const isDisabled = interactivityStateToBoolean(disabled);
  const disabledText = interactivityStateToReason(disabled);

  return (
    <Tooltip
      enabled={!isEmpty(disabledText)}
      tooltipProps={{
        title: <Body size={200} inverted>{disabledText}</Body>,
      }}
    >
      <Box>
        <Button
          disabled={isDisabled || loading}
          type={type}
          fullWidth={fullWidth}
          color="primary"
          size="large"
          variant="contained"
          onClick={onClick}
          startIcon={startIcon}
          endIcon={endIcon}
          buttonVariant={variant}
          ref={ref}
        >
          <ActionText inverted disabled={isDisabled || loading}>
            {loading ? <CircularProgress color={variant} /> : children}
          </ActionText>
        </Button>
      </Box>
    </Tooltip>

  );
});

export default CTAButton;
