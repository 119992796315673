const lineHeights = {
  xxs: '1.2em',
  xs: '1.25em',
  sm: '1.333em',
  md: '1.48em',
  lg: '1.5em',
  xl: '1.555em',
};

export type LineHeight = keyof typeof lineHeights;

export default lineHeights;
