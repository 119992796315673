import type { FormattingFn } from '.';

type AddressType = Readonly<{
  readonly country: string;
  readonly region: string | null;
  readonly postalCode: string | null;
  readonly city: string;
  readonly streetLine: string;
}>;

const inlineAddress: FormattingFn<Partial<AddressType>> = () => (data) => [
  data.streetLine,
  [
    data.postalCode,
    data.city,
  ].filter(Boolean).join(' '),
  data.region,
  data.country,
].filter(Boolean).join(', ');

const personalInfo = {
  inlineAddress,
} as const;

export type PersonalInfo = typeof personalInfo;

export default personalInfo;
