import { lazy } from 'react';

import type { RoutesConfig, WrapRoutes } from '@virtuslab/nfs-shared/src/services/routes';

import { BenefitsRoutes } from '../../../config/paths';

const BenefitsPage = lazy(async () => import('.'));

const config: RoutesConfig<WrapRoutes<typeof BenefitsRoutes>> = {
  paths: {
    [BenefitsRoutes.BENEFITS]: {
      render: BenefitsPage,
    },
  },
};

export default config;
