import { friendlyFormatIBAN } from 'ibantools';
import find from 'lodash/find';
import isString from 'lodash/isString';

import type { BankAccountFragment, VatType } from '../../schema/admin';
import {
  OtherBankAccountNumberTypename,
  isInstanceOf,
  VatAppliedTypename,
} from '../../schema/admin';

import { enumMessage } from '../i18n';

import type { FormattingFn } from '.';

export enum TimeUnit {
  hour = 'hour',
  day = 'day',
  month = 'month',
  year = 'year',
}

/**
 * Returns thing (unit, money, etc) per hour (in given language)
 *
 * @param language language
 * @param value (unit, money, ...)
 * @param timeUnit (hour, month, ...)
 * @return thing per hour
 */

export const perTimePeriod: FormattingFn<{ value: string; timeUnit: string }> = ({ language }) => (
  { value, timeUnit },
) => {
  const arbitraryUnit = Intl.NumberFormat(language, {
    style: 'unit',
    unit: 'liter',
    unitDisplay: 'long',
  }).format(0);

  const arbitraryPerHour = Intl.NumberFormat(language, {
    style: 'unit',
    unit: `liter-per-${timeUnit}`,
    unitDisplay: 'long',
  }).format(0);

  return arbitraryPerHour.replace(arbitraryUnit, value);
};

const moneyWithCurrency: FormattingFn<{
  amount: number | string; currency: string;
}> = ({ language }) => (
  data,
) => {
  if (isString(data.amount)) {
    return Intl.NumberFormat(language, { style: 'currency', currency: data.currency, currencyDisplay: 'code' })
      .format(0).replace(/\d.*\d/, data.amount);
  }

  return Intl.NumberFormat(language, { style: 'currency', currency: data.currency, currencyDisplay: 'code' })
    .format(data.amount);
};

const moneyWithoutCurrency: FormattingFn<{ amount: number; currency: string }> = ({ language }) => (
  data,
) => Intl.NumberFormat(language, { style: 'currency', currency: data.currency })
  .formatToParts(data.amount)
  .filter((part) => part.type !== 'currency')
  .map((part) => part.value)
  .join('')
  .trim();

const moneyPerSettlement: FormattingFn<{
  amount: number | string; currency: string; timeUnit: TimeUnit;
}> = (config) => (data) => {
  const money = moneyWithCurrency(config)(data);

  return perTimePeriod(config)({ value: money, timeUnit: data.timeUnit });
};

type CurrencyInfo = Partial<{
  symbol: string;
  decimalSeparator: string;
  groupSeparator: string;
}>;
const currencyInfo: FormattingFn<string, CurrencyInfo> = ({ language }) => (currency) => {
  const parts = Intl.NumberFormat(language, { style: 'currency', currency, currencyDisplay: 'symbol' }).formatToParts(0);

  const symbol = find(parts, { type: 'currency' });
  const decimal = find(parts, { type: 'decimal' });
  const group = find(parts, { type: 'group' });

  return {
    decimalSeparator: decimal?.value ?? undefined,
    groupSeparator: group?.value ?? undefined,
    symbol: symbol?.value ?? undefined,
  };
};

const compactIBAN: FormattingFn<{ iban: string; groups?: number }> = () => ({
  iban,
  groups = 3,
}) => {
  // Check if iban has at least `groups` groups, if so truncate it.
  const regex = new RegExp(`^(\\w{2,4}\\s)${groups},}\\w{2,4}$`);

  if (regex.test(iban.trim())) {
    const parts = iban.trim().split(/\s/);

    const [first, second] = parts;
    const last = parts[parts.length - 1];

    return [first, second, '(…)', last].join(' ');
  }

  if (iban.length > 14) {
    return [iban.substr(0, 10), '(…)', iban.substr(iban.length - 4)].join(' ');
  }

  return iban;
};

const accountNumber: FormattingFn<BankAccountFragment['accountNumber'], string, 'narrow' | 'wide'> = (arg) => (
  accountNumberData, style,
) => {
  if (isInstanceOf(accountNumberData, OtherBankAccountNumberTypename)) {
    return accountNumberData.value;
  }

  const base = friendlyFormatIBAN(accountNumberData.value) ?? accountNumberData.value;

  return style === 'narrow' ? compactIBAN(arg)({
    iban: base,
  }) : base;
};

type VatFormat = 'withoutSuffix' | 'withSuffix';
const vat: FormattingFn<VatType, string, VatFormat> = ({ t }) => (vatType, suffixStatus) => {
  if (isInstanceOf(vatType, VatAppliedTypename)) {
    const base = `${vatType.value}%`;

    if (suffixStatus === 'withoutSuffix') {
      return base;
    }

    return t('{{value}} VAT', { value: base });
  }

  return t(enumMessage('vatNotAppliedReason', vatType.reason));
};

const finances = {
  moneyPerSettlement,
  moneyWithCurrency,
  moneyWithoutCurrency,
  currencyInfo,
  compactIBAN,
  vat,
  accountNumber,
} as const;

export type Finances = typeof finances;

export default finances;
