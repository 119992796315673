import styled, { css } from 'styled-components/macro';

export type NavButtonBaseProps = Readonly<{
  disabled?: boolean;
}>;

const buttonNotDisabled = ({ disabled = false }: NavButtonBaseProps) => {
  if (!disabled) {
    return css`
      cursor: pointer;

      &:hover {
        background-color: ${({ theme }) => theme.scTheme.palette.greyscale[10]};
      }
    `;
  }
  return null;
};

const NavButtonBase = styled.button<NavButtonBaseProps>`
  align-items: center;
  background-color: ${({ theme }) => theme.scTheme.palette.inverted.white};
  border: none;
  border-radius: ${({ theme }) => theme.scTheme.borders.radii.md};
  display: flex;
  ${buttonNotDisabled}
  padding: ${({ theme }) => theme.scTheme.space[500]};
  padding-bottom: calc(${({ theme }) => `${theme.scTheme.space[300]} + ${theme.scTheme.space[100]}`});
  padding-top: calc(${({ theme }) => `${theme.scTheme.space[300]} + ${theme.scTheme.space[100]}`});
  text-decoration: none;
  width: 100%;
`;

export default NavButtonBase;
